import TreePage from "../../components/TreePage";
import obra from "../../data/obra";

function Index(props) {
  return (
    <TreePage section={"obra"} data={obra} {...props} />
  );
}

export default Index;
