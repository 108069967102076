import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import SinglePage from "../../components/SinglePage";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 32,
        paddingBottom: 32,
        textAlign: "left",
    },
}));

function Nosotros() {
    const classes = useStyles();
    return (
        <SinglePage title="Nosotros" headerTitle={"Nosotros"}>
            <Container maxWidth="md" className={classes.container}>
                <Typography variant="h6">
                    EL MUSEO VIRTUAL DEL PINTOR MEXICANO
                    <Typography variant="subtitle2">HILARIO GÓMEZ SANCHEZ</Typography>
                </Typography>

                <Typography variant="body1">
                    <br/>
                  FUNGE COMO CENTRO DE RECOPILACION E INFORMACION DE LA VIDA Y OBRA DEL
                  ARTISTA; ASI COMO PLATAFORMA PARA SU PROYECCION.
                </Typography>
                <br />
                <Typography variant="body1">
                  Habiendo realizado labores de catalogacion cronologica y tematica de su
                  produccion durante años se presenta en
                  este proyecto.
                  <br />
                  <br />
                  En lo referente:
                  <br />
                  <br />
                  Se continùa el ya iniciado Catàlogo Razonado, convocando a quienes
                  posean alguna de las obras, a contactarnos para incluir la
                  información, asì como para mejorar las imágenes existentes.
                  <br />
                  <br />
                  Recibimos anécdotas o información de quienes conocieron o convivieron
                  con el autor y quieran compartirlas.
                  <br />
                  <br />
                  Realizamos labores de Autentificaciòn de sus obras.
                </Typography>
            </Container>
        </SinglePage>
    );
}

export default Nosotros;
