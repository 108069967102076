const index =  {"type":"directory","name":"galeria","contents":[
            {"type":"directory","name":"5-1 PERSONALES","contents":[
                    {"type":"file","name":"1 Autor Desconocido, Infancia en Guanajuato, 1907.png"},
                    {"type":"file","name":"10 Autor Desconocido, Ludwig van Beethoven, 1928.png"},
                    {"type":"file","name":"11 Autor Desconocido, Escuela Libre de Musica y Declamacion, 1928.png"},
                    {"type":"file","name":"12 Autor Desconocido, Su Estudio en Peralvillo, 1928.png"},
                    {"type":"file","name":"13 Autor I Luna, Hilario Gomez, 1929.png"},
                    {"type":"file","name":"14 Autor Desconocido, Con su instrumento preferido, 1929.png"},
                    {"type":"file","name":"15 Autor Desconocido, Plaza del Baratillo con su hermano, 1931.png"},
                    {"type":"file","name":"16 Autor Desconocido, De visita en Guanajuato, 1931.png"},
                    {"type":"file","name":"17 Autor Desconocido, Con los pintores Jose Cruz y Julio Castellanos, 1932.png"},
                    {"type":"file","name":"18 Autor Desconocido, En su estudio, 1932.png"},
                    {"type":"file","name":"19 Autor Desconocido, En la ventana, 1935.png"},
                    {"type":"file","name":"2 Autor Desconocido, 1917.png"},
                    {"type":"file","name":"20 Autor Desconocido, Pablo Gomez, Baltazar N, Hilario y su hermano Salvador, 1937.png"},
                    {"type":"file","name":"21 Autor desconocido, Veracruz, 1938.png"},
                    {"type":"file","name":"22 Autor Desconocido, En Michoacan I, 1940.png"},
                    {"type":"file","name":"23 Autor desconocido, Su estudio, calle de Peru, 1941.png"},
                    {"type":"file","name":"24 Autor desconocido, Enlace matrimonial, Herlinda e Hilario, 1942.png"},
                    {"type":"file","name":"25 Autor Desconocido, Foto de Boda con pajes y madrinas, 1942 .png"},
                    {"type":"file","name":"26 Autor Desconocido, Con su esposa y paloma, 1943.png"},
                    {"type":"file","name":"27 Autor Desconocido, En Michoacan I, 1944.png"},
                    {"type":"file","name":"28 Autor Desconocido, En Michoacan II, 1944.png"},
                    {"type":"file","name":"29 Autor Desconocido, En el centro de la Ciudad, 1944.png"},
                    {"type":"file","name":"3 Autor Desconocido, ENBA con Roberto de la Cueva del Rio y Ricardo Barcenas, 1922.png"},
                    {"type":"file","name":"30 Autor Desconocido, 1947.png"},
                    {"type":"file","name":"31 Autor Desconocido, Dia de las Mulitas con su familia, 1948.png"},
                    {"type":"file","name":"32 Autor Desconocido, De Bohemia con Claudio Estrada, Trio Los Panchos, Pianista y Declamadores, 1949.png"},
                    {"type":"file","name":"33 Autor Desconocido, En su estudio 1950.png"},
                    {"type":"file","name":"34 Autor Desconocido, Bautizo de su ahijado Eduardo y su comadre, 1953.png"},
                    {"type":"file","name":"35 Autor Desconocido, En Jilotepec, 1956.png"},
                    {"type":"file","name":"36 Autor Desconocido, Noche Mexicana, 1957.png"},
                    {"type":"file","name":"37 Autor Desconocido, En su estudio, Calle Rep. Dominicana, 1958.png"},
                    {"type":"file","name":"38 Autor Con su familia, 1959,.png"},
                    {"type":"file","name":"39 Autor Adolfo Gomez, 1960.png"},
                    {"type":"file","name":"4 Autor Desconocido, Con Gildardo Hernandez, Enrique Ochoa y Telesforo Sierra, 1923.png"},
                    {"type":"file","name":"40 Autor Desconocido, Comida Pintores y Caricaturistas, 1968,.png"},
                    {"type":"file","name":"41 Autor Aurora Gomez, Acapulco, 1969,.png"},
                    {"type":"file","name":"42 Autor desconocido, Boda de su hijo, 1969.png"},
                    {"type":"file","name":"43 Autor desconocido, Boda hija, 1970.png"},
                    {"type":"file","name":"44 Autor Desconocido, Con sus hijos en Guanajuato, 1979.png"},
                    {"type":"file","name":"45 Autor Eloisa Gomez, Con su nieta, 1972.png"},
                    {"type":"file","name":"46 Autor Aurora Gomez, Con pintores en Toluca, 1979.png"},
                    {"type":"file","name":"47 Autor Aurora Gomez, Al aire libre, Toluca, 1979.png"},
                    {"type":"file","name":"48 Aurora Gomez, Pintores En Toluca, Froylan Ojeda, Leopoldo Estrada, Macrina Krauss, Joaquin Martinez Navarrete, Enrique Ugarte, Alicia Soloma e Hilario, 1979.png"},
                    {"type":"file","name":"49 Autor Aurora Gomez, Toluca, 1979.png"},
                    {"type":"file","name":"5 Autor Desconocido, 1924.png"},
                    {"type":"file","name":"50 Autor Aurora Gomez, En Guanajuato, 1979.png"},
                    {"type":"file","name":"51 Autor Aurora Gomez, Con Francisco Merino Arauz, 1979.png"},
                    {"type":"file","name":"52 Autor Aurora Gomez, Chapultepec, 1980.png"},
                    {"type":"file","name":"53 Autor Aurora Gomez, Trazo, 1980.png"},
                    {"type":"file","name":"54 Autor desconocido, Tampico, 1981.png"},
                    {"type":"file","name":"55 Autor Aurora Gomez, Otoño en Chapultepec, 1981.png"},
                    {"type":"file","name":"56 Autor Desconocido, Hilario y Don Fernando Gamboa Bustos, 1981.png"},
                    {"type":"file","name":"57 Autor Silvia Mendoza, En Hacienda de Pastita, 1981.png"},
                    {"type":"file","name":"58 Autor Aurora Gomez, Su estudio en Naucalpan, 1981.png"},
                    {"type":"file","name":"59 Autor Aurora Gomez, Mellado, 1982.png"},
                    {"type":"file","name":"6 Autor Desconocido, ENBA, Trazo Meteorito, 1925.png"},
                    {"type":"file","name":"60 Autor Aurora Gomez,  Atrio Templo de Mellado, 1982.png"},
                    {"type":"file","name":"61 Autor Aurora Gomez,  Hilario en Mellado, 1982.png"},
                    {"type":"file","name":"62 Autor Desconocido, Con su esposa, hijos y nieto, 1982.png"},
                    {"type":"file","name":"63 Autor Aurora Gomez, Atencion capturada,1982.png"},
                    {"type":"file","name":"64 Autor Desconocido,  Pintores en Tequisquiapan, Felix Padilla, Francisco Salas, Aurora Gomez, Alfonso Ortega Ugarte, Hilario, Acompañan Herlinda y Mario N, 1983.png"},
                    {"type":"file","name":"65 Autor Adolfo Gomez, Tequisquiapan, 1983.png"},
                    {"type":"file","name":"66 Autor Aurora Gomez,  Tequisquiapan, 1983.png"},
                    {"type":"file","name":"67 Autor Aurora Gomez,  Mellado, 1983.png"},
                    {"type":"file","name":"68 Autor Adolfo Gomez,  Hacienda de Pastita, 1983.png"},
                    {"type":"file","name":"69 Autor Aurora Gomez,  Aguascalientes con Herlinda, 1983.png"},
                    {"type":"file","name":"7 Autor Desconocido, Grupo de los Siete, Ernesto Tamariz, Roberto de la Cueva del Rio, Ricardo Barcenas, Jose Morales, Hilario, Sostenes Ortega y Lauro Lopez, ausente Fernando Garcia Bustos, 1926.png"},
                    {"type":"file","name":"70 Autor Aurora Gomez,  Zacatecas 2, 1983.png"},
                    {"type":"file","name":"71 Autor Aurora Gomez,  Zacatecas 1, 1983.png"},
                    {"type":"file","name":"72 Autor Desconocido, 15 años de su nieta Silvia, 1985.png"},
                    {"type":"file","name":"73 Autor Eloisa Gomez, Chimalistac con su yerno y nietos, 1986.png"},
                    {"type":"file","name":"74 Autor Desconocido, 1987, Con Herlinda en Trajinera, Xochimilco, 1987.png"},
                    {"type":"file","name":"75 Autor Aurora Gomez,  Queretaro, 1987.png"},
                    {"type":"file","name":"76 Autor Aurora Gomez, 15 años de su nieta Cinthia, 1987.png"},
                    {"type":"file","name":"77 Autor Aurora Gomez, Almoloya de Juarez, 1988.png"},
                    {"type":"file","name":"78 Autor Aurora Gomez, Dibujando a peticion, 1988.png"},
                    {"type":"file","name":"79 Autor Aurora Gomez, New York, 1989.png"},
                    {"type":"file","name":"8 Autor Desconocido, Ludwig van Beethoven II, 1927.png"},
                    {"type":"file","name":"80 Autor Aurora Gomez, Cataratas del Niagara, 1989.png"},
                    {"type":"file","name":"81 Autor Aurora Gomez, Montreal, 1989.png"},
                    {"type":"file","name":"82 Autor Aurora Gomez, Con su hijo, nuera y nietos, 1990.png"},
                    {"type":"file","name":"83 Autor Aurora Gomez, Con su comadre y ahiijado Eduardo Tejedo Narvaez, 1990.png"},
                    {"type":"file","name":"84 Autor Aurora Gomez,  Convalescencia, 1990.png"},
                    {"type":"file","name":"85 Autor Aurora Gomez, En su cumpleaños,1991.jpg"},
                    {"type":"file","name":"86 Autor Adolfo Gomez, 1991.png"},
                    {"type":"file","name":"87 Autor Aurora Gomez, Con su cuñada y nieta, 1991.png"},
                    {"type":"file","name":"88 Autor Hector Peñarrieta, Museo del Pueblo, Guanajuato, 1991.png"},
                    {"type":"file","name":"89 Autor Aurora Gomez, Con sus nietos y su carpeta de apuntes, 1992.png"},
                    {"type":"file","name":"9 Autor Descconocido, ENBA, Profesor Sostenes Ortega Solorzano y sus alumnos, 1928.png"},
                    {"type":"file","name":"90 Autor Aurora Gomez, Miami, 1992.png"},
                    {"type":"file","name":"91 Autor Aurora Gomez Visitando el Museo Frida Khalo, 1992.png"},
                    {"type":"file","name":"92 Autor Hector Peñarrieta, Su estudio, CDMX., 1993.png"},
                    {"type":"file","name":"93 Autor Aurora Gomez,  Baiando con Herlinda, 1993 .png"},
                    {"type":"file","name":"94 Autor Desconocido, Boda de su nieto, 1993.png"},
                    {"type":"file","name":"95 Autor Desconocido, En San Antonio, Texas, 1994.png"},
                    {"type":"file","name":"96 Autor Silvia Mendoza, En Guanajuato con su hijo y nieto, 1994.png"},
                    {"type":"file","name":"97 Autor Aurora Gomez.1994, Queretaro.png"},
                    {"type":"file","name":"98 Autor Aurora Gomez, Con su hija, 1995.png"}
                ]},
            {"type":"directory","name":"5-2 EXPOSICIONES ","contents":[
                    {"type":"directory","name":"1 PALACIO DE MINERIA, 21 de Enero 1926 ","contents":[
                            {"type":"file","name":"1, Excelsior, 12 Enero 1926, 1.png"},
                            {"type":"file","name":"2, Excelsior, 12 Enero 1926, 2.png"},
                            {"type":"file","name":"3, El Democrata, 22 de Enero 1926, 1.png"},
                            {"type":"file","name":"4, El Democrata, 22 de Enero 1926, 2.png"},
                            {"type":"file","name":"5, El Universal, 25 de Enero de 1926, 1.png"}
                        ]},
                    {"type":"directory","name":"10 GALERIA TIERRA ADENTRO, Septiembre de 1979","contents":[
                            {"type":"file","name":"1 INBA-Galeria Tierra Adentro.png"},
                            {"type":"file","name":"2 documento.png"},
                            {"type":"file","name":"3 documento.png"},
                            {"type":"file","name":"4 documento.png"},
                            {"type":"file","name":"5 documento.png"},
                            {"type":"file","name":"6 documento.png"},
                            {"type":"file","name":"7 documento.png"},
                            {"type":"file","name":"8 documento.png"}
                        ]},
                    {"type":"directory","name":"11 CLUB DE GOLF BELLAVISTA, 10 de Noviembre de 1979 ","contents":[
                            {"type":"file","name":"1 CLUB DE GOLF BELLAVISTA, 10 de Noviembre de 1979.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"2 .png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"12 FERIA REGIONAL Cholula, Pue., 1980","contents":[
                        ]},
                    {"type":"directory","name":"13 TEATRO MANUEL DOBLADO, 29 de Abril de 1981","contents":[
                            {"type":"file","name":"1 Invitacion.png"},
                            {"type":"file","name":"10 H 7.png"},
                            {"type":"file","name":"11 H 8.png"},
                            {"type":"file","name":"12 H 9.png"},
                            {"type":"file","name":"13 H 10.png"},
                            {"type":"file","name":"14 H 11.png"},
                            {"type":"file","name":"15 H 12.png"},
                            {"type":"file","name":"16 H 13.png"},
                            {"type":"file","name":"17 H 14.png"},
                            {"type":"file","name":"18.png"},
                            {"type":"file","name":"19.png"},
                            {"type":"file","name":"2 Catalogo 1 Portada 1.png"},
                            {"type":"file","name":"20.png"},
                            {"type":"file","name":"21.png"},
                            {"type":"file","name":"22.png"},
                            {"type":"file","name":"3 Catalogo 2 Portada 2.png"},
                            {"type":"file","name":"4 H 1.png"},
                            {"type":"file","name":"5 H 2.png"},
                            {"type":"file","name":"6 H 3.png"},
                            {"type":"file","name":"7 H 4.png"},
                            {"type":"file","name":"8 H 5.png"},
                            {"type":"file","name":"9 H 6.png"}
                        ]},
                    {"type":"directory","name":"14 CLUB DE GOLF BELLAVISTA, 1 de Agosto de 1981","contents":[
                            {"type":"file","name":"1 Invitacion.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"2 Croquis.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4 Dr. Gregorio Ortega e Hilario.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"15 CAMARA NACIONAL DE LA INDUSTRIA Y LA TRANSFORMACION, 2 de Diciembre de 1981","contents":[
                            {"type":"file","name":"1 Invitaciòn.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"}
                        ]},
                    {"type":"directory","name":"16 FORO CULTURAL COYOACANENSE, 16 de Febrero de 1982","contents":[
                            {"type":"file","name":"1 FORO CULTURAL COYOACANENSE 16 de Febrero de 1982.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"}
                        ]},
                    {"type":"directory","name":"17 CENTRO ACAPULCO, 30 de Junio de 1982","contents":[
                            {"type":"file","name":"1 Invitaciòn I.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"12.png"},
                            {"type":"file","name":"2 Invitaciòn II.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"18 ARTE Y CULTURA, 30 de Mayo de 1983","contents":[
                            {"type":"file","name":"1 Invitaciòn.png"}
                        ]},
                    {"type":"directory","name":"19 DIF TEQUISQUIAPAN, 11 de Agosto de 1983","contents":[
                            {"type":"file","name":"1 Invitaciòn.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"}
                        ]},
                    {"type":"directory","name":"2 BOSQUE DE CHAPULTEPEC, 30 de Abril 1953","contents":[
                            {"type":"file","name":"1, Invitacion Exposiciòn La Fiesta de la Flor, E2BCH1.png"},
                            {"type":"file","name":"2, Participantes, E2BCH2.png"}
                        ]},
                    {"type":"directory","name":"20 HOTEL ALAMEDA, 18 de Agosto de 1983","contents":[
                            {"type":"file","name":"1 Invitaciòn.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"}
                        ]},
                    {"type":"directory","name":"21 CLUB DE GOLF BELLAVISTA Noviembre de 1983","contents":[
                            {"type":"file","name":"1.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6 Dia de Clausura.png"},
                            {"type":"file","name":"7.png"}
                        ]},
                    {"type":"directory","name":"22 GALERIA COYOACAN, 18 de Diciembre de 1983 ","contents":[
                            {"type":"file","name":"1.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"}
                        ]},
                    {"type":"directory","name":"23 GALERIA RESTAURANT YI_L, 1 de Junio de 1984","contents":[
                            {"type":"file","name":"1 Invitaciòn.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"24 GALERIA COYOACAN, 6 de Julio de 1985","contents":[
                            {"type":"file","name":"1 GALERIA COYOACAN, 5 de Julio de 1986.png"},
                            {"type":"file","name":"2.png"}
                        ]},
                    {"type":"directory","name":"25 CLUB DE GOLF BELLAVISTA, 11 de Febrero de 1986","contents":[
                            {"type":"file","name":"1 CLUB DE GOLF BELLAVISTA, 11 de Febrero de 1986.png"},
                            {"type":"file","name":"2.png"}
                        ]},
                    {"type":"directory","name":"26 LA GALERIA, Pue., 1986","contents":[
                            {"type":"file","name":"1 Pintora N, Hilario, Alfonso Tirado, N.png"}
                        ]},
                    {"type":"directory","name":"27 CASA DE LA CULTURA JUAN RULFO, 31 de Julio de 1986","contents":[
                            {"type":"file","name":"1 CASA DE LA CULTURA JUAN RULFO, 31 de Julio de 1986.png"},
                            {"type":"file","name":"2.png"}
                        ]},
                    {"type":"directory","name":"28 GALERIA COYOACAN, Diciembre de 1986","contents":[
                        ]},
                    {"type":"directory","name":"29 ASEGURADORA MEXICANA, 2 de Septiembre de 1987","contents":[
                            {"type":"file","name":"1 ASEGURADORA MEXICANA, S. A. 2 de Septiembre de 1987.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"}
                        ]},
                    {"type":"directory","name":"3 HACIENDA DE LOS MORALES, 15 Julio 1978","contents":[
                            {"type":"file","name":"1.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4 Nisim Moscona e Hilario.png"},
                            {"type":"file","name":"5 Matilde, Interprete baladista e Hilario.png"},
                            {"type":"file","name":"6 Peter Gillham y Arturo Castro.png"},
                            {"type":"file","name":"7 Promotora de la Exhibiciòn, 9.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"30 CONVENTION CENTER LOS ANGELES, CAL., 16 de Octubre de 1987","contents":[
                            {"type":"file","name":"1 LOS ANGELES CONVENTION CENTER, 16 de Octubre de 1987.png"},
                            {"type":"file","name":"2.png"}
                        ]},
                    {"type":"directory","name":"31 GALERIA COYOACAN, 13 de Diciembre de 1989","contents":[
                            {"type":"file","name":"1 Invitaciòn.png"}
                        ]},
                    {"type":"directory","name":"32 CLUB DE GOLF BELLAVISTA, 1988","contents":[
                            {"type":"file","name":"1.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"}
                        ]},
                    {"type":"directory","name":"33 GALERIA COYOACAN, 1988","contents":[
                            {"type":"file","name":"1.png"},
                            {"type":"file","name":"2 Pintores Hilario, Bertha Sandoval, Jose Torres Palomar.png"}
                        ]},
                    {"type":"directory","name":"34 JACOB K. JAVITS CONVENTION CENTER, NEW YORK, 13 de Abril de 1989","contents":[
                            {"type":"file","name":"1 Art Expo NY, 13 de Abril de 1989.png"},
                            {"type":"file","name":"2 Autor y Anthony Quinn.png"},
                            {"type":"file","name":"3 Con el pintor Jesus Leuus.png"},
                            {"type":"file","name":"4.png"}
                        ]},
                    {"type":"directory","name":"35 LA FARGA L_HOSPITALET, BARCELONA, 1989","contents":[
                            {"type":"file","name":"1 LA FARGA L`HOSPITALET, Octubre de 1989.png"},
                            {"type":"file","name":"2.png"}
                        ]},
                    {"type":"directory","name":"36 CAMARA NACIONAL DE COMERCIO DE LA CIUDAD DE MEXICO, 24 de Septiembre de 1991","contents":[
                            {"type":"file","name":"1 INVITACION CAMARA NACIONAL DE COMERCIO, 24 de Septiembre de 1991.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"12.png"},
                            {"type":"file","name":"13 Salvador Lugo Lic Jose Manuel Gallegos, Dn Benito Alvarez Ordoñez copia.png"},
                            {"type":"file","name":"14 Recorrido.png"},
                            {"type":"file","name":"15.png"},
                            {"type":"file","name":"16.png"},
                            {"type":"file","name":"17.png"},
                            {"type":"file","name":"18 Don Vicente Mayo Garcia.png"},
                            {"type":"file","name":"19.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"20.png"},
                            {"type":"file","name":"21 Ing. Jaime Sommer.png"},
                            {"type":"file","name":"22 Dr. Guillermo Saenz Aburto, Dra Aurora Palao, Ma. Elena Aburto Cortes.png"},
                            {"type":"file","name":"23.png"},
                            {"type":"file","name":"24.png"},
                            {"type":"file","name":"25.jpg"},
                            {"type":"file","name":"26n.png"},
                            {"type":"file","name":"27.png"},
                            {"type":"file","name":"28.jpg"},
                            {"type":"file","name":"29.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"30 G Fanyon.png"},
                            {"type":"file","name":"31.png"},
                            {"type":"file","name":"32.png"},
                            {"type":"file","name":"33n.png"},
                            {"type":"file","name":"34.png"},
                            {"type":"file","name":"35.png"},
                            {"type":"file","name":"36.png"},
                            {"type":"file","name":"37 Dr. Eveardo Barojas.png"},
                            {"type":"file","name":"38.png"},
                            {"type":"file","name":"39n.png"},
                            {"type":"file","name":"4 Lic. Gerardo Fanyòn.png"},
                            {"type":"file","name":"40.png"},
                            {"type":"file","name":"41.png"},
                            {"type":"file","name":"42.png"},
                            {"type":"file","name":"43 Carmen Gomez.jpg"},
                            {"type":"file","name":"44n.png"},
                            {"type":"file","name":"45.png"},
                            {"type":"file","name":"46.png"},
                            {"type":"file","name":"47.png"},
                            {"type":"file","name":"48.png"},
                            {"type":"file","name":"49.png"},
                            {"type":"file","name":"4_ David G.png"},
                            {"type":"file","name":"5 Don Benito Alvarez Ordoñez.png"},
                            {"type":"file","name":"50 Clausura.png"},
                            {"type":"file","name":"51 Clausura 3 de Octubre de 1991.png"},
                            {"type":"file","name":"52.png"},
                            {"type":"file","name":"53.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8 Manuel Moyron Buenrostro.png"},
                            {"type":"file","name":"9 Adolfo Gomez, Lic. Gerardo Fanyon, Benito Alvarez Ordoñez, C.P. Raul Caballero.png"}
                        ]},
                    {"type":"directory","name":"37 PALACIO MUNDIAL DE LAS FERIAS CIUDAD DE MEXICO, 13 de Febrero de 1992","contents":[
                            {"type":"file","name":"1 PALACIO MUNDIAL DE LAS FERIAS CDMX., 13 de Febrero de 1992.png"},
                            {"type":"file","name":"2.png"}
                        ]},
                    {"type":"directory","name":"38 CAMARA NACIONAL DE COMERCIO, 1993","contents":[
                            {"type":"file","name":"1 Catalogo Expo Arte Canaco, 1993.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"}
                        ]},
                    {"type":"directory","name":"39 GALERIA COYOACAN, 30 de Junio de 1994","contents":[
                            {"type":"file","name":"1 GALERIA COYOACAN, 30 de Junio de 1994.png"}
                        ]},
                    {"type":"directory","name":"4 CIRCULO SOCIAL GUANAJUATENSE,  A. C., 11 de Agosto 1978","contents":[
                            {"type":"file","name":"1 Invitacion.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"12.png"},
                            {"type":"file","name":"13.png"},
                            {"type":"file","name":"14.png"},
                            {"type":"file","name":"15, Miguel y Amada Cortes, 16.png"},
                            {"type":"file","name":"16.png"},
                            {"type":"file","name":"17.png"},
                            {"type":"file","name":"18.png"},
                            {"type":"file","name":"19 Hija del pintor y Pablo Gòmez .png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"20.png"},
                            {"type":"file","name":"21.png"},
                            {"type":"file","name":"22.png"},
                            {"type":"file","name":"23 Autor y Manuel Moyròn Buenrostro.png"},
                            {"type":"file","name":"24.png"},
                            {"type":"file","name":"25.png"},
                            {"type":"file","name":"26. Kinereth Adberstein.png"},
                            {"type":"file","name":"27.png"},
                            {"type":"file","name":"28.png"},
                            {"type":"file","name":"29 Arturo Velàzquez y Sra..png"},
                            {"type":"file","name":"3 Hilario, Lic. Euquerio Guerrero Lòpez y Sra. Teresa Franco de Bisoso, 4.png"},
                            {"type":"file","name":"30.png"},
                            {"type":"file","name":"31,.png"},
                            {"type":"file","name":"32.png"},
                            {"type":"file","name":"33.png"},
                            {"type":"file","name":"34.png"},
                            {"type":"file","name":"35.png"},
                            {"type":"file","name":"36.png"},
                            {"type":"file","name":"37.png"},
                            {"type":"file","name":"38.png"},
                            {"type":"file","name":"39.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"40.png"},
                            {"type":"file","name":"41.png"},
                            {"type":"file","name":"42.png"},
                            {"type":"file","name":"43.png"},
                            {"type":"file","name":"44.png"},
                            {"type":"file","name":"45.png"},
                            {"type":"file","name":"46.png"},
                            {"type":"file","name":"47, Mtro. Mendarozqueta y Carmen Gomez.png"},
                            {"type":"file","name":"48, Antonia y Maria Loreto Gomez Quiroz, 48.png"},
                            {"type":"file","name":"49, Marcos Solodkin y Sra..png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"50.png"},
                            {"type":"file","name":"51.png"},
                            {"type":"file","name":"52.png"},
                            {"type":"file","name":"53.png"},
                            {"type":"file","name":"54 Pintor Marco Antonio Zepeda Moreno, Herlinda C. de Gomez e Hilario.png"},
                            {"type":"file","name":"55.png"},
                            {"type":"file","name":"56.png"},
                            {"type":"file","name":"57.png"},
                            {"type":"file","name":"58.png"},
                            {"type":"file","name":"59.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"60.png"},
                            {"type":"file","name":"61.png"},
                            {"type":"file","name":"62.png"},
                            {"type":"file","name":"63.png"},
                            {"type":"file","name":"64.png"},
                            {"type":"file","name":"65 Hija del pintor y Edelmira Zuñiga.png"},
                            {"type":"file","name":"66 Sra, Silvia Castrejòn, Lic, Josè Arredondo, esposa del pintor.png"},
                            {"type":"file","name":"67 Rafael Gomez Juarez.png"},
                            {"type":"file","name":"68 Hilario y Marcos Solodkin.png"},
                            {"type":"file","name":"69 Soledad Gomez Quiroz.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"70.png"},
                            {"type":"file","name":"8 Carmen Gòmez e hija del pintor.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"40 MUSEO UNIVERSITARIO CONTEMPORANEO DE ARTE, 28 de Febrero de 1995","contents":[
                            {"type":"file","name":"1 Invitaciòn Inauguracion, Museo Universitario Contemporaneo de Arte, 28 de Febrero de 1995.png"},
                            {"type":"file","name":"2 Del 28 de Febrero al 20 de Junio de 1995.png"},
                            {"type":"file","name":"3 Croquis.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5 Hilario.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7 Lic. Rodolfo Rivera.png"},
                            {"type":"file","name":"8 Libro.png"}
                        ]},
                    {"type":"directory","name":"41 MUSEO UNIVERSITARIO CONTEPORANEO DE ARTE, 12 de Mayo de 1995","contents":[
                            {"type":"file","name":"1 INVITACION MUCA, 12 de Mayo de 1995.png"},
                            {"type":"file","name":"10 .png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"12.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5 Pintor Manuel Echauri Villaseñor, miembro fundador del Salòn de la Plastica Mexicana e Hilario.png"},
                            {"type":"file","name":"6 Con pintores Juan Bernal y Alvaro Mendoza.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"42 MUSEO DE ARTE DE QUERETARO, 1995","contents":[
                            {"type":"file","name":"1 Arq. Margarita Magdaleno Rojas, Directora del Museo de Arte de Queretaro.png"},
                            {"type":"file","name":"1995 Museo de Queretaro II.png"},
                            {"type":"file","name":"1995 Museo de Queretaro copia.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"}
                        ]},
                    {"type":"directory","name":"43 PRESIDENCIA MUNICIPAL DE GUANAJUATO, 11 de Julio de 2002","contents":[
                            {"type":"file","name":"1 PRESIDENCIA MUNICIPAL DE GUANAJUATO, 11 de Julio de 2002.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"12.png"},
                            {"type":"file","name":"13.png"},
                            {"type":"file","name":"14.png"},
                            {"type":"file","name":"15.png"},
                            {"type":"file","name":"16.png"},
                            {"type":"file","name":"17.png"},
                            {"type":"file","name":"18.png"},
                            {"type":"file","name":"19.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"20.png"},
                            {"type":"file","name":"21.png"},
                            {"type":"file","name":"22.png"},
                            {"type":"file","name":"23.png"},
                            {"type":"file","name":"24.png"},
                            {"type":"file","name":"25.png"},
                            {"type":"file","name":"26.png"},
                            {"type":"file","name":"27.png"},
                            {"type":"file","name":"28.png"},
                            {"type":"file","name":"29.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"44 MUSEO FRANZ MAYER, 1992","contents":[
                        ]},
                    {"type":"directory","name":"45 CASA DE CULTURA DE GUANAJUATO, 27 de Junio de 2003","contents":[
                            {"type":"file","name":"1 CASA DE CULTURA DE GUANAJUATO, 27 de Junio de 2003, Invitaciòn.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"12.png"},
                            {"type":"file","name":"13.png"},
                            {"type":"file","name":"14 Sra. Irene Buchanan Gonzalez, Directora de Turismo en representaciòn del Alcalde Arq. Rafael Villagòmez Mapes.png"},
                            {"type":"file","name":"15.png"},
                            {"type":"file","name":"16.png"},
                            {"type":"file","name":"17 Sr, Jose Antonio Campos, Coordinador Academico de la Direcciòn Cultura de Guanajuato e hijas del pintor.png"},
                            {"type":"file","name":"18n.png"},
                            {"type":"file","name":"19.png"},
                            {"type":"file","name":"2 .png"},
                            {"type":"file","name":"20.png"},
                            {"type":"file","name":"21.png"},
                            {"type":"file","name":"22.png"},
                            {"type":"file","name":"23.png"},
                            {"type":"file","name":"24.png"},
                            {"type":"file","name":"25.png"},
                            {"type":"file","name":"26 Mtro. Mauricio Vàzquez Gonzàlez, Director Municipal de Cultura de Guanajuato e hija del pintor .png"},
                            {"type":"file","name":"3 Fiestas de San Juan.png"},
                            {"type":"file","name":"4 Fiestas de San Juan, Exposiciones.png"},
                            {"type":"file","name":"5 Patio Central Casa de Cultura de Guanajuato.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"46 CASA DE CULTURA Y PLAZA JUAREZ DE GUANAJUATO 2008","contents":[
                            {"type":"file","name":"1 Inauguracion, Mtro. Jesùs Antonio Borja, Dir. de la Casa de Cultura de Gto..png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11 Director del Museo Casa Diego Rivera de Guanajuato.png"},
                            {"type":"file","name":"12 Arq, Daniel Ruvalcaba, Director de Conservaciòn del Patrimonio Cultural del Instiruto Estatal.png"},
                            {"type":"file","name":"13.png"},
                            {"type":"file","name":"15. Muestra en la Plaza Juàrez.png"},
                            {"type":"file","name":"16.png"},
                            {"type":"file","name":"17.png"},
                            {"type":"file","name":"2 Aurora Gòmez.png"},
                            {"type":"file","name":"3 Silvia Gòmez Mendoza, nieta del pintor.png"},
                            {"type":"file","name":"4 .png"},
                            {"type":"file","name":"5 Lic. Eduardo Lòpez Goesne, Srio del Ayuntamiento.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7 Hector Peñarrieta Morales_ Promotor del evento.png"},
                            {"type":"file","name":"8 Muestra de la exposiciòn en la Casa de Cultura.png"},
                            {"type":"file","name":"9.png"},
                            {"type":"file","name":"Director del Museo Casa Diego Rivera y Silvia Mendoza, nieta del pintor.png"}
                        ]},
                    {"type":"directory","name":"47 OTROS EVENTOS","contents":[
                            {"type":"file","name":"1978 1 el 19 de Agosto, Evento Tierra de mis amores del compositor Jesùs Elizarraràs, 19 de Agosto de 1978.png"},
                            {"type":"file","name":"1978 2 Con Don Euquerio Guerrero Lòpez.png"},
                            {"type":"file","name":"1978 3 Con Jesùs Elizarraràs.png"},
                            {"type":"file","name":"1978 4 Con Francisco Merino Arauz.png"},
                            {"type":"file","name":"1979 Mesa Redonda I, Diciembre.png"},
                            {"type":"file","name":"1979 Mesa Redonda II.png"},
                            {"type":"file","name":"1980 De sus 78 años I.png"},
                            {"type":"file","name":"1980 De sus 78 años II.png"},
                            {"type":"file","name":"1980 De sus 78 años III.png"},
                            {"type":"file","name":"1980 Fomento Cultural I.png"},
                            {"type":"file","name":"1980 Fomento Cultural II.png"},
                            {"type":"file","name":"1980 Muestra de obra de Froylan Ojeda, acompaña el pintor Leopoldo Estrada.png"}
                        ]},
                    {"type":"directory","name":"5 RECINTO DE LA GRAN COMISION DE LA CAMARA DE DIPUTADOS, 4 de Octubre de 1978","contents":[
                            {"type":"file","name":"1 Invitacion.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11.png"},
                            {"type":"file","name":"12.png"},
                            {"type":"file","name":"13,.png"},
                            {"type":"file","name":"14.png"},
                            {"type":"file","name":"15.png"},
                            {"type":"file","name":"16.png"},
                            {"type":"file","name":"17.png"},
                            {"type":"file","name":"18.png"},
                            {"type":"file","name":"19.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"20.png"},
                            {"type":"file","name":"21.png"},
                            {"type":"file","name":"22.png"},
                            {"type":"file","name":"23.png"},
                            {"type":"file","name":"24.png"},
                            {"type":"file","name":"25.png"},
                            {"type":"file","name":"26 Anita Blanch.png"},
                            {"type":"file","name":"27.png"},
                            {"type":"file","name":"28.png"},
                            {"type":"file","name":"29.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"30.png"},
                            {"type":"file","name":"31.png"},
                            {"type":"file","name":"32.png"},
                            {"type":"file","name":"33.png"},
                            {"type":"file","name":"34.png"},
                            {"type":"file","name":"35.png"},
                            {"type":"file","name":"36.png"},
                            {"type":"file","name":"37.png"},
                            {"type":"file","name":"38.png"},
                            {"type":"file","name":"39.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"40.png"},
                            {"type":"file","name":"41.png"},
                            {"type":"file","name":"42.png"},
                            {"type":"file","name":"43.png"},
                            {"type":"file","name":"44.png"},
                            {"type":"file","name":"45.png"},
                            {"type":"file","name":"46.png"},
                            {"type":"file","name":"47.png"},
                            {"type":"file","name":"48.png"},
                            {"type":"file","name":"49.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"50.png"},
                            {"type":"file","name":"51.png"},
                            {"type":"file","name":"52.png"},
                            {"type":"file","name":"53.png"},
                            {"type":"file","name":"54.png"},
                            {"type":"file","name":"55.png"},
                            {"type":"file","name":"56.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9.png"}
                        ]},
                    {"type":"directory","name":"6 MUSEO DE LA CIUDAD DE MEXICO, 16 de Febrero de 1979","contents":[
                            {"type":"file","name":"1 MUSEO DE LA CIUDAD DE MEXICO, 16 de Febrero de 1979.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"}
                        ]},
                    {"type":"directory","name":"7 GALERIA CARLOS PELLICER, 29 de Marzo de 1979","contents":[
                            {"type":"file","name":"1 Invitacion.png"}
                        ]},
                    {"type":"directory","name":"8 PRESIDENCIA MUNICIPAL DE GUANAJUATO, 26 de Abril de 1979","contents":[
                            {"type":"file","name":"1.png"},
                            {"type":"file","name":"10.png"},
                            {"type":"file","name":"11 Arq. Eduardo Knapp Aguilar, Presidente Municipal Presidente Municipal.png"},
                            {"type":"file","name":"12 Consejo Tècnico de Administraciòn de Fonapas.png"},
                            {"type":"file","name":"13.png"},
                            {"type":"file","name":"14.png"},
                            {"type":"file","name":"15.png"},
                            {"type":"file","name":"16.png"},
                            {"type":"file","name":"17.png"},
                            {"type":"file","name":"18 Sras Lilia Garcia de Maldonado y Lynn Hankock.png"},
                            {"type":"file","name":"19 Lic. Pedro Vazquez Nieto, Dir, Gral. Fonapas Guanajuato.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"20.png"},
                            {"type":"file","name":"21 Entrevista.png"},
                            {"type":"file","name":"22 Nietos felicitandolo.png"},
                            {"type":"file","name":"23 Despuès de la felicitacion de sus nietos.png"},
                            {"type":"file","name":"24.png"},
                            {"type":"file","name":"25.png"},
                            {"type":"file","name":"26.png"},
                            {"type":"file","name":"27.png"},
                            {"type":"file","name":"28.png"},
                            {"type":"file","name":"29.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"30.png"},
                            {"type":"file","name":"31.png"},
                            {"type":"file","name":"32 Entrevista.png"},
                            {"type":"file","name":"33.png"},
                            {"type":"file","name":"34.png"},
                            {"type":"file","name":"35 Lic. Luis H. Ducoin, Gobernador del Estado de Guanajuato.png"},
                            {"type":"file","name":"36.png"},
                            {"type":"file","name":"37 Dr. y Dn Everardo.png"},
                            {"type":"file","name":"38.png"},
                            {"type":"file","name":"39.png"},
                            {"type":"file","name":"4.png"},
                            {"type":"file","name":"40.png"},
                            {"type":"file","name":"41.png"},
                            {"type":"file","name":"42.png"},
                            {"type":"file","name":"43.png"},
                            {"type":"file","name":"44.png"},
                            {"type":"file","name":"45.png"},
                            {"type":"file","name":"46.png"},
                            {"type":"file","name":"47.png"},
                            {"type":"file","name":"48.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"},
                            {"type":"file","name":"7.png"},
                            {"type":"file","name":"8.png"},
                            {"type":"file","name":"9 Poster.png"}
                        ]},
                    {"type":"directory","name":"9 MUSEO DE BELLAS ARTES DE TOLUCA, 13 de Julio de 1979","contents":[
                            {"type":"file","name":"1 Invitacion.png"},
                            {"type":"file","name":"2.png"},
                            {"type":"file","name":"3.png"},
                            {"type":"file","name":"4 Pintores Leopoldo Estrada, Alicia Saloma, Macrina Krauss. atras, Garcia Castro, Hilario Gomez, Enrique Ortega, Joaquin Martinez Navarrete, Alfonso Tirado, Alfonso Ortega Ugarte, Centro Lic. Jose Antonio Muñoz Samayoa.png"},
                            {"type":"file","name":"5.png"},
                            {"type":"file","name":"6.png"}
                        ]}
                ]}
        ]}

export default index;