import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const useStyles = makeStyles((theme) => ({
    gridList: {
        // width: 1000,
        // height: 1000,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    divImg: {
        height:'100%',
        width:'100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    }
}));

const getIndex = (a) => {
    return parseInt(a.name.split('-')[0]);
};

export default function Grid(props) {
    const classes = useStyles();
    const items = props.items.sort(function(a, b) {
        return getIndex(a) - getIndex(b);
    });

    const title = (name) => name.split('.')[0];
    const [open, setOpen] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);


    const handleClick = (index) => {
        setPhotoIndex(index);
        setOpen(true);
    };

    const [state, setState] = useState({
        mobileView: false,
    });

    const { mobileView } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    const cols = mobileView ? 2 : 0.5;
    const height = mobileView ? 400 : 250;

    return (
        <div>
            <GridList cellHeight={300}  spacing={30} className={classes.gridList}>
                <GridListTile key="Subheader" cols={4} style={{ height: 'auto' }}>
                    <ListSubheader component="div"/>
                </GridListTile>
                {items.map((item, idx) => (
                    <GridListTile
                        cols={cols}
                        style={{ height: height, cursor: 'pointer', padding: 10 }}
                        key={item.name}
                        onClick={() =>handleClick(idx)}
                    >
                        <div className={classes.divImg} style={{backgroundImage: 'url("'+item.url+'")'}}  />
                        <GridListTileBar
                            title={title(item.name)}
                            actionIcon={
                                <IconButton aria-label={`info about ${item.name}`} className={classes.icon}>
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                    </GridListTile>
                ))}
            </GridList>

            {open && <Lightbox
                imagePadding={50}
                imageCaption={items[photoIndex].name}
                mainSrc={items[photoIndex].url}
                nextSrc={items[(photoIndex + 1) % items.length].url}
                prevSrc={items[(photoIndex + items.length - 1) % items.length].url}
                onCloseRequest={() => setOpen(false)}
                onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + items.length - 1) % items.length)
                }
                onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % items.length)
                }
            />}
        </div>
    );
}