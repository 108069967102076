const index = {"type":"directory","name":"museo","contents":[
                        {"type":"directory","name":"3-3 TESTIMONIOS","contents":[
                                        {"type":"directory","name":"1 ACADEMICOS","contents":[
                                                        {"type":"file","name":"1 ESCUELA SUPERIOR BENITO JUAREZ, Certificado Educacion Primaria Superior, 8 de Enero de 1916.png"},
                                                        {"type":"file","name":"10 ENBA, Examen Modelo Desnudo, 15 de Noviembre de 1920.png"},
                                                        {"type":"file","name":"11 ENBA, Resultado por Sostenes Ortega, Luis Golmano y Fco de la Torre, 15 de Noviembre de 1920.png"},
                                                        {"type":"file","name":"12 ENBA, Inscripciòn Modelo Desnudo, 15 de Febrero de 1921.png"},
                                                        {"type":"file","name":"13 ENBA, Examen 2o Modelo Desnudo, 3 de Noviembre de 1921.png"},
                                                        {"type":"file","name":"14 ENBA, Resultado por Sostenes Ortega, Leandro Izaguirre y Fco de la Torre, 3 de Noviembre de 1921.png"},
                                                        {"type":"file","name":"15 ENBA, Inscripciòn Taller del Dibujo Modelo Desnudo, 8 de Enero de 1923.png"},
                                                        {"type":"file","name":"16 ENBA, Recibo Inscripciòn, 10 de Enero de 1923.png"},
                                                        {"type":"file","name":"17 ENBA, Examen Dibujo del Modelo Desnudo, 5 de Noviembre de 1923.png"},
                                                        {"type":"file","name":"18 ENBA, Resultado por Sostenes Ortega, Leandro Izaguirre y Mateo Herrera, 5 de Noviembre de 1923.png"},
                                                        {"type":"file","name":"19 ENBA, Inscripciòn Modelo Desnudo, 7 de Febrero de 1924.png"},
                                                        {"type":"file","name":"2 ENBA, Inscripciòn Objetos Usuales, 13 de Marzo de 1917.png"},
                                                        {"type":"file","name":"20 ENBA, Inscripciòn como Alumno Asistente, 9 de Febrero de 1926.png"},
                                                        {"type":"file","name":"21 ENBA, Recibo de pago, 1 de Marzo de 1927.png"},
                                                        {"type":"file","name":"22 ENBA, Inscripciòn asistente, 1 de Marzo de 1927.png"},
                                                        {"type":"file","name":"23 ESCUELA LIBRE DE MÙSICA Y DECLAMACIÒN, Violin y Solfeo 1o., 18 de Diciembre de 1927.png"},
                                                        {"type":"file","name":"24 ESCUELA LIBRE DE MUSICA Y DECLAMACIÒN, Violìn y Solfeo, 8 de Febrero 1928.png"},
                                                        {"type":"file","name":"25 ENBA, Inscripciòn como Alumno Asistente, 2 Septiembre 1928.png"},
                                                        {"type":"file","name":"3 ENBA, Inscripciòn Yesos Clasicos, 26 de Abril de 1918.png"},
                                                        {"type":"file","name":"4 ENBA, Examen Dibujo al Carbòn, 20 de Noviembre de 1918.png"},
                                                        {"type":"file","name":"5 ENBA, Resultado Examen, por Andrès Rios, Adrian de Unzueta y Mateo A Saldaña, 20 de Noviembre de 1918.png"},
                                                        {"type":"file","name":"6 ENBA, Inscripciòn Modelo Desnudo, 24 de Enero de 1919.png"},
                                                        {"type":"file","name":"7 ENBA, Examen Dibujo a Mano Libre, 3 de Noviembre de 1919.png"},
                                                        {"type":"file","name":"8 ENBA, Resultado por Sostenes Ortega, Alfonso Garduño y Adrian de Unzueta, 3 de Noviembre de 1919.png"},
                                                        {"type":"file","name":"9 ENBA, Inscripciòn Modelo Desnudo, 27 de Enero de 1920.png"}
                                                ]},
                                        {"type":"directory","name":"2 DE MURALES","contents":[
                                                        {"type":"file","name":"1 ENBA, Carta Prof. Sostenes Ortega al C. Director, Hoja 1, 27 de Julio de 1926.png"},
                                                        {"type":"file","name":"2 ENBA, Hoja 2.png"},
                                                        {"type":"file","name":"3 ENBA, Anexo 1.png"},
                                                        {"type":"file","name":"4 ENBA, Anexo 2.png"}
                                                ]},
                                        {"type":"directory","name":"3 DE SUS RECUERDOS Y AFECTOS","contents":[
                                                        {"type":"file","name":"1 Foto de Enrique Ochoa, con Jose Clemente Orozco, 1923.png"},
                                                        {"type":"file","name":"10 Del autor Garly, Dècada de los 70´s.png"},
                                                        {"type":"file","name":"11 De una nieta.png"},
                                                        {"type":"file","name":"12 De una nieta, Dècada de los 80´s.png"},
                                                        {"type":"file","name":"14 De su nieta Karina II, Dècada de los 80´s.png"},
                                                        {"type":"file","name":"15 Dibujo ejecutado por Aurora Gomez, 1983.png"},
                                                        {"type":"file","name":"16 De una nieta.png"},
                                                        {"type":"file","name":"16 Titulo de Ciudad del 8 de Diciembre de 1741, Editado en 1985.png"},
                                                        {"type":"file","name":"17 De su nieta Karina I, Dècada de los 80´s.png"},
                                                        {"type":"file","name":"17 Edicion Especial, Dedicatoria de Hector Peñarrieta Morales.png"},
                                                        {"type":"file","name":"18 De su nieta Eli, Dècada de los 80´s.png"},
                                                        {"type":"file","name":"19 De su nieta Ayhde II, 1992.png"},
                                                        {"type":"file","name":"19 De su nieta Eli, Década de los 80´s.png"},
                                                        {"type":"file","name":"2 Retrato ejecutado por Fernando Gamboa Bustos, 1926.png"},
                                                        {"type":"file","name":"20 De sus nietos Cinthia y Samoel, 1993.png"},
                                                        {"type":"file","name":"22 De su nieta Ahydè I, 1992.png"},
                                                        {"type":"file","name":"23 De su nieta Cinthia, 1993.png"},
                                                        {"type":"file","name":"24 De su nieto Samoel, 1993.png"},
                                                        {"type":"file","name":"25 Dibujo a Lapiz ejecutado por Peña, 1994.png"},
                                                        {"type":"file","name":"3 Foto Puebla, dedicó Roberto de la Cueva del Rio, 1927 .png"},
                                                        {"type":"file","name":"4 Foto Puebla, reverso.png"},
                                                        {"type":"file","name":"5 Acuarela ejecutada por A Leon, ca. 1935.png"},
                                                        {"type":"file","name":"6 De sus alumnos, dècada 30_s.png"},
                                                        {"type":"file","name":"7 Mtro. Jose Lopez Alavez, 1948.png"},
                                                        {"type":"file","name":"8 Mtro. Jose Lopez Alavez, reverso, 1948.png"},
                                                        {"type":"file","name":"9 De su hija Aurora, Dècada de los 70´s.png"}
                                                ]},
                                        {"type":"directory","name":"4 DONACIONES","contents":[
                                                        {"type":"file","name":"1 SOLIDARIDAD NICARAGUA, 15 agosto 1979.png"},
                                                        {"type":"file","name":"2 ASEGURADORA MEXICANA, S.A. 1, 2 septiembre de 1987.png"},
                                                        {"type":"file","name":"3 ASEGURADORA MEXICANA, S.A. 2,.png"},
                                                        {"type":"file","name":"4 GRUPO CAMINA, 25 de Noviembre de 1987.png"},
                                                        {"type":"file","name":"5 TELEVISA, septiembre 1988.png"},
                                                        {"type":"file","name":"6 HOMENAJE POSTUMO MAURICIO GROSO, 20 de abril de 1989.png"}
                                                ]},
                                        {"type":"directory","name":"5 SOLICITUDES, CARTAS Y MISIVAS","contents":[
                                                        {"type":"file","name":"1 ENBA, Recado inscripcion del Prof. Andres Rios, 13 de marzo de 1917.png"},
                                                        {"type":"file","name":"2 SALON DE LA PLASTICA MEXICANA, Solicitud para ser miembro, mayo de 1979.png"},
                                                        {"type":"file","name":"3 MUSEO DE LA CIUDAD DE MEXICO, Solicitud a Cia. Vinicola de Aguascalientes, 22 de enero de 1979.png"},
                                                        {"type":"file","name":"4 UNIVERSIDAD DE GUANAJUATO, Instruccion acceso, realizar cuadro Cùpula de la Compañia, 16 de mayo de 1980.png"},
                                                        {"type":"file","name":"5 CONSULADO, LOS ANGELES, CAL.. 1 de junio de 1983.png"},
                                                        {"type":"file","name":"6 AL MUSEO NACIONAL DE ARTE, Solicitud incluciòn en exposiciones, 18 de Noviembre de 2005.png"}
                                                ]},
                                        {"type":"directory","name":"6 RECIBOS","contents":[
                                                        {"type":"file","name":"1 INBA, RECEPCION OBRA, 12 de septiembre de 1968.png"},
                                                        {"type":"file","name":"2 MUSEO DE LA CIUDAD DE MEXICO, 9 de febrero de 1979.png"},
                                                        {"type":"file","name":"3 MUSEO NACIONAL DE ARTE, 29 de Agosto de 1984.png"},
                                                        {"type":"file","name":"4 MUSEO NACIONAL DE ARTE, 31 de enero de 1985.png"}
                                                ]},
                                        {"type":"directory","name":"7 ACTAS Y DOCUMENTOS PERSONALES","contents":[
                                                        {"type":"file","name":"1 BAUTIZO, como Jose Abundio, 13 de julio de 1902.png"},
                                                        {"type":"file","name":"2 ACTA MATRIMONIO, 29 de junio de 1942.png"},
                                                        {"type":"file","name":"3 INVITACION DE SU MATRIMONIO, 1942.png"},
                                                        {"type":"file","name":"4 ACTA CERTIFICACION BAUTIZO, conocido como Hilario, 21 mayo 1980.png"},
                                                        {"type":"file","name":"5 ACTA NACIMIENTOm como Hilario, 7 Mayo 1982.png"},
                                                        {"type":"file","name":"6 ACTA NACIMIENTO Frente, 24 de octubre de 1989.png"},
                                                        {"type":"file","name":"7 ACTA NACIMIENTO Reverso, 1989.png"}
                                                ]},
                                        {"type":"directory","name":"8 VARIOS","contents":[
                                                        {"type":"file","name":"1 CONTRATO DE ARRENDAMIETO, 28 de Agosto de 1919.png"},
                                                        {"type":"file","name":"2 MUSEO MURAL DIEGO RIVERA, 15 de Noviembre de 2007.png"}
                                                ]}
                                ]},
                        {"type":"directory","name":"3-4 ALUCIONES ","contents":[
                                        {"type":"file","name":"1 FRANCISCO MERINO ARAUZ 1, 12 de Agosto de 1978.png"},
                                        {"type":"file","name":"2 FRANCISCO MERINO ARAUZ 2.png"},
                                        {"type":"file","name":"3 MANUEL MOYRON BUENROSTRO, 12 de Agosto de 1978.png"},
                                        {"type":"file","name":"4 JACOBO ZABLUDOVSKY, Noticiero, 17 de Febrero de 1979.png"}
                                ]},
                        {"type":"directory","name":"3-7 RELATOS","contents":[
                                        {"type":"file","name":"1 Roberto Ortega Rodriguez, 26 Febrero 2003.pdf"}
                                ]},
                        {"type":"directory","name":"3-9 RECONOCIMIENTOS","contents":[
                                        {"type":"file","name":"1, CAMARA DE DIPUTADOS, 1978.png"},
                                        {"type":"file","name":"3,MUSEO DE ARTE DE QUERETARO, 1995.png"},
                                        {"type":"file","name":"4, ASEMEX, 1987.png"},
                                        {"type":"file","name":"5, CENTRO DE INVESTIGACION Y SERVICIOS MUSEOLOGICOS, MUCA, 1995.png"},
                                        {"type":"file","name":"6, PRESIDENCIA MUNICIPAL DE GUANAJUATO, 2002.png"},
                                        {"type":"file","name":"7, GOBIERNO DEL ESTADO DE GUANAJUATO, 2010.png"}
                                ]}
                ]}

export default index;