import Page from "./Page";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark, // "#e6e6ea"
        minHeight: "100%",
        paddingTop: theme.spacing(13),
        paddingBottom: theme.spacing(3),
    },
    text: {
        textAlign: "left",
    },
    container: {
        paddingTop: 8,
        paddingLeft: 32,
        paddingRight: 32,
        backgroundColor: "transparent",
        color: "black",
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 1,
            paddingRight: 1,
        }
    },
    footer: {
        textAlign: "center",
    },
    footerTitle: {
    },
    img: {
        width: 500,
        height: "auto",
        margin: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto'
        }
    },
    title: {
        borderBottom: "3px solid #098BCC",
        textTransform: "uppercase",
    }
}));

function SinglePage({title, headerTitle, children}) {
    const classes = useStyles();
    const now = new Date();
    return (
        <Page className={classes.root} title={title}>
            <Container maxWidth="lg" className={classes.container}>
                {headerTitle ?
                    <Container maxWidth="md" style={{marginBottom: 10}}>
                        <Typography variant="h4" className={classes.title}>{headerTitle}</Typography>
                    </Container>
                : null }
                {children}
            </Container>

            <Container maxWidth="lg" className={classes.footer}>
                <Typography variant="subtitle2" className={classes.footerTitle}>Derechos reservados {now.getFullYear()}.</Typography>
            </Container>
        </Page>
    );
}

export default SinglePage;
