import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    Button,
    IconButton,
    Drawer,
    Link,
    MenuItem, Menu,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import museo from "../data/museo";
import obra from "../data/obra";
import galeria from "../data/galeria";
import {toCapitalCase} from "../utils";

const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#f4f4fa",
        color:"black",
        paddingRight: "79px",
        paddingLeft: "118px",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        },
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 600,
        color: "black",
        textAlign: "left",
    },
    menuButton: {
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    drawerContainer: {
        padding: "20px 30px",
    },
    subtitle: {
        fontWeight: 100,
    },
}));

export default function Header() {
    const { header, logo, menuButton, toolbar, drawerContainer, subtitle } = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const { mobileView, drawerOpen } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    const parseName = (name) => {
        const n = name.split(' ');
        const p = n.length === 1 ? name : n.slice(1).join(' ');
        return toCapitalCase(p);
    };

    const createRecursiveMenu = (path, items) => {
        return items.map((item) =>
            <MenuItem
                key={item.name}
                onClick={handleClose}
                component={RouterLink}
                to={path + '?q=' + item.name}
            >
                {parseName(item.name)}
            </MenuItem>
        );
    };

    const handleClose = () => {
        setAnchorEl(null);
        handleDrawerClose();
    };

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const displayDesktop = () => {
        return (
            <Toolbar className={toolbar}>
                {mainLogo}
                <div>{getMenuButtons()}</div>
            </Toolbar>
        );
    };

    const handleDrawerOpen = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
        setState((prevState) => ({ ...prevState, drawerOpen: false }));


    const displayMobile = () => {
        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>

                <Drawer
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <div className={drawerContainer}>{getDrawerChoices()}</div>
                </Drawer>

                <div>{mainLogo}</div>
            </Toolbar>
        );
    };

    const getDrawerChoices = () => {
        return headersData.map(({ label, dropdown, id, href }) => {
            if (dropdown) {
                return(
                    <>
                        <Link
                            onClick={handleMenu}
                            color="inherit"
                            id={id}
                            style={{textDecoration: "none"}}
                        >
                            <MenuItem>{label}</MenuItem>
                        </Link>

                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={open && anchorEl.id === id}
                            onClose={handleClose}
                        >
                            {dropdown}
                        </Menu>
                    </>
                );
            }

            return (
                <Link
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        onClick: handleDrawerClose,
                        style: { textDecoration: "none" },
                        key: label,
                    }}
                >
                    <MenuItem>{label}</MenuItem>
                </Link>
            );
        });
    };

    const mainLogo = (
        <Typography type="title" color="inherit" className={logo}>
            Museo Virtual del Pintor Mexicano <br />
            <span className={subtitle}>HILARIO GOMEZ SANCHEZ</span>
        </Typography>
    );

    const headersData = [
        {
            label: "Inicio",
            href: "/",
        },
        {
            label: "Nosotros",
            href: "/nosotros",
        },
        {
            label: "Museo",
            id: "museo",
            dropdown: <>
                <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/museo/introduccion"
                >
                    Introduccion
                </MenuItem>
                <MenuItem
                  onClick={handleClose}
                  component={RouterLink}
                  to="/museo/biografia"
                >
                    Biografia
                </MenuItem>
                {/*<MenuItem*/}
                {/*  onClick={handleClose}*/}
                {/*  component={RouterLink}*/}
                {/*  to="/museo/sintesis-biografia"*/}
                {/*>*/}
                {/*    Sintesis Biografica*/}
                {/*</MenuItem>*/}
                {createRecursiveMenu('/museo/', museo.contents)}
            </>
        },
        {
            label: "Obra",
            id:"obra",
            dropdown: <span>{createRecursiveMenu('/obra', obra.contents)}</span>
        },
        {
            label: "Galeria",
            id:"galeria",
            dropdown: <span>{createRecursiveMenu('/galeria', galeria.contents)}</span>
        },
        {
            label: "Contacto",
            href: "/contacto",
        },
    ];

    const getMenuButtons = () => {
        return headersData.map(({ label, dropdown, id, href }) => {
            if (dropdown) {
                return (
                    <>
                        <Button
                            onClick={handleMenu}
                            color="inherit"
                            id={id}
                            className={menuButton}
                        >
                            {label}
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={open && anchorEl.id === id}
                            onClose={handleClose}
                        >
                            {dropdown}
                        </Menu>

                    </>
                )
            }
            return (
                <Button
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                        className: menuButton,
                    }}
                >
                    {label}
                </Button>
            );
        });
    };

    return (
        <header>
            <AppBar className={header} style={{zIndex:100}}>
                {mobileView ? displayMobile() : displayDesktop()}
            </AppBar>
        </header>
    );
}