const index = {"type":"directory","name":"obra","contents":[
            {"type":"directory","name":"1 PAISAJE","contents":[
                    {"type":"directory","name":"1 HOMENAJE A GUANAJUATO","contents":[
                            {"type":"file","name":"1 Calle Padre Hidalgo, Sin firma, ca. 1970, Òleo_masonite, 60 x 50 cm., 70-8.png"},
                            {"type":"file","name":"10 Mineral de Cata, 1975, Òleo_tela, 70 x 50 cm., 75-7.png"},
                            {"type":"file","name":"11 Callejòn Mexiamora, 1975, Òleo_tela, 65 x 45 cm., 75-10.png"},
                            {"type":"file","name":"12 Calle Padre Hidalgo, 1976, Òleo_tela, 55 x 66.5 cm., 76-2.png"},
                            {"type":"file","name":"13 Calle Subterrànea Miguel Hidalgo III, 1976, Òleo_tela, 100 x 70 cm., 76-7.png"},
                            {"type":"file","name":"14 Callejón Mexiamora II, 1976, Òleo_tela, 80 x 60 cm 76-8.png"},
                            {"type":"file","name":"15 Callejòn El Laurel II, ca. 1975, Òleo_tela, 65 x 45 cm., 75-9.png"},
                            {"type":"file","name":"16 Plazuela de San Roque, 1977, Òleo_tela, 65.5 x 45 cm., 77-2.png"},
                            {"type":"file","name":"17 Callejòn de Cantaritos, 1977, Òleo_tela, 80 x 60 cm., 77-3.png"},
                            {"type":"file","name":"18 Callejòn de la Galarza, 1978, Òleo_tela, 80 x 60 cm., 78-2.png"},
                            {"type":"file","name":"19 Puente de Peñaranda I, 1978, Òleo_tela, 80 x 70 cm., 78-3.png"},
                            {"type":"file","name":"2 Calle Subterrànea Miguel Hidalgo I, 1974, Òleo_tela, 80 x 60 cm., 74-1.png"},
                            {"type":"file","name":"20 Callejòn El Apartado, 1978, Òleo_tela  70 x 45 cm., 78-4.png"},
                            {"type":"file","name":"21 Los Picachos, El Cerro de la Bufa, 1978, Òleo_tela, 100 x 70 cm., 78-7.png"},
                            {"type":"file","name":"22 Plazuela de Cata I, 1978, Òleo_tela, 80 x 60 cm., 78-10.png"},
                            {"type":"file","name":"23 Plazuela de Cata II, 1878, Òleo_tela, 80 x 60 cm., 78-11.png"},
                            {"type":"file","name":"24 Calle Padre Belauzaràn, 1978, Òleo_tela, 60.5 x 90 cm., 78-13.png"},
                            {"type":"file","name":"25 Callejon de la Galarza nocturno, 1979, Òleo_tela, 80 x 60 cm., 79-3.png"},
                            {"type":"file","name":"26 San Roque 6.30 p.m., 1979, Òleo_tela, 100 x 80 cm., 79-4.png"},
                            {"type":"file","name":"27 Templo Marfil de Abajo, 1979, Òleo_tela, 60 x 50 cm., 79-6.png"},
                            {"type":"file","name":"28 Mineral de Cata II, 1975, Òleo_tela, 80 x 60 cm., 79-17.png"},
                            {"type":"file","name":"29 Mineral de Cata III, 1979, Òleo_tela, 40 x 30 cm., 79-31 .png"},
                            {"type":"file","name":"3 Callejòn de Tanganitos, 1974, Òleo_tela, 66 x 45.5 cm., 74-4 .png"},
                            {"type":"file","name":"30 Cúpula de la Compañia, 1980, Òleo_tela, 100 x 70 cm., 80-20.png"},
                            {"type":"file","name":"31 Ex Hacienda, Marfil de Abajo I, 1980, Òleo_tela, 70 x 50 cm., 80-25.png"},
                            {"type":"file","name":"32 Casa Miguel Hidalgo, 1981, Òleo_tela, 50 x 40 cm., 81-4.png"},
                            {"type":"file","name":"33 Patio en Dolores Hidalgo, 1981, Òleo_tela, 75 x 55 cm,, 81-7.png"},
                            {"type":"file","name":"34 Marfil, Òleo_tela, 1981, 30 x 40 cm., 81-13.png"},
                            {"type":"file","name":"35 Ruinas, Marfil, 1981, Òleo_tela, 70 x 50 cm., 81-15.png"},
                            {"type":"file","name":"36 Mellado, 1981, Òleo_tela, 50 x 60 cm,. 81-29.png"},
                            {"type":"file","name":"37 Riachuelo, Abasolo I, 1982, Òleo_tela, 90 x 40 cm., 82-5.png"},
                            {"type":"file","name":"38 Escalera Mellado, 1982, Òleo_tela, 30 x 40 cm., 82-17.png"},
                            {"type":"file","name":"39 Vista de Mellado, 1983, Òleo_tela, 50 x 65 cm., 83-16.png"},
                            {"type":"file","name":"4 Calle Padre Belauzaràn, 1974, Òleo_tela, 60 x 80 cm., 74-7.png"},
                            {"type":"file","name":"40 Pasillo, Atrio Mellado, 1983, Óleo_tela, 60 x 75 cm., 83-17.png"},
                            {"type":"file","name":"41 Arcos, Atrio Mellado, 1983, Òleo_tela, 65 x 50 cm., 83-19.png"},
                            {"type":"file","name":"42 La Cerca, Santa Rosa, 1985, Óleo_tela, 40 x 30 cm., 83-28.png"},
                            {"type":"file","name":"43 Santa Rosa, 1984, Òleo_tela, 40 x 30 cm., 84-4.png"},
                            {"type":"file","name":"44 Torreon de San Javier, ca. 1984, Òleo_tela, 28.2 x 40.3 cm,. 84-5.png"},
                            {"type":"file","name":"45 San Roque, 1984, Òleo_tela, 40 x 30 cm., 84-20.png"},
                            {"type":"file","name":"46 Corniza, San Roque, 1984, Òleo_tela, 40 x 30 cm., 84-22.png"},
                            {"type":"file","name":"47 Marfil de Arriba, ca. 1985, Òleo_tela, 40 x 30 cm.,  85-8.png"},
                            {"type":"file","name":"48 Ex Hacienda San Gabriel Barrera, ca. 1985, Òleo_tela,  35 x 25 cm., 85-14.png"},
                            {"type":"file","name":"49 Plaza Del Baratillo, ca. 1985, Òleo_tela, 50 x 40 cm., 85-16.png"},
                            {"type":"file","name":"5 Hacienda de Pastita I, 1974, Òleo_tela, 50 x 70 cm., 74-8.png"},
                            {"type":"file","name":"50 Privada de la Escalera, ca. 1985, Óleo_tela, 55 x 40 cm., 85-20.png"},
                            {"type":"file","name":"51 Mineral de Cubo, ca. 1985, Òleo_tela, 40 x 28 cm., 85-22.png"},
                            {"type":"file","name":"52 Mineral de Cubo, ca. 1985, Òleo_tela, 36 x 28 cm. 85-24.png"},
                            {"type":"file","name":"53 Cerro del Gigante, ca. 1986, Óleo_tela, 40x55 cm., 86-1.png"},
                            {"type":"file","name":"54 Calle Padre Hidalgo, ca. 1986, Òleo_tela, 60 x 51 cm., 86-5.png"},
                            {"type":"file","name":"55 Casa Luna, ca. 1986, Òleo_tela, 40 x 30 cm., 86-7.png"},
                            {"type":"file","name":"56 Cerca, Santa Rosa, ca. 1986, Òleo_tela, 26 x 35 cm., 86-14.png"},
                            {"type":"file","name":"57 Santa Rosa, ca. 1986, Òleo_tela, 50 x 35 cm., 86-15.png"},
                            {"type":"file","name":"58 El Guardian, Santa Rosa, ca. 1986, Òleo_tela, 35.8 x 28.3 cm., 86-16.png"},
                            {"type":"file","name":"59 Privada de la Escalera II Òleo_Tela, 55 x 40 cm., 86-21.png"},
                            {"type":"file","name":"6 Hacienda de Pastita II, 1974, Òleo_tela, 70 x 50 cm., 74-10.png"},
                            {"type":"file","name":"60 Mellado, ca. 1987, Acuarela, 40 x 65 cm., 87-2.png"},
                            {"type":"file","name":"61 Zapatero Remendon, Sin firmar,  ca. 1987, Mixta_papel, 66 x 50.5 cm., 87-6.png"},
                            {"type":"file","name":"62 La Charla, Marfil, ca. 1987, Mixta_papel, 65 x 50 cm., 87-13.png"},
                            {"type":"file","name":"63 Abasolo I, ca. 1988. Ôleo_Tela, 60 x 50 cm., 88-1.png"},
                            {"type":"file","name":"64 Abasolo II, ca. 1988, Òleo_Tela, 55 x 70 cm., 88-2.png"},
                            {"type":"file","name":"65 Riachuelo, Abasolo II, Òleo_tela, 70 x 50 cm., 88-3.png"},
                            {"type":"file","name":"66 Abasolo III, ca 1988, Oleo_Tela, 60 x 50 cm., 88-44.png"},
                            {"type":"file","name":"67 Vigilante, Santa Rosa, Sin firmar, ca. 1987, Òleo_tela, 65 x 50 cm., 88-7.png"},
                            {"type":"file","name":"68 Hacienda de Pastita, ca. 1988, Òleo_tela, 60 x 70 cm., 88-8.png"},
                            {"type":"file","name":"69 San Gabriel II, ca 1988, Ôleo_tela, 60 x 50 cm., 88-32.png"},
                            {"type":"file","name":"7 Callejòn El Laurel I, 1975, Òleo_tela, 65 x 45 cm., 75-1.png"},
                            {"type":"file","name":"70 Arbolado, Santa Rosa, ca. 1989, Òleo_tela, 60 x 50 cm., 89-4.png"},
                            {"type":"file","name":"71 Cerca, Santa Rosa, ca. 1989, Òleo_tela, 70 x 50 cm., 89-6.png"},
                            {"type":"file","name":"72 Patio, Dolores Hidalgo, ca. 1989, Óleo_tela, 70 x 55 cm., 89-8.png"},
                            {"type":"file","name":"73 Santa Rosa, ca.1989, Ôleo_tela, 65 x 50 cm., 89-13.png"},
                            {"type":"file","name":"74 Santa Rosa, ca.1989, Òleo_tela, 35 x 25 cm., 89-14.png"},
                            {"type":"file","name":"75 Atrio Templo de Mellado, ca. 1991, Ôleo_tela, 70 x 90 cm., 90-1.png"},
                            {"type":"file","name":"76 Cerca, Santa Rosa, ca. 1990, Òleo_tela, 77 x 60 cm., 90-5.png"},
                            {"type":"file","name":"77 Ex Hacienda, Marfil de Abajo, ca. 1991, Òleo_tela, 70 x 60 cm., 91-13.png"},
                            {"type":"file","name":"78 Hacienda San Gabriel, ca.1992, 60 x 100 cm., 92-7.png"},
                            {"type":"file","name":"79 Puente de Tepetapa, ca. 1967-1993, Òleo_masonite, 60 x 53 cm., 93-10.png"},
                            {"type":"file","name":"8 Calle Subterrànea Miguel Hidalgo II, 1975, Òleo_tela, 60 x 50 cm., 75-2.png"},
                            {"type":"file","name":"9 Atrio, Marfil de Abajo, 1975, Òleo_tela, 60 x 50 cm., 75-3.png"}
                        ]},
                    {"type":"directory","name":"2 MEXICO Y SUS ALREDEDORES","contents":[
                            {"type":"file","name":"1 Chimalistac, 1945, Òleo_tela, 56 x 44 cm., 45-1.png"},
                            {"type":"file","name":"10 Jardin, Cuernavaca, ca.1969, Acuarela_cartoncillo, 45 x 28.5 cm., 69-1.png"},
                            {"type":"file","name":"11 Nevado de Toluca, 1979, Òleo_tela, 35 x 50cm., 79-14.png"},
                            {"type":"file","name":"12 Aculco, 1979, Òleo_tela, 80 x 40cm., 79-15.png"},
                            {"type":"file","name":"13 Club de Golf Bellavista, 1979, Òleo_tela, 40 x 60 cm., 79-16.png"},
                            {"type":"file","name":"14 Iglesia del Carmen, Toluca, Òleo_Tela, 70 x 40 cm., 79-26.png"},
                            {"type":"file","name":"15 Arbolado, Ocoyoacac, 1980, Òleo_tela, 60.5 x 40 cm., 80-12.png"},
                            {"type":"file","name":"16 Ocoyoacac, 1980, Òleo_tela, 35 x 45.5 cm., 80-13.png"},
                            {"type":"file","name":"17 Plaza de los Amates, Cocoyoc, 1980, Òleo_tela, 70 x 90.5 cm., 80-16.png"},
                            {"type":"file","name":"18 Hacienda de Cocoyoc, 1980, Òleo_tela, 80.5 x 61 cm., 80-17.png"},
                            {"type":"file","name":"19 Oaxtepec, I980, Òleo_tela, 40 x 30 cm., 80-22.png"},
                            {"type":"file","name":"2 San Sebastiàn, 1945, Òleo_tela, 51.3 x 77 cm., 45-2.png"},
                            {"type":"file","name":"20 El Arriero, Michoacán, ca. 1981, Òleo_tela, 60 x 45 cm., 81-1.png"},
                            {"type":"file","name":"21  Esperando al Amo, 1981, Òleo_tela, 45 x 60 cm., 81-2.png"},
                            {"type":"file","name":"22 Vista Club de Golf, 1981, Òleo_tela, 40 x 30 cm., 81-3.png"},
                            {"type":"file","name":"23 Cocoyoc, 1981, Òleo_tela, 50 x 40 cm., 81-5.png"},
                            {"type":"file","name":"24 Atrio, Cholula, Pue,, 1981, ôleo_tela, 70 x 50 cm., 81-6.png"},
                            {"type":"file","name":"25 La Carreta, Queretaro, 1981, Òleo_tela, 40 x 60 cm., 81-8.png"},
                            {"type":"file","name":"26 Tepozotlàn, 1981, Òleo_tela, 40 x 30 cm., 81-10.png"},
                            {"type":"file","name":"27 Taxco, 1981, Òleo_tela, 70 x 50 cm., 81-11.png"},
                            {"type":"file","name":"28 Queretaro, 1981, Òleo_tela, 30 x 40 cm., 81-14.png"},
                            {"type":"file","name":"29 Tziquimitio II, Michoacàn, 1982, Òleo_tela, 30 x 40 cm., 82-6.png"},
                            {"type":"file","name":"3 Acueducto de Ticoman, 1946, Òleo_tela. 37.5 x 30.5 cm, 46-1.png"},
                            {"type":"file","name":"30 Chapultepec, 1982, Òleo_tela, 30 x 40 cm., 82-9.png"},
                            {"type":"file","name":"31 Cocoyoc II, 1982, Òleo_tela, 30 x 40 cm., 82-10.png"},
                            {"type":"file","name":"32 La Puerta de la Felicidad, Cocoyoc, 1982, Òleo_tela, 40 x 30 cm., 82-12.png"},
                            {"type":"file","name":"33 Chupicuaro, Michoacàn, 1982, Òleo_tela, 30 x 40 cm., 82-13.png"},
                            {"type":"file","name":"34 Tziquimitio I, Michoacan, 1982, Òleo_tela, 30 x 40 cm., 82-15.png"},
                            {"type":"file","name":"35 Corte de la Colina, Michoacàn, 1982, Òleo_tela, 30 x 40 cm., 82-16.png"},
                            {"type":"file","name":"36 Camino a Patzcuaro, ca. 1983, Òleo_tela, 35 x 25 cm., 82-19.png"},
                            {"type":"file","name":"37 Uruapan I, Michoacan, 1983, Òleo_tela, 30 x 40 cm., 82-20.png"},
                            {"type":"file","name":"38 Camino a la Tzaràracua, ca. 1983, Òleo_tela, 40 x 30 cm., 83-2.png"},
                            {"type":"file","name":"39 Bosque, Michoacan, ca. 1983, Òleo_tela, 40 x 30 cm., 83-3.png"},
                            {"type":"file","name":"4 Puente de Chimalistac,1948, Acuarela_cartoncillo,  22.5 x 30 cm., 48-3.png"},
                            {"type":"file","name":"40 Faenas del campo, 1983, Òleo_tela, 30 x 40 cm., 83-4.png"},
                            {"type":"file","name":"41 Rumbo a Uruapan, ca. 1983, Òleo_tela, 40 x 30 cm., 83-6.png"},
                            {"type":"file","name":"42 Riachuelito, Tziquimitio, 1983, Òleo_tela, 40 x 30 cm., 83-7.png"},
                            {"type":"file","name":"43 Sabino, Tequisquiapan, 1982, Òleo_tela, 40 x 30 cm., 83-11.png"},
                            {"type":"file","name":"44 Viñedos, Ex Hacienda San Jose de Buenavista, ca. 1983, Òleo__tela, 80 x 110 cm., 83-12.png"},
                            {"type":"file","name":"45 Puente de la Maroma, Tequisquiapan, 1983, Òleo_tela, 60 x 75 cm., 83-14.png"},
                            {"type":"file","name":"46 Uruapan. II, Michoacan, 1983, Òleo_tela, 60 x 40 cm., 83-18.png"},
                            {"type":"file","name":"47 Paso del Gato, Tequisquiapan, ca. 1983, Òleo_tela, 70 x 55 cm., 83-20.png"},
                            {"type":"file","name":"48 Tequisquiapan, ca. 1984, Oleo_tela, 30 x 40 cm., 84-2.png"},
                            {"type":"file","name":"49 Hacienda San Antonio Matute, 1984, Òleo_tela, 80 x 60 cm., 84-7.png"},
                            {"type":"file","name":"5 Iglesia de Chimalistac, 1948, Acuarela_cartoncillo, 30 x 20 cm., 48-4.png"},
                            {"type":"file","name":"50 Pastorcillo, Rumbo a Uruapan, 1984, Òleo_tela, 40 x 30 cm., 84-9.png"},
                            {"type":"file","name":"51 Pastorcillo, Tziquimitio, ca. 1984, Òleo_tela, 30 x 40 cm., 84-11.png"},
                            {"type":"file","name":"52 Abrevadero, Tequisquiapan, 1984, Óleo_tela, 35 x 45 cm., 84-15.png"},
                            {"type":"file","name":"53 Shaigui,Tequisquiapan, 1984, Òleo_tela, 40 x 30 cm., 84-17.png"},
                            {"type":"file","name":"54 Vaso de Cristo, Naucalpan, 1985, Ôleo-tela, 30 x 50 cm., 85-1.png"},
                            {"type":"file","name":"55 Quinta Lilibeth I, Tequisquiapan, ca. 1985, Òleo_tela, 35 x 45 cm., 85-4.png"},
                            {"type":"file","name":"56 Barrio de San Juan I, Tequisquiapan, ca. 1985, Òleo_tela, 35 x 25 cm., 85-6.png"},
                            {"type":"file","name":"57 Barrio de San Juan II, Tequisquiapan, ca. 1985, Òleo_tela, 35 x 25 cm., 85-10.png"},
                            {"type":"file","name":"58 Callejon, Tequisquiapan,  ca. 1985, Òleo_tela 65 x 50 cm., 85-12.png"},
                            {"type":"file","name":"59 Lago, Edo. de Mexico, ca. 1985, Òleo_tela, 35 x 50 cm., 85-25.png"},
                            {"type":"file","name":"6 Rancho, Almoloya de Juarez, 1948, Acuarela_cartoncillo,  40 x 51 cm ., 48-5.png"},
                            {"type":"file","name":"60 Quinta Lilibeth II, Tequisquiapan, ca. 1986, Òleo_tela, 55 x 65 cm., 86-3.png"},
                            {"type":"file","name":"61 Valle de Bravo, Estado de Mèxico, ca. 1986, Òleo_tela, 30 x 40 cm., 86-18.png"},
                            {"type":"file","name":"62 Shaigui, Tequisquiapan, ca. 1989, Òleo_tela 55 x 65 cm., 89-1.png"},
                            {"type":"file","name":"63 Hacienda de Cocoyoc II, Morelos, ca. 1989, Òleo_tela, 65 x 50 cm., 89-2.png"},
                            {"type":"file","name":"64 San Sebastian II, Ciudad. de Mexico, ca. 1990, Òleo_tela 60 x 75 cm., 90-11.png"},
                            {"type":"file","name":"65 Xochimilco, Sin firma, ca. 1993, Acrilico y arenas_tela, 60.5 x 90 cm., 92-6 .png"},
                            {"type":"file","name":"66 Bosque, ca. 1994, Òleo_tela, 100 x 150 cm., 94-14.png"},
                            {"type":"file","name":"67 Otoño, Morelos, Sin firma, ca. 1995, Oleo_tela, 100 x 150 cm., 95-18.png"},
                            {"type":"file","name":"7 Piramide de Tula, Sin Firma, ca. 1950, Acuarela_papel cascaròn, 24.5 x 32 cm., 50-3.png"},
                            {"type":"file","name":"8 Piramide de Tula, 1950, Òleo_tela, 55 x 70 cm., 50-4.png"},
                            {"type":"file","name":"9 La Charca, Tula, 1950, Òleo_tela, 61 x 51 cm., 50-5.png"}
                        ]},
                    {"type":"directory","name":"3 MARINO","contents":[
                            {"type":"file","name":"1 Marina, Sin firma, ca. 1956, Acuarela_cartòn, 20.2 x 30.6 cm., 56-1.png"},
                            {"type":"file","name":"10 Marina con Barca, ca. 1974, Òleo_tela, 60 x 150 cm., 74-6.png"},
                            {"type":"file","name":"11 Atardecer, 1974, Òleo_tela, 63 x102 cm., 74-11.png"},
                            {"type":"file","name":"12 Zopilote mirando el Amanecer, 1975, OT, 65 x 130 cm.,75-14.png"},
                            {"type":"file","name":"2 Veracruz, ca. 1957, Acuarela 15x30 cm., 57-2.png"},
                            {"type":"file","name":"3 Marina, ca. 1959, Òleo_tela, 65 x 85 cm aprox., 60-1.png"},
                            {"type":"file","name":"4 Marina al Atardecer, ca. 1959, Óleo_tela, 60 x 85 cm., 62-1.png"},
                            {"type":"file","name":"5 Marina nocturna 1965, Òleo_masonite, 22 x 62 cm., 65-2.png"},
                            {"type":"file","name":"6 Marina, 1966, Òleo_tela, 55 x 130 cm., 66-2.png"},
                            {"type":"file","name":"7 Amanecer, 1967, Òleo_tela, 48x130 cm., 67-2.png"},
                            {"type":"file","name":"8 Atardecer en Acapulco, 1968, Òleo_tela 75 x 150 cm., 68-9.png"},
                            {"type":"file","name":"9 Marina, 1974, Òleo_tela, 66 x 180 cm., 74-2.png"}
                        ]},
                    {"type":"directory","name":"4 DIBUJO","contents":[
                            {"type":"file","name":"1 Sin Titulo, 1914, Lápiz_cartoncillo, 13.6 x 23.8 cm., 14-1.png"},
                            {"type":"file","name":"10 Esquina, Academia, Sin firma, Lápiz_cartulina, 29.9 x 20.4 cm., 28-7R.png"},
                            {"type":"file","name":"11 Apunte, Sin firma, ca.1939, Sepia_cartulina, 21.5 x 31.5 cm., 39-7R.png"},
                            {"type":"file","name":"12 Boceto en San Angel, Sin firma, ca. 1955, Làpiz de color_cartoncillo 21.5 x 27.5 cm., 55-2.png"},
                            {"type":"file","name":"13 Callejon de Tanganitos, ca. 1974, Plumòn_papel ingres, 43 x 40 cm., 74-3.png"},
                            {"type":"file","name":"14 Boceto lancha, Sin firma, ca.1974, Làpiz_Cartoncillo, 11.4 x 20 cm., 74-5.png"},
                            {"type":"file","name":"15 Hacienda de Pastita, ca. 1974, plumòn_ papel Ingres, 43 x 30 cm., 74-9.png"},
                            {"type":"file","name":"16 Apunte Mineral de Cata, Sin firma, ca 1975, Plumòn_papel Ingres, 30 x 40.1 cm., 75-3.png"},
                            {"type":"file","name":"17 Apunte Mineral de Cata Sin firma, ca. 1975, Plumòn_papel ingres, 40 x 30.2 cm., 75-4.png"},
                            {"type":"file","name":"18 Mineral de Cata, ca. 1975, Plumòn_catulina. 40 x 32.2 cm., 75-5.png"},
                            {"type":"file","name":"19 Mineral de Cata, ca. 1973, Pastel_cartoncillo, 40 x 30 cm., 75-6.png"},
                            {"type":"file","name":"2 Iglesia de la Santisima, 1923, Làpiz_cartoncillo, 38 x 25 cm., 23-7.png"},
                            {"type":"file","name":"20 Apunte Zopilote, Sin firma, ca, 1975, Làpiz_cartòn, 15.1 x 22.2 cm., 75-4R.png"},
                            {"type":"file","name":"21 Boceto para marina, Sin firma, ca. 1975, Plumòn_cartòn, 15.1 x 22.2 cm., 75-11.png"},
                            {"type":"file","name":"22 Boceto con Zopilotes, Sin firma, ca. 1975, Tinta_cartoncillo, 24.1 x 31.5 cm., 75-13 y 75-13R.png"},
                            {"type":"file","name":"23 Calle Miguel Hidalgo, ca. 1976, Plumòn_papel ingres, 30 x 43 cm., 76-1.png"},
                            {"type":"file","name":"24 Apunte El Pipila, Sin firma, ca. 1976, Pastel_papel Ingres, 50 x 31 cm., 76-4.png"},
                            {"type":"file","name":"25 El Pipila, Sin firma, ca. 1976, Plumòn_papel manila, 41.5 x 35 cm., 76-5.png"},
                            {"type":"file","name":"26 El Pipila, Sin firma, ca. 1976, Plumòn_papel manila, 44 x 35 cm., 76-6.png"},
                            {"type":"file","name":"27 Plazuela de San Roque, ca. 1977, Plumòn_papel ingres, 39 x 27 cm., 77-1.png"},
                            {"type":"file","name":"28 Boceto Callejòn, Sin firma, ca. 1978, Sepia_Cartoncillo, 46.2 x 35 cm., 78-1.png"},
                            {"type":"file","name":"29 Apunte Los Picachos, sin firma, ca.1978, Lapiz_papel bond, 34.5 x 24 cm., 78-6.png"},
                            {"type":"file","name":"3 Puerta aula, Academia, Sin firma, ca. 1924, Lápiz_cartoncillo, 29.8 x 22.5 cm., 24-3.png"},
                            {"type":"file","name":"30 Apunte, Los Picachos, Sin firma, ca. 1978, Sepia_papel Bond, 34.5 x 24.5 cm 78-6R.png"},
                            {"type":"file","name":"31 Apunte Carreta, ca. 1978, Plumòn_cartoncillo, 35 x 42 cm. 78-8.png"},
                            {"type":"file","name":"32 Boceto Carreta, Sin firma, ca. 1978, Plumòn_papel manila, 30 x 50 cm., 78-9.png"},
                            {"type":"file","name":"33 Calle Padre Belauzaràn, ca. 1978, Plumil_papel ingres, 30 x 43 cm., 78-12.png"},
                            {"type":"file","name":"34 Boceto Faroles, Sin firma, ca. 1979, plumil sanguina_cartulina, 15.4 x 21.8 cm., 79-1.png"},
                            {"type":"file","name":"35 Boceto Faroles, Sin firma, ca. 1979, plumòn_papel de china, 43 x 28 cm., 79-8.png"},
                            {"type":"file","name":"36 Apunte Templo, Marfil de Abajo, 1979, Plumòn_cartoncillo, 31 x 23.5 cm., 79-5.png"},
                            {"type":"file","name":"37 Apunte casa de la infancia del autor, Guanajuato, Sin firma, ca. 1979, Plumòn_papel Ingres, 50 x 35.3 cm., 79-12.png"},
                            {"type":"file","name":"38 Paella en Chapultepec, ca. 1979, Plumòn_papel Ingres, 30 x 45 cm., 79-29.png"},
                            {"type":"file","name":"39 Apunte Cùpula de la Compañia, Sin firma, ca. 1980, Làpiz_cartulina,  32 x 24.3 cm., 80-5R.png"},
                            {"type":"file","name":"4 Columnas, Academia, sin firma, ca. 1924, Lápiz_Cartoncillo, 22.5 x 29.8 cm., 24-3R.png"},
                            {"type":"file","name":"40 Apunte Iglesia, Sin firma, ca, 1980, plumòn_papel ingres, 35 x 50 cm., 80-11.png"},
                            {"type":"file","name":"41 Apunte camino a Ocoyoacac, Sin firma, ca. 1980, Plumòn_cartoncillo, 80-10.png"},
                            {"type":"file","name":"42 Apunte paella en Chapultepec, Sin firma, ca. 1980, Tinta y plumòn_cartoncillo, 35 x 50 cm., 80-21.png"},
                            {"type":"file","name":"43 Callejòn casa de la infancia del autor, 1981, Plumòn_papel Ingres, 48.6  x  34 cm., 81-12.png"},
                            {"type":"file","name":"44 Monumento a la Paz, Sin firma, ca, 1981, Plumòn_c. 81-31.png"},
                            {"type":"file","name":"45 Apunte, Abasolo I, Sin firma, ca. 1982, Plumòn_papel ingres, 50 x 41.3 cm., 82-2.png"},
                            {"type":"file","name":"46 Apunte, Abasolo II, Sin firma, ca. 1982, plumòn_papel ingris, 50 x 35.5 cm., 82-4 y 8.png"},
                            {"type":"file","name":"47 Abasolo III, Sin firma, ca.1982, Acuarela_cartulina, 30 x 45.5 cm., 82-3.png"},
                            {"type":"file","name":"48 Arbol de Tule, Sin firma, ca. 1982, Plumòn_cartulina, 24.4 x 31.8 cm., 82-7.png"},
                            {"type":"file","name":"49 Ahuehuete, Sin firma, ca, 1982, Plumòn_cartulina, 27 x 17.3 cm., 82-8.png"},
                            {"type":"file","name":"5 Cùpula Iglesia de Santa Teresa, 1924, Lápiz_Cartoncillo, 29.8 x 22.5 cm., 24-8.png"},
                            {"type":"file","name":"50 Apunte Hacienda de Pastita, Sin firma, ca.1982, Plumòn_papel Ingres, 43 x 30 cm., 82-16.png"},
                            {"type":"file","name":"51 Sabinos, 1983, Plumòn_papel ingres, 30.5 x 45 cm., 83-9.png"},
                            {"type":"file","name":"52 Apunte callejòn, Sin firma, ca. 1983, plumòn_papel Ingres, 50 x 25.6 cm., 83-15.png"},
                            {"type":"file","name":"53 Santa Rosa, ca. 1983, Plumón_papel Ingres, 35.4 x 25.4, 83-27.png"},
                            {"type":"file","name":"54 Apunte dia de Campo, Sin firma, ca. 1983, Pastel_papel manila, 34.6 x 50 cm., 83-29.png"},
                            {"type":"file","name":"55 Boceto Rancho, Almoloya, Sin firma, ca. 1983, Pastel_papel manila, 46 x 59 cm., 83-29R.png"},
                            {"type":"file","name":"56 Retoño, ca. 1984, Plumòn_papel, 84-1.png"},
                            {"type":"file","name":"57 Tequisquiapan, ca. 1984, plumón_papel Ingres, 84-13.png"},
                            {"type":"file","name":"58 Apunte Tequisquiapan, Sin firma, ca. 1985, Plumòn_papel, 35.4 x 50 cm., 85-3.png"},
                            {"type":"file","name":"59 Calle con arco, ca. 1985, Plumòn_papel Ingres, 40 x 50.5 cm., 85-5.png"},
                            {"type":"file","name":"6 Apunte, Veracruz, Sin firma, ca 1925, Lápiz_cartulina, 21.2 x 13.2 cm., 25-2.png"},
                            {"type":"file","name":"60 Barda en Tequisquiapan, ca. 1985, plumòn_papel Ingres, 85-7.png"},
                            {"type":"file","name":"61 Tequisquiapan, Sin firma, ca. 1985, Pastel y plumòn_ papel Ingres, 49.8 x 35.5 cm. 85-11.png"},
                            {"type":"file","name":"62 Apunte Fuente Plaza Baratillo, ca. 1985, Plumòn_papel Ingres, 45 x 50 cm., 85-15.png"},
                            {"type":"file","name":"63 Cerca, Santa Rosa, ca. 1986, Plumòn_papel Ingres, 35.5 x 35 cm., 86-16.png"},
                            {"type":"file","name":"64 Privada de la Escalera, ca. 1985, Plumòn_papel Ingres, 86-20.png"},
                            {"type":"file","name":"65 Apunte Fuente  Plazuela de la Cruz, Queretaro, Sin firma, ca, 1986, Plumòn_papel ingres, 30 x 45 cm., 86-25.png"},
                            {"type":"file","name":"66 Apunte Iglesia, Sin firma, ca. 1986, Plumòn_cartoncillo, 50 x 35.2 cm., 86-26.png"},
                            {"type":"file","name":"67 Apunte en Guanajuato, Sin firma, ca. 1987, 50 x 35.5 cm., 87-15R.png"},
                            {"type":"file","name":"68 Apunte Los Picachos, Sin firma, ca. 1989, Làpiz_papel bond, 14 x 21.5 cm., 89-7.png"},
                            {"type":"file","name":"69 Apunte Callejòn, Sin firma, ca. 1990, plumòn_papel ingres, 46 x 29.2 cm., 90-25R.png"},
                            {"type":"file","name":"7 Apunte cùpulas de Iglesia, Sin firma, ca. 1925, Lápiz_cartulina, 13.2 x 21.2 cm., 25-15.png"},
                            {"type":"file","name":"70 Apunte en Mexiamora, ca. 1981, Plumòn_revez cartòn de caja de zapatos, 20 x 27.2 cm. 81-17 y 81-17R.png"},
                            {"type":"file","name":"8 Muro, Academia, Sin firma, ca. 1928, Lápiz_cartulina, 20.4 x 29.8 cm., 28-2.png"},
                            {"type":"file","name":"9 Escalera, Academia, 1928, Lápiz_cartulina, 29.9 x 20.4 cm., 28-7.png"}
                        ]},
                    {"type":"directory","name":"5 GRAFICA","contents":[
                            {"type":"file","name":"1 Tanganitos, Mixografia_papel hecho a mano, 65 x 50 cm., 87-7.png"},
                            {"type":"file","name":"2 Serenata, Mixografia_papel hecho a mano, 65 x 50 cm.,87-11.png"},
                            {"type":"file","name":"3 Mineral de Cata, Mixografìa_papel hecho a mano, 65 x 50 cm,87-14.png"},
                            {"type":"file","name":"4 Violinista, Mixografía_papel hecho a mano, 65 x 50 cm,,87-17.png"},
                            {"type":"file","name":"5 Mellado, Mixografìa_papel hecho a mano, 65 x 50 cm.,87-18.png"}
                        ]}
                ]},
            {"type":"directory","name":"2 REALISMO MAGICO","contents":[
                    {"type":"directory","name":"1 ECLIPSE 1991","contents":[
                            {"type":"file","name":"1 Mujer mirando al cielo, Sin firma, ca. 1990, Pastel_papel manila, 41 x 49 cm., 90-27 y 90-27R.png"},
                            {"type":"file","name":"10 Partìcula 4, ca. 1990, Mixta_Tela, 80 x 59 cm., 90-38.png"},
                            {"type":"file","name":"11 Partìcula 5, ca. 1990, Mixta _Tela, 80 x 100 cm., 90-39.png"},
                            {"type":"file","name":"12 Partícula 6, ca. 1990, Mixta_T, 80x100, 90-40.png"},
                            {"type":"file","name":"13 Partìcula 7, ca. 1991, Mixta_tela, 100 x 80 cm., 91-1.png"},
                            {"type":"file","name":"14 Partìcula 8, Sin firma, ca. 1991, Mixta_Tela, 95 x 75 cm., 91-2.png"},
                            {"type":"file","name":"15 Partìcula 9, Sin firma, ca.1991, Mixta_tela, 100 x 80cm., 91-3.png"},
                            {"type":"file","name":"16 Partìcula 10, ca. 1991, Pastel_papel ingres, 48.8 x41.5 cm., 91-4.png"},
                            {"type":"file","name":"17 Partìcula 11, ca. 1991, Pastel_papel ingres, 49.8 x 41.2 cm., 91-5.png"},
                            {"type":"file","name":"18 Partìcula 12, ca. 1991, Mixta_tela, 114 x 88 cm., 91-6.png"},
                            {"type":"file","name":"19 Tintes del cielo 1, Sin firma, ca. 1990, Acrilico_papel grueso hecho a mano, 50 x 65 cm., 91-7.png"},
                            {"type":"file","name":"2 Partìcula 1, Sin firma, ca. 1990, Acrilico_papel ingres,  60 x 80 cm., 90-29.png"},
                            {"type":"file","name":"20 Tintes del cielo 2 Sin firma, ca. 1990, Acrilico_ papel grueso hecho a mano, 50 x 65 cm., 91-8.png"},
                            {"type":"file","name":"21 Tintes en el cielo 3, Sin firma, ca. 1991, Acrilico_ papel hecho a mano, 65 x 50 cm., 91-9.png"},
                            {"type":"file","name":"22 Eclipse, ca.1991, Òleo y arenas_Tela, 96 x 126 cm., 91-10.png"},
                            {"type":"file","name":"23 Boceto Partìcula 13, ca. 1991, plumòn_papel ingres, 50.5 x 32.5 cm., 91-11.png"},
                            {"type":"file","name":"3 Partìcula 2, Sin firma, ca. 1990, Pastel y plumòn_papel ingres, 21.4 x 49.9 cm., 90-30.png"},
                            {"type":"file","name":"4 Poder Cosmico, Sin firma, ca. 1990, Òleo y arena_Tela,100 x 100 cm., 90-31.png"},
                            {"type":"file","name":"5 El Flechador. ca. 1990. Pastel_papel ingres. 50 x 35 cm., 90-33.png"},
                            {"type":"file","name":"6 Bosquejo Encuentro, Sin firma, ca. 1990, Pastel_papel ingres, 41.5 x 50 cm., 90-34.png"},
                            {"type":"file","name":"7 El Flechador, ca. 1990, Mixta con arenas_tela, 100 x 80 cm., 90-35.png"},
                            {"type":"file","name":"8 Encuentro còsmico, Sin firma, ca 1990, Mixta_tela, 100 x 100 cm., 90-36.png"},
                            {"type":"file","name":"9 Partìculas 3, Sin firma, ca, 1990, Làpiz_papel bond, 30.5 x 22.7, 90-37 cm., 90-37.png"}
                        ]},
                    {"type":"directory","name":"2 FANTASIA","contents":[
                            {"type":"directory","name":"1 PINTURA","contents":[
                                    {"type":"file","name":"1 Meteorito, 1925,  Temple_papel manila_madera, 4 x 3.25 mt., 25-13.png"},
                                    {"type":"file","name":"2 Viejo Vergel, 1968, Òleo_tela, 100 x 80 cm., 72-6.png"},
                                    {"type":"file","name":"3 El Beso, 1981, Òleo_tela, 80 x 105 cm., 81-27.png"},
                                    {"type":"file","name":"4 A Galope, Sin firma, ca. 1988, Acrilico_papel hecho a mano, 50 x 65 cm., 88-27.png"},
                                    {"type":"file","name":"5 El Sueño del Caracol I, ca. 1988, Oleo_Yute, 70 x 60 cm., 88-31.png"},
                                    {"type":"file","name":"6 El Sueño del Caracol II, ca. 1988, Acrilico_papel grueso hecho a mano, 63.3 x 51 cm., 88-33.png"},
                                    {"type":"file","name":"7 El retorno, Sin firma, ca. 1988, Acrilico_papel ingres, 50 x 65 cm., 88-36.png"},
                                    {"type":"file","name":"8 Reflexiòn, ca. 1988, Òleo_tela, 80 x 100 cm., 88-41.png"}
                                ]},
                            {"type":"directory","name":"2 DIBUJO","contents":[
                                    {"type":"file","name":"1 Boceto Viejo vergel 1, Sin firma, ca. 1972, Tinta_papel bond, 72-1.png"},
                                    {"type":"file","name":"10 Beso versiòn 4, Sin firma, ca. 1979, Pastel_papel ingres, 49.8 x 35.1 cm., 79-21.png"},
                                    {"type":"file","name":"11 Buscando un beso versiòn 5, Sin firma, ca. 1980, Acuarela y plumil_papel manila, 50x40 cm.,80-3R.png"},
                                    {"type":"file","name":"12 Beso reprimido, versiòn 6, Sin firma. ca. 1981,  Plumil_cartoncillo, 32.2 x 23.5 cm., 81-16.png"},
                                    {"type":"file","name":"13 Beso versiòn 7, Sin firma, ca. 1981,  plumòn_cartoncillo, 23.5 x 32.2 cm., 81-16R y 81-16.png"},
                                    {"type":"file","name":"14 Beso versiòn 8, Sin firma, ca. 1981, Plumòn_cartòn de caja de zapatos, 27.5 x 20 cm., 81-17 y 81-17R.png"},
                                    {"type":"file","name":"15 Beso versiòn 9, Sin firma, ca. 1981, Plumòn_cartoncillo, 24.3 x 31.8 cm., 81-18.png"},
                                    {"type":"file","name":"16 Beso versiòn 10, Sin firma, ca.1981,  Plumòn_papel bond, 21.2 x 27.8 cm., 81-19.png"},
                                    {"type":"file","name":"17 Beso versiòn 11, Sin firma, ca. 1981. Plumòn_papel manila, 33.5 x 49 cm., 81-20.png"},
                                    {"type":"file","name":"18 Beso versiòn 12, Sin firma, ca, 1981, Plumòn_papel ingres, 35.5 x 50 cm., 81-21 y 82-4R.png"},
                                    {"type":"file","name":"19 Beso versiòn 13, Sin firma, ca. 1981, Plumòn_cartoncillo, 24 x 31.5 cm., 81-22.png"},
                                    {"type":"file","name":"2 Boceto Viejo vergel, 2, Sin firma, ca. 1972, Plumòn_papel manila, 36.7 x20.6 cm., 72-2.png"},
                                    {"type":"file","name":"20 Beso versiòn 14, Sin firma, ca. 1981, plumòn_cartoncillo, 24.1 x 31.5 cm., 81-23 y 79-23.png"},
                                    {"type":"file","name":"21 Beso versiòn 15,  Sin firma, ca. 1981, Plumòn_cartoncillo, 33.8 x 22.7 cm., 81-24 y 63-3R.png"},
                                    {"type":"file","name":"22 Beso versiòn 16, Sin firma, ca. 1981, Plumòn_cartulina, 24 x 31.6 cm., 81-25 y 88-13R.png"},
                                    {"type":"file","name":"23 Beso boceto versiòn 17, Sin firma, ca. 1981, Gouache_papel ingres, 47 x 65 cm., 81-26.png"},
                                    {"type":"file","name":"24 Beso versiòn 18, Sin firma, ca, 1990, Plumòn_papel ingres, 24.3 x 30.8 cm., 81-28.png"},
                                    {"type":"file","name":"25 Seres ocultos, Sin firma, ca. 1988, Plumòn_papel manila, 26.7 x 17.2 cm., 88-9.png"},
                                    {"type":"file","name":"26 Visiòn en penumbra, Sin firma, ca. 1988, Plumòn_papel ingres, 88-10.png"},
                                    {"type":"file","name":"27 Beso variacion 18, Sin firma, ca. 1988, Plumòn_c. 31.5x24 cm., 88-11.png"},
                                    {"type":"file","name":"28 Encuentros 1, Sin firma, ca. 1988, pastel_papel manila, 49.5 x 38 cm., 88-12 y 88-27.png"},
                                    {"type":"file","name":"29 Encuentros, 1, Sin firma, ca. 1988, Gouache_cartulina, 31.5 x 24 cm., 88-13 y 81-25 .png"},
                                    {"type":"file","name":"3 Boceto Viejo vergel 3, Sin firma, ca. 1972, plumòn_papel, 28 x 21.5 cm., 72-3.png"},
                                    {"type":"file","name":"30 Encuentros 2, Sin firma, ca. 1988, Gouache_papel ingres, 88-14.png"},
                                    {"type":"file","name":"31 Encuentros 3, Sin firma, ca. 1988, Acrilico_papel hecho a mano,  65 x 50 cm., 88-15.png"},
                                    {"type":"file","name":"32 Encuentros 3, Sin firma, ca. 1988, Gouache pastel_papel manila, 50 x 41 cm., 88-16.png"},
                                    {"type":"file","name":"33 Encuentros 4, Sin firma, ca.1988, Gouache y pastel_papel manila, 50 x 38 cm., 88-17.png"},
                                    {"type":"file","name":"34 Encuentros 5, Sin firma, ca. 1988, Gouache y pastel_papel manila, 50 x 38 cm., 88-18 p.png"},
                                    {"type":"file","name":"35 Encuentros 6, Sin firma, ca. 1988, Pastel y carboncillo_papel manila, 49.7 x 38 cm., 88-19.png"},
                                    {"type":"file","name":"36 Encuentros 7, Sin firma, ca, 1988, Plumòn_papel de china, 88-20.png"},
                                    {"type":"file","name":"37 Encuentros 8, Sin firma, ca. 1988, Plumòn_papel de china,  23.8 x 31 cm., 88-21.png"},
                                    {"type":"file","name":"38 Encuentros 9, Sin firma, ca. 1988, Tinta_cartoncillo, 33 x 23.8 cm., 88-22 .png"},
                                    {"type":"file","name":"39 Encuentros 10, Sin firma, ca. 1988, Plumòn_papel ingres, 34 x 23 cm., 88-23.png"},
                                    {"type":"file","name":"4 Viejo vergel 4, Sin firma, ca. 1972. Sepia_papel ingres, 72-4.png"},
                                    {"type":"file","name":"40 Encuentros 11, Sin firma, ca. 1988, Plumòn_papel ingres, 28 x 21-4 cm., 88-24.png"},
                                    {"type":"file","name":"41 Encuentros 12, Sin firma, ca. 1988, Plumòn_papel manila, 49.5 x 38 cm., 88-27.png"},
                                    {"type":"file","name":"42 Encuentros 13, Sin firma, ca. 1991, plumòn_papel, 28.5 x 22 cm., 87-26.png"},
                                    {"type":"file","name":"43 El sueño del caracol 2, Sin firma, ca 1988. Mixta_papel manila, 49 x 34 cm., 88-28.png"},
                                    {"type":"file","name":"44 El sueño del caracol 3,  ca. 1988 , plumòn_papel manila, 50 x 35.3 cm., 88-29.png"},
                                    {"type":"file","name":"45 El sueño del caracol 4, plumòn_papel ingres, 50 x 35.3 cm., 88-30.png"},
                                    {"type":"file","name":"46 Despedida, Sin firma, ca, 1988, Gouache_papel ingres, 31 x 24 cm., 88-35.png"},
                                    {"type":"file","name":"47 Duelo, Sin firma, ca. 1988, Pastel_papel ingres, 88-37.png"},
                                    {"type":"file","name":"48 Buscando al caracol 5, ca. 1988, Pastel_papel ingres, 88-38 y 83-13R.png"},
                                    {"type":"file","name":"49 Encuentros 14, Sin firma, ca. 1988, Pastel_papel ingres, 50  X 40, 88-39.png"},
                                    {"type":"file","name":"5 Viejo vergel 5, Sin firma, ca. 1972, Mixta plumil y Acuarela_cartoncillo, 31 x 25 cm.,72-5 .png"},
                                    {"type":"file","name":"50 El sueño del caracol 6, ca. 1988, Pastel_papel manila, 49 x 34 cm., 88-40.png"},
                                    {"type":"file","name":"51 El beso variaciòn 19, Sin firma, ca. 1990, Pastel_papel ingres, 50.5 x 35.3 cm., 90-19 y 90-19R.png"},
                                    {"type":"file","name":"52 El beso variante 20, Sin firma, ca. 1990, Plumòn_papel manila, 48.7 x 33.8 cm. 90-21.png"},
                                    {"type":"file","name":"53 El beso 21 boceto Eclipse, Sin firma, ca. 1991, Plumòn_ cartoncillo, 91-10.png"},
                                    {"type":"file","name":"54 Encuentros 15, Sin firma, ca. 1992, pastel_papel manila, 50 x 38.3 cm., 92-13R.png"},
                                    {"type":"file","name":"6 Beso y caracol versiòn 1,  Sin firma, ca. 1988, Tinta_cartoncillo, 33.7 x 23 cm., 75-12.png"},
                                    {"type":"file","name":"7 Beso versiòn 2, Sin firma, ca. 1975, Làpiz_cartoncillo, 33.2 x 24.5 cm., 75-13R y 75-13,.png"},
                                    {"type":"file","name":"8 El sueño del caracol 1, Sin firma, ca. 1978 Làpiz 23.5 x 32 cm., 78-15 y 78-15R .png"},
                                    {"type":"file","name":"9 Beso versiòn 3, Sin firma, ca, 1978, Làpiz_cartoncillo, 32 x 23.5 cm., 78-15R y 78-15.png"}
                                ]}
                        ]},
                    {"type":"directory","name":"3 EL QUIJOTE","contents":[
                            {"type":"file","name":"1 El Quijote de la Mancha, Sin firma, ca. 1930, Tinta_cartulina, 32 x 24.3 cm., 30-13.png"},
                            {"type":"file","name":"10 Dulcinea es la mujer mas hermosa, Sin firma, ca. 1978, Plumil_papel bond, 21.6 x 27.8 cm., 78-15.png"},
                            {"type":"file","name":"11 Êl mismo se hace llamar Don Quijote de la Mancha, Sin firma, ca. 1979, Làpiz_cartoncillo, 31.7 x 24.5 cm., 79-27 .png"},
                            {"type":"file","name":"12 Su primera salda fuè antes del dìa, ca. 1962,  Pastel_papel ingres, 50 x 35 cm., 79-29.png"},
                            {"type":"file","name":"13 Quiere prepararse un bàlsamo que cure todas las heridas, Sin firma, ca. 1980, Pastel_papel ingres, 34.7 x 49.2 cm., 80-1R.png"},
                            {"type":"file","name":"14 Discurso por la paz, la amistad y la concordia, Sin firma, ca. 1980, pastel_papel ingres. 34.7 x 49.2 cm., 80-1.png"},
                            {"type":"file","name":"15 Un caballero andante sin amores èra àrbol sin hojas y sin fruto, Sin firma, ca. 1980, Sanguina_papel manila, 50 x 40.5 cm., 80-2 y 80-2R.png"},
                            {"type":"file","name":"16 A la señora de sus pensamientos la llamò Dulcinea del Toboso, ca. 1980, Plumòn_papel manila, 40.5 x 50 cm. 80-2R y 80-2.png"},
                            {"type":"file","name":"17 Sancho en lugar de la insula quiere la fòrmula del bàlsamo, ca. 1980, Pastel_papel manila, 40 x 50 cm., 80-3 y 80-3R.png"},
                            {"type":"file","name":"18 Bebiendo el bàlsamo, Sin firma, ca, 1980, Plumòn_papel manila, 41 x 50 cm., 80-4.png"},
                            {"type":"file","name":"19 Boceto Quijote y Sancho, ca. 1980, Carboncillo_cartulina, 24.3 x32 cm., 80-5.png"},
                            {"type":"file","name":"2 Miguel de Cervantes Saavedra, Sin firma, ca. 1963, Tinta_cartoncillo, 31 x 24.5 cm., 63-1.png"},
                            {"type":"file","name":"20 Quijote y Sancho, ca. 1980, Acuarela, 37 x 54 cm., 80-6.png"},
                            {"type":"file","name":"21 Alonso Quijano decide convertirse en Caballero andante, Sin firma, ca. 1981, Sepia_papel ingres, 28.2 x 21.8 cm., 81-30 y 81-30R.png"},
                            {"type":"file","name":"22 La sobrina de Don Alonso,   Sin firma, ca. 1981, Sepia_papel ingres, 28.2 x 21.8, cm., 81-30R y 81-30.png"},
                            {"type":"file","name":"23 El Media noche èra por filo, cuando dejaron el monte, Sin firma, ca. 1983, Pastel_papel ingres, 50 x 34 cm., 83-21 y 83-21R.png"},
                            {"type":"file","name":"24 Espera verse Rey o Emperador por el valor de su fuerte brazo, ca. 1983, Plumòn y pastel_papel ingres, 83-24.png"},
                            {"type":"file","name":"25 El discurso de las armas y las letras, 1984, Pastel_papel ingres, 40 x 30 cm., 84-6 .png"},
                            {"type":"file","name":"26 Quiere que reconozcan a Dulcinea del Toboso como la dama mas bella del mundo, Sin firma, ca. 1984, plumòn_papel manila, 38 x 38 cm., 84-23.png"},
                            {"type":"file","name":"27 El valor se halla en èse lugar intermedio entre la cobardìa y la temeridad, Sin fima, ca. 1985, plumòn_p 85-18R.png"},
                            {"type":"file","name":"28 Libera a quienes creìa raptados, Sin firma, ca. 1986. Plumòn_papel ingres, 86-23.png"},
                            {"type":"file","name":"29 Las tristezas no se hicieron para las bestias, sino para los hombres,  ca. 1987, Plumòn_papel ingres, 32.8 x 25 cm., 87-20 y 87-20-R.png"},
                            {"type":"file","name":"3 Limpiò y usò la vieja armadura de su bisabuelo,  ca. 1963, Tinta_cartoncillo, 31 x 24.5 cm.,  63-2.png"},
                            {"type":"file","name":"30 Con las lecturas de Caballerias habìa perdido el juicio, Sin firmar, ca. 1987, Plumòn_papel ingres, 87-20-R y 87-20.png"},
                            {"type":"file","name":"31 Por la libertad asì como por la honra, se puede y se debe aventurar la vida, Sin firma, ca. 1988, Pastel_papel, 34.2 x 24.4 cm., 89-16.png"},
                            {"type":"file","name":"32 La derrota con el Caballero de la Blanca luna, ca. 1988, Sepia_papel ingres, 31.1 x 26.7 cm., 88-34.png"},
                            {"type":"file","name":"33 No son gigantes sino molinos de viento, dijo Sancho, Sin firma, ca. 1990, Sanguina_papel ingres, 29.2 x 46 cm., 90-25 y 90-25R.png"},
                            {"type":"file","name":"34 _Mira Sancho_, Sin firma, ca, 1990, Pastel_Cartoncillo,  50 x 35.5 cm., 90-28.png"},
                            {"type":"file","name":"35 Sancho se convierte en un soñador, Sin firma, ca. 1992, Tinta_papel bond, 50 x 35, 92-16.png"},
                            {"type":"file","name":"36 Consideran que los libros son el motivo de la locura del Hidalgo, ca. 1992,  Plumón_papel ingres, 35 x 50 cm., 92-15.png"},
                            {"type":"file","name":"37 Decide llamarse el Caballero de los Leones, ca. 1993, Plumòn_papel ingres, 35.3 x 49.8 cm., 92-17.png"},
                            {"type":"file","name":"38 Encerrado en una jaula, lo llevan de regreso en un carro tirado por bueyes, ca. 1993, Plumòn_papel ingres, 35.2 x 50 cm., 92-18 .png"},
                            {"type":"file","name":"39 De regreso de sus hazañas heroicas, ca. 1993, Plumòn_papel ingres, 35 x 50 cm., 92-19.png"},
                            {"type":"file","name":"4 Armado caballero buscaba un escudero, Plumòn_cartulina, 31.8 x 24.4 cm., 65-1.png"},
                            {"type":"file","name":"40 _La libertad Sancho es uno de los mas preciosos dones que a los hombres dieron los cielos_ ca. 1993, Pastel_papel ingres, 50 x 40.7 cm., 93-18.png"},
                            {"type":"file","name":"41 _El que hoy cae, puede levantarse mañana_ ca. 1994, Pastel_papel ingres, 50 x 41.5 cm., 93-19.png"},
                            {"type":"file","name":"42 _Entre los pecados mayores que los hombres cometen, aunque algunos dìcen que es la soberbia, yo digo que es el desagradecimiento_, ca. 1994, Pastel_papel Ingres, 50 x 35 cm., 93-20.png"},
                            {"type":"file","name":"43 Del sueño del Quijote de encotrarse con su amada Dulcinea, Sin firma, ca. 1995, Plumòn_papel manila, 94-6.png"},
                            {"type":"file","name":"44 A su regreso recuperò la cordura, Sin firma, ca. 1994, Plumòn_papel manila, 45 x 31.4 cm., 97-7.png"},
                            {"type":"file","name":"5 Le promete a Sancho Panza que si lo acompaña lo harà el gobernador de una Insula, ca. 1965, Plumòn_papel ingres, 65-3.png"},
                            {"type":"file","name":"6 Rucio, el Asno de Sancho, ca. 1965, Plumòn_cartoncillo, 31 x 24.5 cm, 65-3.png"},
                            {"type":"file","name":"7 Como todo un caballero necesitaba una Dama, Sin firma, ca. 1966, Óleo_Tela, 46 x 33.5 cm., 66-1.png"},
                            {"type":"file","name":"8 Puso nombre a su Rocin, llamandole Rocinante, ca. 1973, Plumòn_ papel bond, 27.8 x 17.4 cm., 73-4.png"},
                            {"type":"file","name":"9 Va en busca de aventuras y pretende solucionar las injusticias, ca. 1973, Plumòn_papel bond, 27.8 x 21.3 cm., 73-5.png"}
                        ]}
                ]},
            {"type":"directory","name":"3 COSTUMBRISTA","contents":[
                    {"type":"directory","name":"1 PINTURA","contents":[
                            {"type":"file","name":"1 Meteorito, 1925,  Temple_papel manila_madera, 4 x 3.25 mt., 25-14.png"},
                            {"type":"file","name":"10 Inspiracion, Agustin Lara, 1979, Oleo_tela, 85 x 105 cm., 79-25.png"},
                            {"type":"file","name":"11 La Ronda, Òleo_Tela 30 x 40 cm., 81-9.png"},
                            {"type":"file","name":"12 La Flor, Al a limòn con Aurora G., Sin firma, ca.1982, Òleo_tela, 120 x 60 cm., 82-22.png"},
                            {"type":"file","name":"13 Maja, Al a Limòn con Aurora G, Acrilico_tela, 60 x 120 cm. 82-23.png"},
                            {"type":"file","name":"14 Alfarero I, ca. 1992, Mixta_ papel ingres,49 x 53 cm., 87-18.png"},
                            {"type":"file","name":"15 Danza del venado, ca. 1990, Acrilico_ papel, 65 x 50 cm., 90-18.png"},
                            {"type":"file","name":"16 Encuentro de dos culturas I, ca. 1990, Acrilico_tela, 60 x 120 cm., 90-23.png"},
                            {"type":"file","name":"17 Encuentro de dos culturas II, Acrilico_tela, 60 x 120 cm., 90-26.png"},
                            {"type":"file","name":"18 Encuentro de dos culturas III, Sin firma, ca, 1992, Òleo y arenas_cartòn, 60 x 80 cm., 92-12.png"},
                            {"type":"file","name":"19 Mujer en richuelo, Sin firma, ca. 1993, Acrilico_papel hecho a mano, 65 x 50 cm., 93-6.png"},
                            {"type":"file","name":"2 El Votan de los Antiguos Mayas, 1925, Temple_papel manila_madera, 4 x 6 mt., 25-15.png"},
                            {"type":"file","name":"20 De guardianes de los valores, Sin firma, ca. 1993, Acrilico_tela, 80 x 126 cm., 93-17.png"},
                            {"type":"file","name":"21 Ofrenda, ca. 1966-1993, Òleo_masonite, 71.5 x 61.5 cm., 93-22.png"},
                            {"type":"file","name":"3 El Patròn, ca 1932, Óleo_Tela, 70 x 55 cm. aprox, 21-7.png"},
                            {"type":"file","name":"4 Ofrenda dia de muertos, 1945, Òleo_Tela, 61 x 51 cm., 45-4.png"},
                            {"type":"file","name":"5 Virgen I. (Facsimil), 1949, Òleo_tela,  60 x 50 cm., 49-2.png"},
                            {"type":"file","name":"6 Virgen II, 1950, Óleo_Tela, 60 x 51 cm., 50-6.png"},
                            {"type":"file","name":"7 Mujer con Chal, 1951,  Óleo_Tela, 62 x 51cm., 51-2.png"},
                            {"type":"file","name":"8 El Principio y el Fin, 1953, Òleo_Tela, 68 x 58 cm., 53-1.png"},
                            {"type":"file","name":"9 El Espiritu de la Raza, 1968, Óleo_Tela, 1.65 x 1.43 mt., 68-8.png"}
                        ]},
                    {"type":"directory","name":"2 DIBUJO","contents":[
                            {"type":"file","name":"1 Marìa, 1923. Sepia_cartoncillo, 32.9 x 24 cm., 23-3.png"},
                            {"type":"file","name":"10 Mujer lavando en el rìo, 1943, Carboncillo_cartoncillo, 28 x 22 cm., 43-2.png"},
                            {"type":"file","name":"100 Boceto para proyecto 3, Sin firma, Pastel_papel manila, 51.5 x 37.5 cm., 92-11 y 92-11R.png"},
                            {"type":"file","name":"101 Boceto para proyecto 4, Sin firma, ca. 1992, Pastel_papel ingres, 92-10R.png"},
                            {"type":"file","name":"102 Boceto para proyecto 5, Sin firma, ca. 1992, Pastel_papel ingres, 50 x 38.3 cm., .png"},
                            {"type":"file","name":"103 Grupo El Quijote, Sin firma, ca. 1992, Plumòn_papel bond, 21.5 x 27.9 cm., 92-21.png"},
                            {"type":"file","name":"104 Beso versiòn 27 tango, Sin firma, ca. 1992, Pastel_papel, 30 x 40.5 cm., 92-25R.png"},
                            {"type":"file","name":"105 Grupo musical en calle Mexiamora, ca. 1992, Plumòn_papel ingres, 50 x 35.3 cm., 92-22.png"},
                            {"type":"file","name":"106 Escena de teatro, ca 1992, Pastel_papel, 22.6 x 30.4 cm., 92-24 .png"},
                            {"type":"file","name":"107 Danza, Sin firma, ca. 1992, Pastel_cartoncillo, 31 x 40.5 cm., 92-25.png"},
                            {"type":"file","name":"108 Apunte Baile, Sin firma. Pastel_cartoncillo, 30.4 x 22.6 cm., 92-18R.png"},
                            {"type":"file","name":"109 Apunte danza, Sin firma, ca, 1992, pastel_papel, .png"},
                            {"type":"file","name":"11 Jesùs, 1948, Pastel_papel, 25 x 20 cm., 48-2.png"},
                            {"type":"file","name":"110 Baile I ca. 1992, Pastel_papel Ingres, 35 x 50 cm. 92-27.png"},
                            {"type":"file","name":"111 Baile II, Ca. 1992, Pastel_papel ingres. 35 x 59 cm. 92-28 .png"},
                            {"type":"file","name":"112 Rumba, Sin firma, ca. 1992, Pastel_papel ingres, 50 x 35 cm. 92-30.png"},
                            {"type":"file","name":"113 Rumba, ca. 1992, Pastel_papel ingres, 50 x 35 cm..png"},
                            {"type":"file","name":"114 Danza, Sin firma, ca. 1993, Pastel_papel ingres, 35 x 50 cm.,92-R .png"},
                            {"type":"file","name":"115 Rumba, Sin firma, ca, 1993, Pastel_papel manila. 50 x 38 cm. 92-3R.png"},
                            {"type":"file","name":"116 Alfarero, Sin firma, ca. 1993, Pastel_papel ingres, 49 x 53 cm., 93-1R.png"},
                            {"type":"file","name":"117 Alfarero, ca. 1993, Paste_papel ingres, 49 x 53 cm, 93-1y93-1R.png"},
                            {"type":"file","name":"118 Baile Flamenco, Sin firma, ca. 1993, Pastel_papel ingres, .png"},
                            {"type":"file","name":"119 Baile Flamenco, Sin firma, ca. 1993, Plumòn_papel manila, 32 x 45 cm., 93-4.png"},
                            {"type":"file","name":"12 Boceto composiciòn San Angel, Sin firma, ca. 1955, Plumòn_cartoncillo, 24.1 x 31.5 cm., 55-2.png"},
                            {"type":"file","name":"120 El maestro y el alumno,  ca. 1993, Pastel_papel ingres, 50.3 x 35.5 cm., 93-7 .png"},
                            {"type":"file","name":"121 Danza, Sin firma, ca. 1993, Plumòn_papel ingres, 30.5 x 32.5 cm., 93-13.png"},
                            {"type":"file","name":"122 Danza, Sin firma, ca. 1993, Plumòn_papel manila, 38.1 x 37.6 cm.,93-11.png"},
                            {"type":"file","name":"123 Danza, Sin firma, ca. 1993, Plumòn_papel ingres., 91-15R.png"},
                            {"type":"file","name":"124 La historia, Sin firma, ca. 1993, Làpiz_papel Ingres, 34.5 x 50 cm, 93-8.png"},
                            {"type":"file","name":"125 Danza, Sin firma, ca. 1993, Plumòn_papel manila, 33.2 x 45 cm., 93-3.png"},
                            {"type":"file","name":"126 El alumno, Sin firma, ca. 1993, Làpiz_papel manila, 49.6 x 40.6 cm., 93-9.png"},
                            {"type":"file","name":"127 El maestro, Sin firma, ca. 1993, Carboncillo_papel manila, 49.7 x 40.5 cm., 93-9R.png"},
                            {"type":"file","name":"128 Boceto, Sin firma, ca. 1993, Làpiz_papel bond,93-14.png"},
                            {"type":"file","name":"129 Boceto, Sin firrma, ca. 1993, Làpiz_papel, 23 x 30.4cm. 93-16.png"},
                            {"type":"file","name":"13 Violinista y su maestro, Sin firma, ca. 1959, Plumòn_cartoncillo, 25 x 33 cm., 59-2 y 92-23R.png"},
                            {"type":"file","name":"130 Danza, Sin firma, ca. 1993, Plumòn_cartoncillo,93-12.png"},
                            {"type":"file","name":"131 Danza, Sin firma, ca. 1993, Plumòn_papel ingres,  35 x 50, 93-21.png"},
                            {"type":"file","name":"132 Danza, Sin firma, ca. 1994, 40 x 50 cm, 94-1.png"},
                            {"type":"file","name":"133 Danza, Sin firma, ca. 1994, Plumòn_papel manila, 39.5 x 49.7, 94-2.png"},
                            {"type":"file","name":"134 Gallos de pelea, Sin firma, ca, 1994, Plumòn_papel ingres,94-3.png"},
                            {"type":"file","name":"135 Apunte toros, Sin firma, ca. 1994, plumòn_papel ingres,13 x 17.5 cm, 94-4.png"},
                            {"type":"file","name":"136 Proyecto Pinturerìas I, ca. 1994, Plumòn_papel ingres, 94-8.png"},
                            {"type":"file","name":"137 Proyecto Pinturerìas II, ca. 1994, Plumòn_papel ingres, 94-9.png"},
                            {"type":"file","name":"138 Proyecto Pinturerìas III, ca. 1994, Tinta_papel ingres, 21.7 x 27.8 cm., 94-10.png"},
                            {"type":"file","name":"139 95-14 Plumòn_p manila, 31.5x45 cm,. 95-1.png"},
                            {"type":"file","name":"14 Pianista, Sin firma, ca. 1960, Pastel_cartulina, 30.6 x 45.7 cm.. 60-2.png"},
                            {"type":"file","name":"140 Danza p. 40x50 c., 95-2.png"},
                            {"type":"file","name":"15 Director de Orquesta, Sin firma, ca. 1960, Pastel_papel ingres, 31 x 46 cm., 60-3.png"},
                            {"type":"file","name":"16 Compositor, Sin firma, ca. 1960, Pastel_cartulina. 60-4.png"},
                            {"type":"file","name":"17 Boceto Pescadores, Sin firma, ca. 1963, Tinta_caroncillo, 22.7 x 33.8 cm. 63-3.png"},
                            {"type":"file","name":"18 Apunte Lancheros, Sin firma, ca. 1964 plumòn _cartulina, 12.1 x 25 cm., 64-1.png"},
                            {"type":"file","name":"19 Apunte Pescadores, Sin firma, ca. 1964,  Plumil_cartoncillo, 9.6 x 15.7 cm 64-2.png"},
                            {"type":"file","name":"2 Niño Toño, 1923, Sepia_cartoncillo, 33 x 24 cm., 23-4.png"},
                            {"type":"file","name":"20 Apunte Pescador, Sin firma, ca. 1964, Plumòn_carulina, 9.6 x 15.7 cm., 64-2R.png"},
                            {"type":"file","name":"21 Bosquejo _No te vayas_, Sin firma, ca. 1968, mixta_c 31.7x24.3 cm., 68-1.png"},
                            {"type":"file","name":"22 Bosquejo Exposiciòn solar, Sin firma, ca. 1968, Acuarela y plumòn, 24.5 x 32 cm., 68-1R.png"},
                            {"type":"file","name":"23 Boceto Espiritu de la raza 1, Sepia_cartoncillo, 31.8 x 24.4 cm., 68-2.png"},
                            {"type":"file","name":"24 Boceto Espiritu de la raza 2, Sin firma, ca. 1968, Tinta_papel manila, 31.5 x 23 cm., 68-3.png"},
                            {"type":"file","name":"25 Boceto Espiritu de la raza 3, Sin firma, ca. 1968, Sepia_cartoncillo, 24.4 x 31 cm., 68-4 .png"},
                            {"type":"file","name":"26 Boceto Espiritu de la Raza 5, Sin firma, ca, 1968, Làpiz_papel, 22.3 x 31.7 cm., 68-5 .png"},
                            {"type":"file","name":"27 Boceto Espiritu de la raza 6, Sin firma, ca. 1968, Plumòn_papel manila, 22.1 x 31.6 cm., 68-6.png"},
                            {"type":"file","name":"28 Boceto Espiritu de la raza, 6, Sin firma, ca. 1968, Sepia_cartoncillo, 31.3 x 24.4 cm., 68-7.png"},
                            {"type":"file","name":"29 Boceto Espiritu de la raza 7, Sin firma, ca. 1968, Plumòn_cartoncillo, 31.3 x 24.4 cm., 68-7R.png"},
                            {"type":"file","name":"3 Boceto mujer con charola, Sin firma, ca. 1930, Tinta_cartulina, 21.2 x 13.2 cm., 30-10.png"},
                            {"type":"file","name":"30 Sùplica, Sin firma, ca. 1969, Plumòn_cartoncillo, 31.8 x 24.4 cm., 69-2.png"},
                            {"type":"file","name":"31 Jesùs 2, ca. 1969, Plumòn_cartoncillo,  31.8 x 24.4 cm., 69-3 .png"},
                            {"type":"file","name":"32 Jesùs 1, Sin firma, ca. 1969, Làpiz_cartoncillo, 31.8 x 24.4 cm., 69-3R.png"},
                            {"type":"file","name":"33 Jesùs 3, ca. 1969,  Plumòn_cartoncillo, 31.8 x 24.4 cm., 69-4.png"},
                            {"type":"file","name":"34 Jesùs 4, ca. 1969, Plumòn_cartoncillo, 31.6 x 24.3 cm., 69-5.png"},
                            {"type":"file","name":"35 Jesùs, 5, ca. 1969, Plumòn_cartoncilo, 31.6 x 24.6 cm., 69-6.png"},
                            {"type":"file","name":"36 Bosquejo manos, Sin firma, ca. 1969, Plumòn_cartoncillo, 24.4 x 31.8 cm., 70-1.png"},
                            {"type":"file","name":"37 Bosquejo interprete, Sin firma, ca. 1969, plumòn_cartulina, 24.4 x 31.8 cm., 70-2.png"},
                            {"type":"file","name":"38 Bosquejo pianista, Sin firma, ca. 1970, Plumòn_cartoncillo, 24.4 x 31.8 cm., 70-3.png"},
                            {"type":"file","name":"39 Bosquejo musa, Sin firma, ca. 1970, Plumòn_cartoncillo, 28 x 21.5 cm., 70-4.png"},
                            {"type":"file","name":"4 Niña Otomì, 1930, Sanguina_cartulina, 30 x 23 cm., 30-11.png"},
                            {"type":"file","name":"40 Agustin interpretando, Sin firma, ca. 1970, Plumòn_cartoncillo,  70-5.png"},
                            {"type":"file","name":"41 Bosquejo musa y compositor, Sin firma, ca.  Plumòn_papel, 28 x 21.5 cm., 70-6.png"},
                            {"type":"file","name":"42 Bosquejo cenicero Inspiracion, ca. 1979, Pastel_cartulina, 49.6 x 35.2 cm., 79-19.png"},
                            {"type":"file","name":"43 Boceto manos, Sin firma, ca. 1979, Plumòn_cartoncillo,  24 x 31.8 cm., 79-23 y 81-22R.png"},
                            {"type":"file","name":"44 Apunte Familia en Acapulco, ca. 1982, Plumil_papel ingres, 30.3 x 45 cm. 82-14.png"},
                            {"type":"file","name":"45 Niña con canasta, ca. 1983, Pastel_papel ingres, 50 x 35 cm., 82-21.png"},
                            {"type":"file","name":"46 Mujer con Turbante, ca. 1982, Pastel_papel ingres,  50 x 37 cm., 83-5.png"},
                            {"type":"file","name":"47 Apunte en Tequisquiapan, ca. 1983, Plumòn_papel Ingres, 83-10.png"},
                            {"type":"file","name":"48 Apunte Familia en Tequisquiapan, Sin firma, ca. 1983, Plumòn_papel ingres, 83-13 y 88-38R.png"},
                            {"type":"file","name":"49 Joven con citara, Sin firma, ca. 1983, Pastel_papel ingres, 83-25.png"},
                            {"type":"file","name":"5 Mujer con charola, 1930, Tinta_cartulina, 13.2 x 21.2 cm., 30-12.png"},
                            {"type":"file","name":"50 Apunte Familia dia de campo, Sin firma, ca. 1983, Pastel_papel manila, 34.3 x 50 cm., 83-32.png"},
                            {"type":"file","name":"51 Apunte a peticiòn de los niños, Sin firma, ca. 1983, Pastel_papel ingres, 23.2 x 35.5 cm., 83-30.png"},
                            {"type":"file","name":"52 Boceto Pastorcillo, Sin firma, Plumil_cartoncillo, 24.3 x 31.8 cm., 84-10.png"},
                            {"type":"file","name":"53 Apunte rostro, Sin firma, ca, 1984, Plumòn_cartoncillo, 24.3 x 31.8 cm., 84-10R.png"},
                            {"type":"file","name":"54 Hacia la Lecheria, ca. 1985, Plumil_papel Ingres, 50 x 35.2 cm. 85-18.png"},
                            {"type":"file","name":"55 Siamès, 1985, Pastel_Cartoncillo, 50 x 35 cm., 85-26.png"},
                            {"type":"file","name":"56 Apunte Caballo, Sin firma, ca. 1986, Plumòn_papel bond, 21.5 x 27.9 cm., 86-8.png"},
                            {"type":"file","name":"57 Apunte caballos, Sin firma, ca. 1986, Plumòn_papel bond 31.5 x 27.9 cm., 86-9.png"},
                            {"type":"file","name":"58 Hora de baño, ca, 1979, Plumòn_cartulina, 25 x 20 cm., 86-27.png"},
                            {"type":"file","name":"59 Zapatero remendon, ca. 1987, Plumòn sobre papel bond, 27.9 x 21.5 cm., 87-4.png"},
                            {"type":"file","name":"6 Sembrador, ca. 1931, Carbon_Cartoncillo, 37 x 27 cm., 31-5.png"},
                            {"type":"file","name":"60 Serenata, ca 1987, Plumòn_papel manila, 49 x 34 cm., 87-10.png"},
                            {"type":"file","name":"61 Amigos charlando, Sin firma, ca. 1987, mixta_cartoncillo, 24,2 x 34 cm., 87-12.png"},
                            {"type":"file","name":"62 Violinista, ca. 1987, Plumòn_papel manila, 48.9 x 33.9 cm., 87-16.png"},
                            {"type":"file","name":"63 Lecheria, ca. 1989, plumòn_papel manila, 44.5 x 35.5 cm., 89-10.png"},
                            {"type":"file","name":"64 Fiesta de la Cueva, faldas cerro de la Bufa, ca. 1981, Plumòn_papel ingres, 35.4 x59 cm.,81-11.png"},
                            {"type":"file","name":"65 Fiesta de la Cueva, Sin firma, ca. 1989, Plumòn_ papel ingres, 35.4 x 50 cm., 81-12 y 89-12R .png"},
                            {"type":"file","name":"66 Guitarrista, Sin firma, ca. 1990,  Plumòn_papel de china, 24 x 23.5 cm., 90-6.png"},
                            {"type":"file","name":"67 Vendedora de patos I, Sin firma, ca. 1990, Plumòn_papel ingres, 44.8 x 30.4 cm., 90-7.png"},
                            {"type":"file","name":"68 Vendedora de patos II, Sin firma, ca. 1990. plumòn_papel ingres, 44.8 x 30-4 cm., 90-7R P.png"},
                            {"type":"file","name":"69 Vendedora de Patos, ca. 1990, Plumil_ papel ingres, 49 x 43 cm.,90-8.png"},
                            {"type":"file","name":"7 Joven campesino, 1931. Carbòn_cartoncillo, 37 x 27 cm., 31-1.png"},
                            {"type":"file","name":"70 Vendedoras, ca. 1990, Plumòn_papel ingres, 17.3 x 21 cm., 90-9.png"},
                            {"type":"file","name":"71 Vendedoras, ca. 1990, Plumòn_papel ingres. 17 x 17.9 cm., 90-10.png"},
                            {"type":"file","name":"72 Danzante 1, Sin firma, ca. 1990, plumòn_papel bond, 27.9 x 21.5 cm., 90-12.png"},
                            {"type":"file","name":"73 Danzante 2,  Sin firma, ca. 1990, Plumòn_papel bond, 27.8 x 21.5 cm., 90-13.png"},
                            {"type":"file","name":"74 Danza del venado, Sin firma, ca. 1990, Plumòn_cartulina, 21.7 x 28 cm., 90-14.png"},
                            {"type":"file","name":"75 Variaciòn danzante 3, Sin firma, ca.1990, Pastel_papel Ingres, 34.4 x 24.4 cm., 90-15.png"},
                            {"type":"file","name":"76 Danzantes, Sin firma, ca. 1990, Plumòn_papel ingres, 34.9 x 42.5 cm., 90-16.png"},
                            {"type":"file","name":"77 Danzante, Sin firma, ca, 1990, Pastel_papel ingres, 50 x 35.2 cm. 90-17.png"},
                            {"type":"file","name":"78 Variaciòn danzantes, Sin firma, ca. 1990, Pastel_papel Ingres, 35.3 x 50.5 cm., 90-19R.png"},
                            {"type":"file","name":"79 Mùsicos, Sin firma, ca. 1990, Plumòn_papel, 24.4 x 34.4 cm., 90-21 y 90-21R.png"},
                            {"type":"file","name":"8 Nicanor el jornalero, 1931, Carbón_cartoncillo, 47 x 33 cm., 31-8.png"},
                            {"type":"file","name":"80 Boceto Encuentro de dos culturas I, Sin firma, ca. 1990, Carboncillo_papel manila, 41 x 49 cm., 90-22.png"},
                            {"type":"file","name":"81 Boceto El flechador, Sin firma, ca. 1990, Plumòn_papel, 34.5 x24.4 cm., 90-31.png"},
                            {"type":"file","name":"82 Bosquejo bailarines de tango, Sin firma, ca. 1990. Pastel_papel, 41.5x5090-34R.png"},
                            {"type":"file","name":"83 El Trovador, ca 1990, Plumòn_papel ingres, 50 x 35.3 cm., 90-41 .png"},
                            {"type":"file","name":"84 Trìo, ca. 1990, Plumòn_papel ingres, 35 x 50 cm., 90-42 .png"},
                            {"type":"file","name":"85 Musicos en Bar de plaza Baratillo, ca. 1990, plumòn_papel ingres, 50 x 35 cm., 90-43.png"},
                            {"type":"file","name":"86 Grupo Mono Blonda de Veracruz, ca. 1991, Plumòn_papel ingres, 35 x 50 cm., 90-44.png"},
                            {"type":"file","name":"87 Trìo, Sin firma, ca. 1991, Pastel_papel ingres, 50 x 35 cm., 91-15 y 91-15R Danza c. 1993.png"},
                            {"type":"file","name":"88 Dueto invidentes en Guanajuato, Sin firma, ca. 1991, Plumòn y gouache_cartoncillo, 92-23R y 59-2  .png"},
                            {"type":"file","name":"89 Danza, Sin firma, ca. 1991, Pastel_papel ingres, 91-14.png"},
                            {"type":"file","name":"9 Caporal, 1932, Lápiz_Cartoncillo, 25 x 21 cm., 32-6.png"},
                            {"type":"file","name":"90 Danza, Sin firma, ca. 1992, Pastel_papel Ingres, 50 x 35.4 cm., 92-1 .png"},
                            {"type":"file","name":"91 Danza, Sin firma, ca. 1992, Pastel_papel ingres, 92-2 y R.png"},
                            {"type":"file","name":"92 Silueta, Sin firma, ca. 1992 Pastel_papel ingres, 92-2R.png"},
                            {"type":"file","name":"93 Proyecto, Sin firma, ca. 1992, Pastel_papel ingres, 92-3,.png"},
                            {"type":"file","name":"94 Danza, Sin firma, ca. 1992, Pastel_papel ingres,92-4.png"},
                            {"type":"file","name":"95 Baile, Sin firma, ca. 1992, Pastel_papel ingres, 92-5,.png"},
                            {"type":"file","name":"96 Proyecto siluetas, Sin firma, ca. 1992, 50 x 35.4, 92-6.png"},
                            {"type":"file","name":"97 Danza, Sin firma, ca. 1993, Pastel_papel manila, 92-7.png"},
                            {"type":"file","name":"98 Boceto para proyecto 1, Sin firma, ca. 1993, Pastel_papel ingres, 50.1 x 38.1 cm., 92-8 .png"},
                            {"type":"file","name":"99 Boceto para proyecto 2, Sin firma, ca. 1992, Pastel_papel ingres, 50 x 35 cm., 92-10 y 92-10R .png"}
                        ]}
                ]},
            {"type":"directory","name":"4 FIGURA","contents":[
                    {"type":"directory","name":"1 RETRATO","contents":[
                            {"type":"directory","name":"1 PINTURA","contents":[
                                    {"type":"file","name":"0 Autorretrato, Òleo_tela, 70 x 50 cm. 78-5 .png"},
                                    {"type":"file","name":"1 Candido Gómez, padre del autor, 1933, Óleo_Tela 60 x 50 cm., 33-6.png"},
                                    {"type":"file","name":"10 Elisa Macias de Guevara, ca 1979, Óleo_Tela, 80 x 60 cm.,., 79-32.png"},
                                    {"type":"file","name":"11 Adolfo Gomez, hijo del autor, sin firma, ca.1980, Óleo_tela, 80 x 60 cm., 80-3.png"},
                                    {"type":"file","name":"12 Salvador Salgado, 1982, Óleo_tela, 65 x 50 cm., 82-1.png"},
                                    {"type":"file","name":"13, Luz Maria, 1983, Óleo_tela, 66 x 50 cm., 83-20.png"},
                                    {"type":"file","name":"14 Herminio Salgado, 1988,  Óleo_tela, 70 x 50 cm., 88-42.png"},
                                    {"type":"file","name":"15 Director de Orquesta, Sin firma, ca, 1983, Acrilico y arenas_papel hecho a mano, 65 x 50 cm., 92-10R .png"},
                                    {"type":"file","name":"2 Leopoldo _El Flaco_, 1945, Óleo_Masonite, 35 x 28 cm., 45-4.png"},
                                    {"type":"file","name":"3 Jose Lopez Alavez, 1951, Öleo_Tela, 60 x 50 cm., 1951-1.png"},
                                    {"type":"file","name":"4 Herlinda en la ventana, 1955, Óleo_Tela, 104 x 85 cm., 55-3.png"},
                                    {"type":"file","name":"5 Dr. Eucario Salgado, 1958, Óleo_Tela, 104 x 85 cm., 58-2.png"},
                                    {"type":"file","name":"6 Ana (Fragmento) ca. 1959, Óleo_Tela, 80 x 50  cm aprox. 59-1 .png"},
                                    {"type":"file","name":"7 Herlinda, ca. 1970, Óleo_Tela, 60 x 50 cm, 70-1.png"},
                                    {"type":"file","name":"8 Fernando, 1973, ôleo_tela, 107x88 cm., 73-3.png"},
                                    {"type":"file","name":"9 Autorretrato, Óleo_tela, 70 x 50 cm., 78-5.png"}
                                ]},
                            {"type":"directory","name":"2 DIBUJO","contents":[
                                    {"type":"file","name":"1 Rostro femenino, 1922, Sepia_cartoncillo, 32.8 x 24 cm., 22-2R.png"},
                                    {"type":"file","name":"10 Ludving van Beethoven,  Mixta_papel_tela, ca. 1927, 65 x 50 cm. aprox., 27-2.png"},
                                    {"type":"file","name":"11 Dama con piel de zorro al hombro, Probablemente Antonieta Rivas Mercado, 1927, Sanguina_papel,  19.5 x 12.5 cm., 27-3.png"},
                                    {"type":"file","name":"12 Apunte Pintor Miguel Cruz, 1927, Carboncillo y làpiz_cartoncillo, 31.3x23.4, 27-10.png"},
                                    {"type":"file","name":"13 Apunte de una pintora, sin firma, ca. 1928, Lápiz_papel, 16.8 x 22.2 cm., 28-3.png"},
                                    {"type":"file","name":"14 Pintor Ricardo Barcenas, 1928, Carboncillo_papel, 19.6 x 12.5 cm., 28-5.png"},
                                    {"type":"file","name":"15 Autorretrato, 1928, Sepia_Cartoncillo, 32x22.5 cm., 28-8.png"},
                                    {"type":"file","name":"16 Adolescente, (Fragmento), Sin firma, ca. 1929, Sanguina_cartulina, 26.6 x 17.2 cm., 29-1R.png"},
                                    {"type":"file","name":"17 Maria Izquierdo, Sin firma, ca. 1929, Anilina_papel, 26.6 x 17.2 cm., 29-2.png"},
                                    {"type":"file","name":"18 Caricatura de un pintor, Sin firma, ca. 1928, Lápiz_cartulina, 21 x 13 cm., 29-3.png"},
                                    {"type":"file","name":"19 Rostro femenino, 1929,  Carboncillo y làpiz_cartulina, 30 x 21 cm., 29-6.png"},
                                    {"type":"file","name":"2 Autorretrato, 1922. Sepia_cartoncillo, 33 x 24 cm., 22-4.png"},
                                    {"type":"file","name":"20 Miguel Cruz, 1929, Carbòn_Cartòn, 27.4 x 20, 29-7.png"},
                                    {"type":"file","name":"21 Perfil femenino, Sin firma, ca. 1930, Lápiz_cartoncillo, 32.5 x 23.8 cm., 30-2.png"},
                                    {"type":"file","name":"22 Autorretrato a Linea. Sin firma, Lápiz_catoncillo, 21.2x13.2 cm., 30-3.png"},
                                    {"type":"file","name":"23 Angelica, Sin firma, ca. 1930, Lápiz_cartoncillo, 21.2 x 13.2 cm 30-5.png"},
                                    {"type":"file","name":"24 Dolores, Sin firma, ca. 1930, Sanguina_cartoncillo,  27.3 x 21.3 cm., 30-6.png"},
                                    {"type":"file","name":"25 Retrato de un pintor,  ca. 1931, Carbón_cartoncillo, 27.3 x 21.3 cm., 31-1.png"},
                                    {"type":"file","name":"26 Adolescencia, ca. 1931,  Carboncillo__cartulina, 47 x 33 cm aprox. 31-6.png"},
                                    {"type":"file","name":"27 German Gedovius, 1931, Carbòn_Cartoncillo, 48 x 30 cm., 31-9.png"},
                                    {"type":"file","name":"28 Pintora Rosario Cabrera, Lápiz_papel, 1932, 19.6 x 12.5 cm., 32-5.png"},
                                    {"type":"file","name":"29 Pintor Aldazoro, 1932. Carboncillo_cartoncillo, 34.5 x 23.5 cm., 32-10.png"},
                                    {"type":"file","name":"3 Rostro juvenil, Sin firma, ca, 1923, Sepia_cartoncillo, 32.9 x 24 cm., 23-3R.png"},
                                    {"type":"file","name":"30 Rostro de perfil, Sin firma, ca. 1933, Lápiz_cartoncillo, 32 x 24 cm., 33-4.png"},
                                    {"type":"file","name":"31 Rostro febril, 1933, Sanguina_cartoncillo, 17.2 x 26.6 cm., 33-5.png"},
                                    {"type":"file","name":"32 Somnolencia, Sin firma, ca. 1933, Lápiz_Cartoncillo, 22.5 x 34 cm., 22.5 x 34 cm., 33-9R.png"},
                                    {"type":"file","name":"33 Apunte de Salvador, Sin firma, ca. 1936, Sanguina_Cartoncillo, 13.2 x 21.2 cm., 36-2R.png"},
                                    {"type":"file","name":"34 Compositor, 1937,  Lápiz_Cartoncillo, 23.5 x 17 cm., 37-2.png"},
                                    {"type":"file","name":"35 Victoria, ca. 1938,  Lápiz_cartoncillo, 18 x 13 cm., 38-4.png"},
                                    {"type":"file","name":"36 Juanito, 1940, Sepia_Cartoncillo, 20 x 13 cm., 40-1.png"},
                                    {"type":"file","name":"37 Apunte rostro, Sin firma, ca. 1940, Tinta_cartoncillo, 40-4.png"},
                                    {"type":"file","name":"38 Rostro masculino, Sin firma, ca. 1940, Tinta_cartoncillo,  27.4 x 21.5 cm., 27.4 x 21.5 cm., 40-5.png"},
                                    {"type":"file","name":"39 Alfredo Gil, 1940, Tinta_cartoncillo, 27 x 21 cm., 40-7.png"},
                                    {"type":"file","name":"4 Retrato de un pintor, 1923, Sepia_cartoncillo, 33 x 24 cm., 23-5.png"},
                                    {"type":"file","name":"40 Ambrosio Cortes, Sin firma, ca. 1943, Mixta_papel_tela, 41.5,x,34 cm., 43-3.png"},
                                    {"type":"file","name":"41 Autorretrato, Sin firma, ca. 1944, Sanguina_cartoncillo, 21.2 x 13.2 cm., 44-5.png"},
                                    {"type":"file","name":"42 Alfredo, 1947, Pastel_papel, 25 x 20 cm., 47-4.png"},
                                    {"type":"file","name":"43 Maria de las Nieves Sanchez Gaytan, Sin firma, ca. 1952, Mixta_papel_madera, 20 x 15 cm., 52-3.png"},
                                    {"type":"file","name":"44 Guadalupe, Sin firma, ca. 1957, Mixta_papel_tela, 57-1.png"},
                                    {"type":"file","name":"45 Eloisa, Sin firma, ca. 1967, Mixta_papel_tela,40 x 30 cm., 78-1.png"},
                                    {"type":"file","name":"46 Linda, Sin firma, ca. 1970, Mixta_papel_tela, 34.8 x 27.7 cm., 70-1 .png"},
                                    {"type":"file","name":"47 Ruth, Sin firma, ca.1971, Mixta_papel_Tela, 56 x 44.3 cm., 71-1.png"},
                                    {"type":"file","name":"48 Ruth, Sin firma, ca. 1971, Mixta_papel _tela, 35 x 28 cm., 71-2.png"},
                                    {"type":"file","name":"49 David, Sin firma, ca. 1976, Mixta_papel_Tela, 56 x 44.3., 76-3 cm.png"},
                                    {"type":"file","name":"5 Una Madre, Sin firma, ca. 1924, Carboncillo_cartulina, 32.8 x 24 cm., 24-1.png"},
                                    {"type":"file","name":"50 Trinidad Aguado, Sin firma, ca. 1977, Mixta_papel_Tela, 60 x 50 cm., 77-5.png"},
                                    {"type":"file","name":"51 Victoria I, ca. 1979, Impresiòn Acuareleada_papel cascaròn, 38.3 x 28.2 cm., 79-7.png"},
                                    {"type":"file","name":"52 Victoria II, ca. 1979, Impresiòn Acuareleada_papel cascaròn, 40.2 x 30 cm. 79-8.png"},
                                    {"type":"file","name":"53 Julio Espinoza, Sin firma, ca. 1979, Plumil_papel ingres, 50 x 35 cm., 79-13.png"},
                                    {"type":"file","name":"54 Boceto Agustin 1, ca, 1979, Làpiz_papel ingres,  79-18.png"},
                                    {"type":"file","name":"55 El flaco de oro, Sin firma, ca. 1979, Làpiz_cartoncillo, 79-20,.png"},
                                    {"type":"file","name":"56 Boceto Inspiraciòn, ca. 1979, Plumòn_cartoncillo, 23 x 33.5 cm., 79-22.png"},
                                    {"type":"file","name":"57 Boceto Agustin Lara, ca. 1979, Pastel_cartulina, 30 x 45 cm., 79-24.png"},
                                    {"type":"file","name":"58 Maura, 1980, Sanguina_papel ingres, 53 x 38 cm., 80-9.png"},
                                    {"type":"file","name":"59 Samuel, ca. 1980, Plumòn_papel ingres, 50.5 x 35.5 cm., 80-15.png"},
                                    {"type":"file","name":"6 Cabeza femenina, 1924, Carbón_tapa de Block, 32.1 x 23.2 cm., 24-7.png"},
                                    {"type":"file","name":"60 Apunte Jorge I, Sin firma, ca. 1980, Pastel_papel ingres, 50 x 35.5 cm., 80-18.png"},
                                    {"type":"file","name":"61 Apunte Jorge II, Sin firma, ca. 1980, Plumòn_papel ingres, 50 x 35.5 cm. 80-18R.png"},
                                    {"type":"file","name":"62 Hector, Sin firma, ca. 1980, Plumòn_papel ingres, 50 x 35 cm., 80-21.png"},
                                    {"type":"file","name":"63 Cinthia, ca. 1980, Plumòn_papel ingres, 50 x 35.2 cm., 80-23.png"},
                                    {"type":"file","name":"64 Karina, ca. 1980, Plumòn_papel ingres, 50 x 37.5 cm., 80-26.png"},
                                    {"type":"file","name":"65 Apunte Eloisa, Sin firma, ca. 1982, Pastel_papel, 33 x 48 cm., 83-1.png"},
                                    {"type":"file","name":"66 Florencio _El Jondias_, 1983, Sanguina_papel ingres, 44 x29 cm., 83-22.png"},
                                    {"type":"file","name":"67 Director de Orquesta, ca, 1984, Pastel_p  45x33 cm., 84-21R.png"},
                                    {"type":"file","name":"68 Apunte Rolando, Sin firma, ca. 1990, plumòn_papel bond, 27.9x21.5 cm., 86-8.png"},
                                    {"type":"file","name":"69 Apunte rostro femenino, Sin firma, ca, 1987, Sepia_papel ingres, 50 x 35.5 cm., 87-9R.png"},
                                    {"type":"file","name":"7 Rostro inerte, Sin firma, ca 1925, Lápiz_cartulina 13.2 x 21.2 cm., 25-8.png"},
                                    {"type":"file","name":"70 Rosario Sabatè, ca. 1987, Sanguina_papel ingres, 50.5 x 35.5 cm., 87-19.png"},
                                    {"type":"file","name":"71 Apunte Cinthia, Sin firma, ca. 1992, Pastel_papel ingres, 50 x 35.2 cm., 92-20.png"},
                                    {"type":"file","name":"72 Hector Peñarrieta, Sin firma, ca. 1992, Carboncillo_papel ingres, 50 x 36.5 cm., 92-31.png"},
                                    {"type":"file","name":"73 Apunte Hector, Sin firma, ca. 1882, Sepia_papel ingres, 50 x 36.5 cm., 92-31R.png"},
                                    {"type":"file","name":"8 Dr. Atl, 1926, Làpiz_cartoncillo, 33 x 20 cm., 26-7.png"},
                                    {"type":"file","name":"9 Ludwin van Beethoven, ca. 1927, Óleo_Tela, 50 x 35 cm. aprox. 27-1.png"}
                                ]}
                        ]},
                    {"type":"directory","name":"2 DESNUDO DIBUJO","contents":[
                            {"type":"file","name":"1 Mujeres con càntaros, Sin firma, ca. 1919, Sepia_cartoncillo, 32.9 x 23.9 cm., 19-1.png"},
                            {"type":"file","name":"10 Estudio hombre de espaldas, ca. 1921, Sepia_cartoncillo, 32.9 x 24 cm., 21-1R.png"},
                            {"type":"file","name":"100 Mujer de pie, Sin firma, ca 1932, Làpiz_cartulina, 32 x 24 cm., 33-1.png"},
                            {"type":"file","name":"101 Figuras femeninas, Sin firma, ca. 1933, Làpiz_cartulina, 32 x 24 cm., 33-1R.png"},
                            {"type":"file","name":"102 Mujer en movimiento, 1933, Làpiz_cartulina, 19.6 x 12.5 cm., 33-2.png"},
                            {"type":"file","name":"103 Tres figuras femeninas, Sin firma, ca. 1933, Làpiz_cartulina, 32 x 24 cm., 33-3.png"},
                            {"type":"file","name":"104 Actitudes femeninas, ca. 1933, Sin firma, Làpiz_cartulina, 32 x 24 cm., 33-3R.png"},
                            {"type":"file","name":"105 Pareja recostada, Sin firma, ca. 1933,  Sanguina_cartulina, 17.2 x 26.6 cm., 33-7.png"},
                            {"type":"file","name":"106 Apunte figuras femeninas, Sin firma, ca. 1933, Sanguina_cartulina, 17.2 x 26.6 cm., 33-7R.png"},
                            {"type":"file","name":"107 Dos figuras femeninas, Sin firma, ca. 1933, Sanguina_cartulina, 26.6 x 17 cm., 33-8.png"},
                            {"type":"file","name":"108 Apunte tres desnudos femeninos, Sin firma, ca. 1933, Sanguina_cartulina, 26.6 x 17 cm., 33-8R.png"},
                            {"type":"file","name":"109 Parejas, 1933, Làpiz_cartulina, 34 x 22.5 cm.,33-9.png"},
                            {"type":"file","name":"11 Estudio femenino de pie, ca.1921, Sepia_cartoncillo, 32.9 x 24.1 cm., 21-2.png"},
                            {"type":"file","name":"110 Escorzo, 1933, Sepia_cartulina, 17.2 x 26.6 cm., 33-10.png"},
                            {"type":"file","name":"111 Anatomia femenina, Sin firma, ca 1933, Sepia_cartulina, 17.2 x 26.6 cm., 33-10R.png"},
                            {"type":"file","name":"112 Apunte figuras masculinas, Sin firma, ca. 1934, Làpiz_cartulina, 23.2 x 31.6 cm., 34-1.png"},
                            {"type":"file","name":"113 Apunte anatomia masculina, Sin firma, ca. 1934, Làpiz_cartulina, 23.2 x 31.6 cm., 34-1R.png"},
                            {"type":"file","name":"114 Emociones encontradas, Sin firma, ca. 1934, Sanguina_cartulina, 23.5 x 31.7 cm., 34-2.png"},
                            {"type":"file","name":"115 Mujeres preñadas, Sin firma, ca. 1934, Sepia_cartulina, 23.5 x 31.7 cm., 34-2R.png"},
                            {"type":"file","name":"116 Propuesta, ca. 1934, Sin firma, Pastel_ cartulina, 23.5 x 31.7 cm.,34-3.png"},
                            {"type":"file","name":"117 Mujeres relajadas, Sin firma, ca. 1935, Làpiz_cartulina,  31.7 x 23.5 cm., 34-3R.png"},
                            {"type":"file","name":"118 Actitudes, Sin firma, ca. 1934, Pastel_cartulina,  31.7 x 23.5 cm. 34-4.png"},
                            {"type":"file","name":"119 Emociones II, Sin firma, ca. 1934, Pastel_cartulina, 31.7 x 23.5 cm., 34-4R.png"},
                            {"type":"file","name":"12 Estudio desnudo femenino, Sin firma, ca.1921, Sepia_cartoncillo, 32.9 x 24.1 cm., 21-2R .png"},
                            {"type":"file","name":"120 Actitudes femeninas, Sin firma, ca. 1934, Làpiz_cartulina, 23.5 x 31.7 cm., 34-5.png"},
                            {"type":"file","name":"121 Apuntes anatomia, Sin firma, ca. 1934, Làpiz y pastel_cartoncillo, 31.7 x 23.5 cm., 34-5R.png"},
                            {"type":"file","name":"122 Figuras masculinas, Sin firma, ca. 1934,  Sanguina_cartulina, 23.5 x 31.7 cm., 34-6.png"},
                            {"type":"file","name":"123 Figuras masculinas II, Sin firma, ca 1934, Làpiz y pastel_cartulina, 23.5 x 31.7 cm., 34-6R.png"},
                            {"type":"file","name":"124 Actitudes II, Sin firma, ca. 1934, Làpiz y sanguina_cartulina, 23.5 x 31.7 cm., 34-7.png"},
                            {"type":"file","name":"125 Variantes anatomia, Sin firma, ca. 1934, Sanguina y pastel_cartulina, 23.5 x 31.7 cm., 34-7R.png"},
                            {"type":"file","name":"126 Figuras masculinas, ca. 1934, Sin firma, Làpiz_cartulina, 32.5 x 31.7 cm., 34-8.png"},
                            {"type":"file","name":"127 Posiciones, Sin firma, ca. 1934, Làpiz_cartulina,  23.5 x 31.7 cm., 34-8R.png"},
                            {"type":"file","name":"128 Variantes, Sin firma, ca. 1934, Làpiz y pastel_cartulina, 31.7 x 23.7 cm. 34-9.png"},
                            {"type":"file","name":"129 Apuntes, Sin firma, ca. 1934, Pastel y làpiz_cartulina, 23.7 x 31.7 cm., 34-9R.png"},
                            {"type":"file","name":"13 Mujer de pie, 1922, Sepia_cartoncillo, 33 x 24 cm., 22-1.png"},
                            {"type":"file","name":"130 Sentimientos, Sin firma, ca. 1934, Làpiz_cartulina, 23.5 x 31.7 cm., 34-10.png"},
                            {"type":"file","name":"131 Varios masculinos, Sin firma, ca. 1934, sepia_cartulina, 31.7 x23.5 cm., 34-10R.png"},
                            {"type":"file","name":"132 Detalles anatomia, sin firma, ca. 1935, Làpiz_cartulina,  23.5 x 31.7 cm., 35-1.png"},
                            {"type":"file","name":"133 Apunte en peleas de box, Sin firma, ca. 1935,  Làpiz_cartulina, 23.5 x 31.7 cm., 35-1R.png"},
                            {"type":"file","name":"134 Mujer tocandose el pelo, sin firma, ca. 1935,  Pastel_cartulina, 27 x 21.3 cm., 35-2.png"},
                            {"type":"file","name":"135 Mujer sentada, ca. 1935, Sin firmar, Làpiz_cartulina, 24.4 x 20.1 cm., 35-2R.png"},
                            {"type":"file","name":"136 Actitudes, ca. 1935, Sin firmar, Pastel_cartulina, 21.3 x 27 cm., 35.3.png"},
                            {"type":"file","name":"137 Reposo, ca. 1935, Sin firmar, Làpiz_cartulina,  27.3 x 21.3 cm., 35-3R.png"},
                            {"type":"file","name":"138 Amigas, ca. 1935, Sin firmar,Sanguina_cartulina, 21.3 X 27 cm. 35-4.png"},
                            {"type":"file","name":"139 Variaciones, ca. 1935, Sin firmar, Sanguina_cartulina, 21.3 x 27 cm., 35-4R.png"},
                            {"type":"file","name":"14 Estudio desnudo de mujer, ca, 1922, Sepia_cartoncillo, 33 x 24 cm., 22-1R.png"},
                            {"type":"file","name":"140 Desnudo femenino, ca. 1935, Sanguina_cartulina,  19.9 x 26.7 cm., 35-5.png"},
                            {"type":"file","name":"141 Reconocimiento corporal, ca. 1935, Sin firmar, Sanguina_cartulina,26.6 x 16.9 cm., 35-5R.png"},
                            {"type":"file","name":"142 Mujer apenada, 1935, Làpiz_cartulina, 27 x 21 cm., 35-6.png"},
                            {"type":"file","name":"143 Reprenciòn, ca. 1935, Sin firmar, Pastel_cartulina,  21.3 X 27 cm., 35-6R.png"},
                            {"type":"file","name":"144 Ante el espejo, 1935, Làpiz_cartoncillo,  27 x 21.3 cm., 35-7.png"},
                            {"type":"file","name":"145 Escorzo masculino, 1935, Làpiz_cartulina 21.1 x 27.3 cm., 35-7R.png"},
                            {"type":"file","name":"146 Hombre reclinado, 1935, Tinta aguada_cartulina, 18 x 13 cm., 35-8.png"},
                            {"type":"file","name":"147 Hombre de espaldas, Sin firma, ca. 1935, Tinta aguada_cartulina, 18 x 13 cm., 35-8R.png"},
                            {"type":"file","name":"148 Hombre sentado en el piso, 1935, Tinta aguada_cartoncillo,13 x 18 cm., 35-9.png"},
                            {"type":"file","name":"149 Hombre agachado, Sin firma, ca. 1935, Tinta aguada_cartoncillo, 18 x 13 cm., 35-9R.png"},
                            {"type":"file","name":"15 Mujer sentada en el piso, 1922, Sepia_cartoncillo, 32.8 x 24 cm., 22-2.png"},
                            {"type":"file","name":"150 Hobre en movimiento, Sin firma, ca. 1935, Làpiz_cartoncillo, 27.3 x 21.3 cm., 36-1.png"},
                            {"type":"file","name":"151 Mujeres conversando, Sin firma, ca. 1935, Làpiz_cartoncillo,  21.3 x 27.3 cm., 36-1R.png"},
                            {"type":"file","name":"152 Hombre de pie, ca. 1935, Làpiz_cartoncillo,  13.2 x 21.2 cm., 36-2.png"},
                            {"type":"file","name":"153 Mujer de pie, ca. 1936, Làpiz_cartoncillo,  21.2 x 13.2 cm., 36-3 .png"},
                            {"type":"file","name":"154 Ejercicio, Sin firma, ca. 1936, Làpiz_cartoncillo, 24.8 x 31 cm., 36-4.png"},
                            {"type":"file","name":"155 Hombre de espaldas y mujer recostada, ca. 1936, Làpiz_cartoncillo, 27.3 x 21.3 cm., 36-5.png"},
                            {"type":"file","name":"156 Hombres ejercitandose, Sin firma, ca. 1936, Làpiz_cartoncillo, 21.3 x 27.3 cm., 36-5R.png"},
                            {"type":"file","name":"157 Desnudo Masculino, Sin firma, ca. 1937, Làpiz_cartoncillo, 32 x 23.5 cm., 37-1.png"},
                            {"type":"file","name":"158 Mujer en dos posiciones, Sin firma,ca. 1937, 32 x 23.5 cm., 37-1R c.png"},
                            {"type":"file","name":"159 Hombre en movimiento, ca. 1937, Làpiz_cartoncillo, 27.3 x 22 cm., 37-3.png"},
                            {"type":"file","name":"16 Mujer de espaldas, 1922, Sepia_cartoncillo, 32.8 x 24 cm., 22-3.png"},
                            {"type":"file","name":"160 Mujer reclinàndose, ca. 1937, Làpiz_cartulina, 25 x 21.5 cm., 38-1.png"},
                            {"type":"file","name":"161 Fin de raund, Sin firma, ca. 1938, Sanguina_cartoncillo, 25 x 21-5 cm., 38-1R .png"},
                            {"type":"file","name":"162 Mujer recargada en pared, Sin firma, ca. 1938, Pastel_cartoncillo, 26.5 x 17 cm., 38-2.png"},
                            {"type":"file","name":"163 Mujer inclinàndose, ca. 1938, Làpiz_cartoncillo, 26.5 x 17 cm., 38-2R.png"},
                            {"type":"file","name":"164 Escorso, Carboncillo_cartulina, 1938, 18 x 13 cm.,38-3.png"},
                            {"type":"file","name":"165 La siesta, ca. 1938, Làpiz_cartoncillo, 21.5 x 25 cm., 38-5.png"},
                            {"type":"file","name":"166 La espera, Sin firma ca. 1939, Tinta_cartoncillo, 25 x 21.5 cm., 38-5R.png"},
                            {"type":"file","name":"167 Mujer mano en la cadera, Sin firma, ca 1939,  21.2 x 13.2 cm., 39-1.png"},
                            {"type":"file","name":"168 Mujer caminàndo, Sin firma, ca. 1939, 21.2 x 13.2 cm., 39-1R.png"},
                            {"type":"file","name":"169 Mujer manos en la nuca, Sin firma, ca, 1939, 26.5 x 17 cm., 39-2.png"},
                            {"type":"file","name":"17 Mujer sentada en caja, ca. 1922, Sepia_cartoncillo, 32.8 x 24 cm., 22-3R.png"},
                            {"type":"file","name":"170 Mujer de perfil, Sin firma, ca. 1939, Sepia_cartulina, 26.5 x 17 cm., 39-2R.png"},
                            {"type":"file","name":"171 Perfil mujer sentada, Sin firma, ca. 1939, 16.1 x 26.7 cm., 39-3.png"},
                            {"type":"file","name":"172 Mujer peinàndose, Sin firma, ca. 1939, 26.7 x 16.1 cm., 39-3R.png"},
                            {"type":"file","name":"173 Mujer brazos en la espalda, Sin firma, Sepia_cartoncillo, 39-4 26.5X17.png"},
                            {"type":"file","name":"174 Mujer caminando, Sin firma, ca. 1939, 26.5 x 17 cm., 39-4R.png"},
                            {"type":"file","name":"175 Curiosidad, Sin firma, ca. 1939, Sepia_cartoncillo,  26.5 x 17 cm., 39-5.png"},
                            {"type":"file","name":"176 Tres mujeres, 1939, Tinta_cartoncillo, 17 x 26.5 cm., 395R.png"},
                            {"type":"file","name":"177 Piernas masculinasI, Sin firma,ca. Carboncillo_cartulina,  15.9 x 23.3 cm., 39-6.png"},
                            {"type":"file","name":"178 Piernas masculinas II, Sin firma, ca, 1939, Carboncillo_cartulina, ca,1939, 23.5 x 31 cm., 39-6.png"},
                            {"type":"file","name":"179 Desnudo masculino, 1940, Tinta_cartoncillo, 27 x 21 cm., 40-2.png"},
                            {"type":"file","name":"18 Mujer sentada en el piso, 1923, Sepia_Cartoncillo, 33 x 24 cm., 23-1.png"},
                            {"type":"file","name":"180 Hombre sentado en el piso, Sin firma, ca. 1940, 13.2 x 21.2 cm., 40-3.png"},
                            {"type":"file","name":"181 Hombre sentado, 1940, Tinta_cartoncillo, 13.2 x 21.2 cm., 40-3R.png"},
                            {"type":"file","name":"182 Joven sentado, Sin firma, ca, 1940, Tinta_cartoncillo, 21.2 x 13.2 cm., 40-6.png"},
                            {"type":"file","name":"183 Pianista e interprete, Sin firma, ca. 1940, Tinta_cartoncillo,  27 x 21 cm., 40-7R.png"},
                            {"type":"file","name":"184 Mujer con brazos cruzados, Sin firma, ca. 1941, Tinta_cartoncillo, 21 x 13 cm., 41-1.png"},
                            {"type":"file","name":"185 Dos poses I, ca. 1941, 27.5 x 21 cm., 41-2.png"},
                            {"type":"file","name":"186 Piernas femeninas, Sin firma, ca. 1941, Tinta_cartoncillo, 12.3 x 13.2 cm., 41-3.png"},
                            {"type":"file","name":"187 Mujer de espaldas, Sin firma, ca. 1941, Tinta_cartulina, 26.7 x 17.2 cm., 41-4.png"},
                            {"type":"file","name":"188 Mujeres posando, Sin firma, ca. 1941, Tinta_cartoncillo, 17.2X26.5 cm. 41-4R.png"},
                            {"type":"file","name":"189 Dos poses II, Sin firma, ca. 1941, Tinta_cartoncillo, 21.5 x 27.3 cm., 41-5.png"},
                            {"type":"file","name":"19 Estudio decùbito lateral, Sin firma, ca.1923, Sepia_cartoncillo, 24 x 33 cm., 23-1R.png"},
                            {"type":"file","name":"190 Mujer sentada, 1942, Tinta_cartulina, 21 x 13 cm., 42-1.png"},
                            {"type":"file","name":"191 Mujer sentada de espaldas, 1942, Tinta_cartoncillo, 21 x 13 cm., 42-2 .png"},
                            {"type":"file","name":"192 Mujer sentada en banco, 1942, Tinta_cartoncillo, 21 x 13 cm., 42-3.png"},
                            {"type":"file","name":"193 Espalda de mujer de pie I, 1942, Tinta_cartoncillo,  21 x 13 cm., 42-4.png"},
                            {"type":"file","name":"194 Mujer de espaldas II,  1942, Tinta_cartoncillo, 21 x 13 cm., 42-5.png"},
                            {"type":"file","name":"195 Mujer de pie, 1942, Tinta_cartoncillo, 21 x 13 cm., 42-6.png"},
                            {"type":"file","name":"196 Mujer mano sobre banco, 1942, Tinta_cartulina, 21 x 13 cm., 42-7 .png"},
                            {"type":"file","name":"197 Mujer acostada, Sin firma, ca. 1943, Pastel_cartoncillo,  24.4 x 34.5 cm., 43-1.png"},
                            {"type":"file","name":"198 Mujer acostada de lado, ca. 1944, Pastel_cartoncillo, 21 x 27.5 cm., 44-1.png"},
                            {"type":"file","name":"199 Mujer mano sobre su frente, Sin firma, ca. 1944, Làpiz_cartoncillo, 21 x 27.5 cm. 44-1R.tiff"},
                            {"type":"file","name":"2 Estudio hombre inclinado, Sin firma, ca 1919, Sepia_Cartoncillo, 32.9 x 23.9 cm., 19-1R.png"},
                            {"type":"file","name":"20 Mujer sentada en caja, 1923, Sepia_cartoncillo, 32.9 x 23 cm., 23-2.png"},
                            {"type":"file","name":"200 Mujer recostada pierna flexionada, 1944, Làpiz_cartoncillo, 21 x 27.5 cm.,, 44-2 c.png"},
                            {"type":"file","name":"201 Mujer recostada, Sin firma, ca, 1944, Làpiz_cartoncilo, 44-2R c.png"},
                            {"type":"file","name":"202 Mujer acostada pierna flexionada, Sin firma, ca, 1944, Làpiz_cartoncillo, 44-3.png"},
                            {"type":"file","name":"203 Mujer pierna flexionada, 1944, Làpiz_cartoncillo, 21 x 27.5 cm., 44-4.tiff"},
                            {"type":"file","name":"204 Mujer sentada I, Sin firma, ca. 1947, Pastel_papel, 21.1 x 16 cm., 47-1.png"},
                            {"type":"file","name":"205 Hombre sentado, Sin firma, ca. 1947, Pastel_papel, 25 x 20 cm., 47-2.png"},
                            {"type":"file","name":"206 Mujer sentada II, Sin firma, ca. 1947, Pastel_papel, 25 x 20 cm.,  47-3.png"},
                            {"type":"file","name":"207 Mujer sentada III. Sin firma, ca. 1947, Pastel_papel, 26 x 20 cm., 47-5.png"},
                            {"type":"file","name":"208 Mujer de Espaldas, Sin firma, ca. 1947, Pastel_papel, 17.1 x 12.4 cm., 47-6.png"},
                            {"type":"file","name":"209 Mujer recostada, 1948, Pastel_papel, 20 x 25 cm., 48-1.png"},
                            {"type":"file","name":"21 Mujer sentada de espaldas, Sin firma, ca. 1923, Sepia_cartoncillo, 32.9 x 23 cm., 23-2R.png"},
                            {"type":"file","name":"210 Mujer de Pie, Sin firma, ca. 1948, Pastel_papel, 25 x 20 cm., 48-1R.png"},
                            {"type":"file","name":"211 Mujer sentada en el piso, 1949, Pastel_papel,  49-1.png"},
                            {"type":"file","name":"212 Mujer de espaldas, Sin firma, ca. 1949, Pastel_papel, 49-1R.png"},
                            {"type":"file","name":"213 Mujer agobiada, Sin firma. ca. 1968, Sanguina_papel, 35.3 x 40.3 cm., 68-1.png"},
                            {"type":"file","name":"214 Mujer bailando I, Sin firma, ca. 1970, Plumòn_papel bond, 28 x 21.5 cm., 70-4.png"},
                            {"type":"file","name":"215 Mujer bailando II, Sin firma, ca. 1970, Plumòn_papel, 28 x 21.5 cm., 70-6.png"},
                            {"type":"file","name":"216 Mujer sentada sobre sus piernas, ca, 1977, Plumòn_papel ingres, 50 x 35.4 cm., 77-7.png"},
                            {"type":"file","name":"217 Mujer durmiendo, Sin firma, Pastel_papel ingres, 50 x 35 cm., 83-21R.png"},
                            {"type":"file","name":"218 Lucia con flautiin, Sin firma, ca. 1985, Sanguina_papel ingres, 49.8 x 24.9 cm., 84-3.png"},
                            {"type":"file","name":"219 La siesta, Sin firma, ca. 1984, Pastel_papel ingres, 35.2 x 50 cm., 84-8.png"},
                            {"type":"file","name":"22 Estudio hombre sentado, 1923, Sepia_cartoncillo, 33 x 24 cm., 23-4R.png"},
                            {"type":"file","name":"220 Lucia de espaldas, Sin firma, ca. 1984, Pastel_papel ingres, 49.8 x 35.1 cm., 84-12.png"},
                            {"type":"file","name":"221 Lucia enamorada, ca. 1984, Sanguina_papel ingres, 50 x 35.5 cm., 84-14.png"},
                            {"type":"file","name":"222 Sofìa pensativa, ca. 1984,  Sanguina_cartoncillo, 46 x 30.5 cm., 84-16.png"},
                            {"type":"file","name":"223 Sofìa con mandolina, ca. 1984, Pastel_papel ingres, 50 x 35.2 cm., 85-2.png"},
                            {"type":"file","name":"224 Mujer afligida, ca. 1985, Sanguina_cartulina,40.3 x 35.3 c.m., 85-9.png"},
                            {"type":"file","name":"225 Mujer dormitando, ca. 1985, Pastel_papel ingres,  50 x 41 cm., 85-17 .png"},
                            {"type":"file","name":"226 Sofia sentada, ca. 1985, Pastel_papel ingres, 50 x 35 cm., 85-19.png"},
                            {"type":"file","name":"227 Ana sentada en el piso, Sin firma, ca, 1985, 35 x 50 cm., 85-21 .png"},
                            {"type":"file","name":"228 Ana recostada, ca. 1985, Pastel_papel ingres, 35.4 x 50 cm., 85-24 .png"},
                            {"type":"file","name":"229 Ana sentada, Sin firma, ca, 1985, Pastel_papel ingres, 49.4 x 34.7 cm., 85-27.png"},
                            {"type":"file","name":"23 Estudio hombre de espaldas, 1923, Sepia_cartoncillo, 33 x 24 cm., 23-5R.png"},
                            {"type":"file","name":"230 Ana recostada, Sin firma, ca, 1986, Pastel_papel ingres, 35.2 x 50 cm., 86-2.png"},
                            {"type":"file","name":"231 Ana con toalla, ca. 1986, Sepia_papel ingres, 50 x 35.2 cm., 86-6.png"},
                            {"type":"file","name":"232 Ana con velo, Sin firma, ca. 1986 pastel_papel ingres, 50 x 35 cm 86-10.png"},
                            {"type":"file","name":"233 Ana con cabeza inclinada, Sin firma, ca. 1986, Sepia_papel ingres, 35.4 x 50 cm., 86-12.png"},
                            {"type":"file","name":"234 Mujer con Abanico, Sin firma, ca. 1986, Pastel_papel ingres, 50 x 35cm., 86-17.png"},
                            {"type":"file","name":"235 Mujer sentada sobre sus piernas, ca. 1986, Pastel_papel ingres, 50 x 35.5 cm., 86-19.png"},
                            {"type":"file","name":"236 Eva recostada I, ca. 1986, Pastel_papel ingres, 35.5 x 50 cm., 86-22R y 86-22.png"},
                            {"type":"file","name":"237 Eva recostada de lado, Sin firma, ca. 1986, Pastel_papel ingres,  35.5 x 50 cm., 86-22 y 86-22R.png"},
                            {"type":"file","name":"238 Paz recostada, ca. 1986, Pastel_papel ingres,  35.5 x 50 cm., 86-24.png"},
                            {"type":"file","name":"239 Julia sentada, Sin firma, ca, 1987, Pastel_papel ingres, 49.8 x 41.3 cm., 87-1.png"},
                            {"type":"file","name":"24 Desnudo mujer sentada, 1923, Sepia_cartoncillo, 33 X 24 cm., 23-6.png"},
                            {"type":"file","name":"240 Julia acostada, Sin firma, ca.1987, Pastel_papel ingres. 34 x 45.6 cm., 87-3.png"},
                            {"type":"file","name":"241 Ana sentada, Sin firma, ca. 1987, Pastel_papel ingres, 50 x 35.4 cm., 87-5R y 89-12.png"},
                            {"type":"file","name":"242 Bosquejo desnudo femenino, Sin firma, ca. 1987,  Pastel_papel ingres, 87-8.png"},
                            {"type":"file","name":"243 Nora de pie, ca. 1987, Sepia_papel ingres, 50 x 35.5 cm., 87-9.png"},
                            {"type":"file","name":"244 Nora acostada, ca. 1987, Pastel_papel ingres, 35.5 x 50 cm.,87-15.png"},
                            {"type":"file","name":"245 Nora sentada, ca, 1988, Sepia_papel ingres, 50 x 35.5 cm., 88-4.png"},
                            {"type":"file","name":"246 Ana tomando sol, ca. 1988, Sanguina_papel ingres, 35.5 x 50 cm., 88-6.png"},
                            {"type":"file","name":"247 El Peinado de Clara, ca. 1988, Pastel_papel ingres, 50x35.5 cm., 88-43.png"},
                            {"type":"file","name":"248 Elena recostada, ca. 1989, Sepia_cartulina, 45.3 x 30.2 cm., 89-3 .png"},
                            {"type":"file","name":"249 Elena sentada, Sin firma, ca. 1989, 41.6 x 50 cm., 89-5.png"},
                            {"type":"file","name":"25 Estudio mujer sentada, 1923, Sepia_cartoncillo, 33 x 24 cm., 23-6R.png"},
                            {"type":"file","name":"250 Elena sentada rodilla flexionada, ca. 1989, Sanguina_papel ingres, 51.5 x 35 cm., 51.5 x35 cm., 89-9.png"},
                            {"type":"file","name":"251 La siesta, ca. 1989, Sanguina_papel ingres, 89-15.png"},
                            {"type":"file","name":"26 Figura femenina brazos en alto, Sin firma, ca. 1924, Carboncillo_c., 33 x 24 cm., 24-2.png"},
                            {"type":"file","name":"27 Figura femenina de espaldas, 1924, Carboncillo_c, 33 x 24 cm., 24-2R.png"},
                            {"type":"file","name":"28 Desnudo femenino recostado, 1924, Sepia_cartoncillo, 24.1 x 33 cm., 24-4.png"},
                            {"type":"file","name":"29 Mujer manos en la nuca, ca. 1924, Sepia_cartoncillo, 33 x 24.1 cm., 24-4R.png"},
                            {"type":"file","name":"3 Estudio mujer sentada, 1920, Sepia_cartoncillo, 33 x 22.4 cm., 20-1.png"},
                            {"type":"file","name":"30 Desnudo femenino rodilla al piso, 1924, Carbòncillo_cartoncillo, 33 x 24 cm., 24-5.png"},
                            {"type":"file","name":"31 Hombre en movimiento, 1924, Carboncillo_cartoncillo, 33 x 24 cm., 24-5R.png"},
                            {"type":"file","name":"32 Mujer de espaldas, ca. 1924, Sepia_cartòn de tapa posterior de block, 33 x 24.2 cm., 24-6.png"},
                            {"type":"file","name":"33 Hombre triste, Sin firma, ca. 1925, Lapiz_cartulina, 21.2 x 13.2 cm., 25-1.png"},
                            {"type":"file","name":"34 Mujer ascendiendo, sin firma, ca 1925, Làpiz_cartulina, 21.2 x 13.2 cm., 25-2R.png"},
                            {"type":"file","name":"35 Anatomia cuerpo inerte, Sin firma, ca. 1925, Carboncillo_Cartulina, 13.2 x 21.2 cm., 25-3.png"},
                            {"type":"file","name":"36 Hombre ejercitàndose I, Sin firma, ca. 1925, Lápiz_cartulina, 13.2 x 21.2 cm., 25-4.png"},
                            {"type":"file","name":"37 Hombre ejercitàndose II, Sin firma, ca. 1925, Lápiz_cartulina, 21.2 x 13.2 cm., 25-5.png"},
                            {"type":"file","name":"38 Hombre en cuclillas, 1925, Lápiz_cartulina, 13.2 x 21.2 cm., 25-6.png"},
                            {"type":"file","name":"39 Serenidad, ca.1925, Lápiz_cartulina, 21.2 x 13.2 cm.,25-7.png"},
                            {"type":"file","name":"4 Estudio mujer de espaldas, ca. 1920, Sepia_cartoncillo, 33 x 22.4 cm., 20-1R.png"},
                            {"type":"file","name":"40 Rechazo, Sin firma, ca. 1925, Anilina_cartulina, 21.2 x 13.2 cm., 25-9.png"},
                            {"type":"file","name":"41 Asombro, Sin firma, ca, 1925, Anilina_cartulina, 21.2 x 13.2 cm., 25-10.png"},
                            {"type":"file","name":"42 Curiosidad, Sin firma, ca. 1925, Anilina_cartulina, 21.2 x 13.2 cm., 25-10R.png"},
                            {"type":"file","name":"43 Vacilaciòn, Sin firma, ca. 1925, Anilina_cartulina, 21.2 x 13.20 25-11.png"},
                            {"type":"file","name":"44 Hombre ejercitandose, Sin firma, ca. 1925, Anilina_cartulina, 21.2 x 13.2 cm., 25-11R.png"},
                            {"type":"file","name":"45 Subiendo al trampolin, Sin firma, ca. 1925, Anilina_cartulina, 21.2 x 13.2 cm., 25-12.png"},
                            {"type":"file","name":"46 Hombre saltando, Sin firma, ca. 1925, Anilina_cartulina, 21.2 x 13.2 cm., 25-13.png"},
                            {"type":"file","name":"47 Espera, Sin firma, ca. 1926, Lápiz_cartulina, 26.6 x 17.2 cm., 26-1.png"},
                            {"type":"file","name":"48 Afliccion, 1926, Lápiz_cartulina, 13.2 x 21.2 cm., 26-2.png"},
                            {"type":"file","name":"49 Desanimo, Sin firma, ca. 1926, Lápiz_cartulina, 21.2 x 13.2 cm., 26-2R.png"},
                            {"type":"file","name":"5 Estudio hombre sentado, 1920, Sepia_cartoncillo,  33 x 23.3 cm., 20-2.png"},
                            {"type":"file","name":"50 Reflejo, Sin firma, ca. 1926, Lápiz_cartulina, 17.2 x 26.6 cm., 26-3.png"},
                            {"type":"file","name":"51 Mujer con rodilla flexionada, Sin firma, ca, 1926, Lápiz_cartulina, 26.6 x 17.2 cm., 26-3R.png"},
                            {"type":"file","name":"52 Mujer Posando I, 1926, Sanguina_cartulina, 32 x 23 cm., 26-4.png"},
                            {"type":"file","name":"53 Mujer Posando II, 1926, Sanguina_cartulina, 34 x 23 cm., 26-5.png"},
                            {"type":"file","name":"54 Mujer Posando III, 1926, Sanguina_cartulina, 23 x 32 cm., 26-6.png"},
                            {"type":"file","name":"55 Mujer intrigada, Sin firma, ca. 1926, Lápiz_cartulina, 32 x 23 cm., 26-6R.png"},
                            {"type":"file","name":"56 Reflexion, 1926, Sepia_cartulina, 29.5 x 22.3 cm., 26-8.png"},
                            {"type":"file","name":"57 Mujer sentada, 1926, Sepia_cartulina, 29.5 x 22.3 cm., 26-8R.png"},
                            {"type":"file","name":"58 Dama peinandose, 1927, Pastel_cartulina, 26.6 x 17.2 cm., 27-4.png"},
                            {"type":"file","name":"59 Desacuerdo, ca. Sin Firma, 1927, Sepia_cartulina  29.9 x 22.5 cm., 27-5.png"},
                            {"type":"file","name":"6 Figura femenina. ca.1920. Sepia_cartoncillo, 33 x 23.3 cm., 20-2R.png"},
                            {"type":"file","name":"60 Desconcierto, ca. 1927, Sepia_cartulina, 29.9 x 22.5 cm., 27-6.png"},
                            {"type":"file","name":"61 Desnudo femenino de pie, ca. 1927, Sepia_cartulina, 29.9 x 22.5 cm., 27-6R.png"},
                            {"type":"file","name":"62 Rechazo, 1927, sepia_cartulina, 29.9 x 22.5 cm., 27-7.png"},
                            {"type":"file","name":"63 Provocaciòn, Sin firma, ca. 1927, Sepia_cartulina, 29.5 x 22.5cm., 27-7R cm.png"},
                            {"type":"file","name":"64 Amigas, ca. 1927, Sepia_cartulina, ca. 22.5 x 29.5 cm., 27-8.png"},
                            {"type":"file","name":"65 Mujer desamparada, ca. 1927, Sepia_cartulina, 22.5 x 29.5 cm., 27-8R.png"},
                            {"type":"file","name":"66 Encuentro con sigilo, ca. 1927, Sepia_cartulina, 22.5 x 29.5 cm., 27-9.png"},
                            {"type":"file","name":"67 La Siesta, Sin firma, ca. 1927, Sepia_cartulina, 29.5 x 22.5 cm., 27-9R.png"},
                            {"type":"file","name":"68 Amigas II, 1928, Sepia_cartulina, 29.5 x 22.5 cm., 28-1.png"},
                            {"type":"file","name":"69 Mujer Bailando, 1928, Sepia_cartulina, 33 x 24 cm., 28-4.png"},
                            {"type":"file","name":"7 Estudio figura femenina, 1920, Sepia_cartoncillo, 33 x 23.3 cm., 23-3.png"},
                            {"type":"file","name":"70 Mujer ejercitandose, Sin firma, ca. 1928, Lápiz_cartulina, 33 x 24 cm., 28-4R.png"},
                            {"type":"file","name":"71 Estudio de mano, 1928, Sanguina_cartulina, 23 x 29.2 cm., 28-6.png"},
                            {"type":"file","name":"72 Hombre con Remo, ca. 1929, Lápiz_cartulina, 26.6 x 17.2 cm., 29-1.png"},
                            {"type":"file","name":"73 Estudio de manos, Sin firma ca. 1929, Sanguina_cartulina, 26.6 x 17.2 cm., 29-2R.png"},
                            {"type":"file","name":"74 Joven Observando, 1929, Anilina_cartulina, 24 x 19 cm., 29-4.png"},
                            {"type":"file","name":"75 Hombre Esperando, Sin firma, ca. 1929, Anilia_cartulina, 24 x 19 cm., 29-4R.png"},
                            {"type":"file","name":"76 Mujer apenada, Sin firma, ca. 1929, Lápiz_cartulina, 32.8 x 20.1 cm., 29-5.png"},
                            {"type":"file","name":"77 Mujer descansando, Sin firma, ca. 1929, Lápiz_cartulina, 20.1 x 32.8 cm., 29-5R.png"},
                            {"type":"file","name":"78 Estudio de piernas, Sin firma, ca. 1929,  Làpiz_cartulina, 26.5 x 17 cm., 29-8.png"},
                            {"type":"file","name":"79 Mujer con Toalla, 1930, Sepia_cartulina, 27.3 x 21.3 cm., 30-1.png"},
                            {"type":"file","name":"8 Estudio hombre de pie, 1920, Sepia_cartoncillo, 33 x 23.3 cm., 20-3R.png"},
                            {"type":"file","name":"80 Desnudo femenino a linea, ca. 1930, Làpiz_cartulina, 13.2 x 21.2 cm., 30-4.png"},
                            {"type":"file","name":"81 Derrota, ca. 1930, Lápiz_cartulina, 27.3 x 21.3 cm., 30-6R.png"},
                            {"type":"file","name":"82 Mujer posando I, ca. 1930,  Sanguina_cartulina, 26.6 x 17.2 cm., 30-7.png"},
                            {"type":"file","name":"83 Èxtasis, ca. 1930, Sanguina_cartulina, 17.2 x 26.6 cm., 30-8.png"},
                            {"type":"file","name":"84 Estudio de pie y brazo, Sin firma, ca. 1930, Sanguina_cartulina,26.6 x 17.2 cm., 30-8R.png"},
                            {"type":"file","name":"85 Desnudo femenino de pie, 1930, Sanguina_cartulina, 26.6 x 17.2 cm., 30-9.png"},
                            {"type":"file","name":"86 Mujer posando II, Sin firma, ca. 1930, Sanguina_cartulina, 26.6 x 17.2 cm., 30-9R.png"},
                            {"type":"file","name":"87 Boceto mujer con charola, Sin firma, ca. 1930, Tinta_cartulina, 21.2 x 13.2 cm., 30-10.png"},
                            {"type":"file","name":"88 Desnudo masculino de pie, Sin firma, ca. 1930, Tinta_cartulina, 21.2 x 13.2 cm., 30-10R.png"},
                            {"type":"file","name":"89 Mujer con Charola, 1930, Tinta_cartulina, 13.2 x 21.2 cm., 30-12.png"},
                            {"type":"file","name":"9 Estudio figura femenina recostada, ca. 1921, Sepia_cartoncillo, 24 x 32.9 cm., 21-1.png"},
                            {"type":"file","name":"90 Hombre agachado, Sin firma, ca. 1931, Làpiz_cartoncillo, 21.2 x 13.2 cm., 31-2.png"},
                            {"type":"file","name":"91 Curiosidad, 1931, Làpiz_cartulina, 21 x 25 cm. 31-3.png"},
                            {"type":"file","name":"92 Hombre en movimiento, 1931 Làpiz_cartulina, 25 x 21 cm. 31-4.png"},
                            {"type":"file","name":"93 Mujer triste, ca. 1932, Làpiz_cartulina, 21.2 x 13.2 cm., 32-1.png"},
                            {"type":"file","name":"94 Mujer desolada, 1932, Làpiz_cartulina, 13.2 x 21.2 cm., 32-1R.png"},
                            {"type":"file","name":"95 Hombre de pie, 1932, Làpiz_cartulina, 21.2 x 13.2 cm. 32-2.png"},
                            {"type":"file","name":"96 Mujer atenta, 1932, Làpiz_cartulina, 13.2 x 21.2  cm., 32-3.png"},
                            {"type":"file","name":"97 Mujer con rizos,1932, Làpiz_cartulina, 21.2 x 13.2 cm., 32-4.png"},
                            {"type":"file","name":"98 Mujer con rostro inclinado, 1932, Làpiz_cartulina, 34 x 18 cm., 32-8.png"},
                            {"type":"file","name":"99 Mujer de espaldas, 1932. Làpiz_cartulina, 30 x 19 cm. 32-9.png"}
                        ]},
                    {"type":"directory","name":"3 DISEÑO","contents":[
                            {"type":"file","name":"1 Vestido 1, Làpiz_papel, 26.5 x 19.6 cm., 40-9.png"},
                            {"type":"file","name":"2 Vestido 2, Làpiz_papel, 26.7 x 19.5 cm., 40-12.png"},
                            {"type":"file","name":"3 Camisòn 1, Làpiz _papel, 26.5 x 19.4 cm., 40-13.png"},
                            {"type":"file","name":"4 Camisòn 2, Làpiz_papel, 26.6 x 19.5 cm., 40-8.png"},
                            {"type":"file","name":"5 Bata, Làpiz_papel, 26.6 x 19.6 1 cm, 40-11.png"},
                            {"type":"file","name":"6 Camisòn 3, Làpiz_papel, 26.7 x 19.5 cm., 40-10.png"}
                        ]}
                ]},
            {"type":"directory","name":"5 INTERIORES","contents":[
                    {"type":"file","name":"1 Busto con Gladiolas, 1952, Òleo_tela 77 x 51 cm., 52-1.png"},
                    {"type":"file","name":"10 Boceto jarro, Sin firma, ca. 1988, Pastel_papel ingres, 88-4 y 95-1R, .png"},
                    {"type":"file","name":"11 Merienda para Carmen, ca. 1988, Ôleo_Tela, 50 x 60 cm., 88-5.png"},
                    {"type":"file","name":"12 Boceto Bodegon, Sin firma, ca, 1990, Pastel_cartulina, 30.8 x 45.8 cm., 90-2.png"},
                    {"type":"file","name":"13 Boceto rosa, Sin firima, ca. 1990, Pastel_paple ingres, 90-3 Rosa copia.png"},
                    {"type":"file","name":"14 Bodegon al alimòn con Aurora, ca. 1990, Òleo_Tela, 90 x 130 cm., 90-4.png"},
                    {"type":"file","name":"15 90-21R Jarros, Sin firmar. ca. 1990, pllumòn_papel ingres , 24.3 x 34.4 cm., 90-21R y 90-21A.png"},
                    {"type":"file","name":"16 Calabaza para Linda, ca. 1993, Òleo_Tela, 65 x 78 cm.,93-5.png"},
                    {"type":"file","name":"2 Bodegon con Calabazas,1952, Acuarela, 30 x 45 cm., 52-2.png"},
                    {"type":"file","name":"3 Rosas para Eloisa, 1966, Òleo_Tela, 51 x 61 cm., 66-1.png"},
                    {"type":"file","name":"4 Boceto Taverna, Plumòn_cartòn, 20.5 x 45.6 cm., 72-12.png"},
                    {"type":"file","name":"5 Taverna, 1973, Ôleo_Tela, 60 x 120 cm., 73-1.png"},
                    {"type":"file","name":"6 Bodegon Michoacano, ca. 1980, 37 x 54 cm., 80-8.png"},
                    {"type":"file","name":"7 Rosas para una Madre, Ôleo_Tela, 70 x 110 cm., 82-11.png"},
                    {"type":"file","name":"8 Rosa para una novia, ca. 1984, Pastel_cartoncillo, 20 x 15.5 cm., 84-19.png"},
                    {"type":"file","name":"9 Bodegòn con cazo, Pastel_papel ingres, 33 x 45 cm., 84-21.png"}
                ]}
        ]}
export default index;