import Page from "../../components/Page";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import SinglePage from "../../components/SinglePage";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 32,
    textAlign: "center",
  },
  img: {
    border: "1px solid #ddd",
    padding: 5,
    background: "black",
    boxShadow: "0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
    width: '80%',
    height: "auto",
    margin: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto'
    }
  },
  title: {
    color: "gray",
  }
}));

function Home() {
  const classes = useStyles();
  return (
      <SinglePage>
          <Container maxWidth="md" className={classes.container}>
            <Typography variant="h3">
              MUSEO VIRTUAL DEL PINTOR MEXICANO
              <Typography variant="h5" className={classes.title}>HILARIO GÓMEZ SANCHEZ</Typography>
            </Typography>
            <br />
            <img
                src="/static/img/paisaje.png"
                alt="inicio imagen"
                className={classes.img}
            />
            <Typography variant="subtitle1">
              (Guanajuato 1902 - Ciudad de Mexico 1995) Pintor, Muralista, Retocador,
              Grabador
            </Typography>
          </Container>
      </SinglePage>
  );
}

export default Home;
