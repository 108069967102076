import TreePage from "../../../components/TreePage";
import museo from "../../../data/museo";

function Index(props) {
    return (
        <TreePage section={"museo"} data={museo} {...props} />
    );
}

export default Index;
