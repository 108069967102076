import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import SinglePage from "../../../components/SinglePage";

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: "left",
  },
  container: {
    paddingTop: 32,
    paddingBottom: 32,
    textAlign: "center",
  },
  img: {
    width: '100%',
    height: "auto",
    border: "1px solid #ddd",
    padding: 5,
    backgroundColor: 'black',
    //margin: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto'
    }
  },
}));

function Introduccion() {
  const classes = useStyles();
  return (
    <SinglePage title="INTRODUCCION" headerTitle={"Introduccion"}>
      <Container maxWidth="lg" className={classes.container}>
        <img
          src="/static/img/paisaje2.jpeg"
          alt={"inicio imagen"}
          className={classes.img}
        />
      </Container>
    </SinglePage>
  );
}

export default Introduccion;
