import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Grid from "./Grid";
import SinglePage from "./SinglePage";

const useStyles = makeStyles((theme) => ({
    text: {
        textAlign: "left",
    },
    container: {
        paddingBottom: 32,
        textAlign: "left",
    },
    link: {
        color: "black",
        '&:hover': {
            color: 'gray'
        }
    },
    linkTitle: {
        textDecoration: "none",
        color: "black",
        '&:hover': {
            color: 'gray'
        }
    },
    li : {
        marginBottom: 15,
        marginLeft: 0
    },
    ul: {
        listStylePosition: "inside",
        paddingLeft: 0
    },
    title: {
        borderBottom: "3px solid #098BCC",
        textTransform: "uppercase",
        textDecoration: "none"
    }
}));

function TreePage(props) {
    const classes = useStyles();
    const query = new URLSearchParams(props.location.search);
    const section = props.section;
    const data = props.data;
    const params = query.getAll("q");

    let baseItem = data.contents;
    params.forEach((q) => {
        baseItem = baseItem.find((item) => item.name === q).contents;
    })

    const getIndex = (a) => {
        return parseInt(a.name.split('-')[0]);
    };

    const parseName = (name) => {
        const n = name.split(' ');
        return n.length === 1 ? name : n.slice(1).join(' ');
    };

    const urlParams = (slug, params) => {
        return params.length === 0 ? "?q=" + slug : "?q=" + params.join("&q=") + "&q=" + slug;
    };

    const createImgURL = (section, params, i) => {
        const baseImg = '/static/img/' + section;
        return params.length === 0 ? baseImg + '/' + i.name : baseImg + '/' + params.join('/') + '/' + i.name;
    };

    const generateTitle = function(section, params) {
        let addr = "/" + section + "/";
        const t = <Link className={classes.linkTitle} to={addr}>{parseName(section.toUpperCase())}</Link>
        const d = <span style={{marginLeft: 10, marginRight:10}}> / </span>
        const links = [t];

        if(params.length > 0) {
            links.push(d);
        }

        for(let i = 0; i < params.length; i++) {
            if (i === 0) {
                addr += "?";
            } else {
                addr += "&";
            }

            const p = params[i];
            addr += "q="+p
            let l = <Link className={classes.linkTitle} to={addr}>{parseName(p.toUpperCase())}</Link>;
            links.push(l);

            if (i !== params.length - 1) {
                links.push(d);
            }
        }

        return links;
    };

    if(baseItem.length > 0 && baseItem[0].type === 'directory'){
        const curr = baseItem.sort((a,b) => getIndex(a) - getIndex(b));
        const joinedParams = params.join(' > ');
        const title = params.length === 0 ? section.toUpperCase() : section + ' > ' + joinedParams;
        return (
            <SinglePage title={title}>
                <Container maxWidth="md" className={classes.container}>
                    <Typography variant="h5" className={classes.title}>
                        {generateTitle(section, params)}
                    </Typography>
                    <br />
                    <ul className={classes.ul}>
                        {curr.map((item) =>
                            item.contents.length > 0 ? <li className={classes.li}>
                                <Link
                                    className={classes.link}
                                    to={"/"+section + urlParams(item.name, params)}
                                >
                                    {parseName(item.name)}
                                </Link>
                            </li> : null

                        )}
                    </ul>
                </Container>
            </SinglePage>
        );
    }
    else {
        const curr = baseItem;
        const joinedParams = params.join(' - ');
        const items = curr.map((item) => {
            return {name: item.name, url: createImgURL(section, params, item)}
        });

        return (
            <SinglePage title={section + ' ' + joinedParams}>
                <Typography variant="h5" className={classes.title}>
                    {generateTitle(section, params)}
                </Typography>
                <br />
                <Grid items={items} {...props}/>
                <br /><br />
            </SinglePage>
        );
    }
}

export default TreePage;
