import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/home";
import Nosotros from "./pages/nosotros";
import Introduccion from "./pages/museo/introduccion";
import Biografia from "./pages/museo/sintesis-biografica";
import Obra from "./pages/obra";
import Galeria from "./pages/galeria";
import Contacto from "./pages/contacto";
import Museo from "./pages/museo/main";
import Header from "./components/Header";
import {ThemeProvider} from "@material-ui/core";
import theme from "./theme";

function App() {
  return (
      <Router>
          <ThemeProvider theme={theme}>
            <Header />
            <div>
              <Route exact path="/" component={Home} />
              <Route exact path="/nosotros" component={Nosotros} />
              <Route exact path="/museo/introduccion" component={Introduccion} />
              <Route exact path="/museo/biografia" component={Biografia} />
              <Route exact path="/museo/" component={Museo} />
              {/*<Route exact path="/museo/biografia" component={SintesisBiografica} />*/}
              <Route exact path="/obra" component={Obra} />
              <Route exact path="/galeria" component={Galeria} />
              <Route exact path="/contacto" component={Contacto} />
            </div>
          </ThemeProvider>
      </Router>
  );
}

export default App;
