import TreePage from "../../components/TreePage";
import galeria from "../../data/galeria";

function Index(props) {
    return (
        <TreePage section={"galeria"} data={galeria} {...props} />
    );
}

export default Index;
