import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import SinglePage from "../../../components/SinglePage";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 32,
    paddingBottom: 32,
    textAlign: "justify",
  },
  img: {
    width: 500,
    height: "auto",
    margin: 10,
  },
}));

function Biografia() {
  const classes = useStyles();
  return (
    <SinglePage title="Biografia" headerTitle="Biografia">
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h4">
          HILARIO GOMEZ SANCHEZ, Dibujante, Pintor, Grabador y Muralista
        </Typography>
        <br />
        <Typography variant="body1">
          Nació en Chichimequillas, Silao, Gto el 11 de julio de 1902, Falleció en la ciudad
          de México el 7 de agosto de 1995.
        </Typography>
        <Typography variant="body1">
          <br />
          Sus padres fueron Càndido Gòmez Bonilla y Maria Nieves Sànchez Gaytàn, familia humilde
          que vivía en la Hacienda de Chichimequllas. Vivió su infancia y adolescencia en Guanajuato.
          En el año de 1916, la familia completa partió a radicar a la Ciudad de México. A lo largo
          de su vida visitó con frecuencia su estado natal y de 1973 a 1981 radicó en ambos lados, alternándolos.
        </Typography>
        <Typography variant="body1">
          <br />
          Siendo su afición el dibujo, en 1917 ingresó a estudiar dicha materia a la
          Escuela Nacional de Bellas Artes “La Academia”, en el turno nocturno,
          en calidad de alumno supernumerario durante 7 años.
          En 1925, inició su estudio de pintor, como alumno asistente; una vez concluido,
          continuó en el Taller de Modelo Desnudo por algunos años más. Sus
          profesores  fueron:  Andrès Rios, Sòstenes Ortega, Mateo Saldaña,
          Juan de Mata Pacheco, Germàn Gedovius, Dr. Carlos Dublàn, Arnulfo Dominguez
          Bello y los Jueces en sus  exámenes:  Adrián de Unzueta, Alfonso Garduño,
          Luis Golmano, Francisco de la Torre, Leandro Izaguirre y Mateo Herrera entre otros.
        </Typography>
        <Typography variant="body1">
          <br />
          En 1925 se creò el primer Taller de Composición Mural  a cargo del profesor Sòstenes Ortega,  formado por Ernesto Tamarìz Galicia, Ricardo Barcenas, Fernando Gamboa Bustos, Roberto de la Cueva del Rio, Josè Morales Escudero, Làzaro Lòpez e Hilario Gòmez Sànches, conocido como “Grupo de los Siete”, quienes realizaron tanto los bocetos Murales inspirados en la Cosmogonìa Tolteco-Maya, en la parte relativa a la gènesis de los metales, que armonizaba con las tendencias nacionalistas, como los carteles para  la Magna Exposición que se llevò acabo de Febrero de 1926 en Palacio de Minería y en la Escuela Nacional de Bellas Artes, donde se expusieron tanto  las obras de  los alumnos de las Escuelas al Aire Libre, como las obras Murales del mencionado  grupo, inaugurando el entonces Rector de la Universidad Nacional de Mèxico, el Doctor Alfonso Pruneda. Concluido, los murales quedaron en poder de la escuela y los carteles se enviaron a la Secretarìa de Educaciòn Pùblica.
        </Typography>
        <Typography variant="body1">
          <br />
          En ese mismo año realizó Murales en las Escuelas primarias: “Niños Héroes de Chapultepec” en las calles de Las Cruces y Mesones y “Cuauhtémoc” situada en  Santa Julia. En años posteriores participó en Murales de la Calle de Donceles y en la Casa de los Mascarones.
        </Typography>
        <Typography variant="body1">
          <br />
          A principio de los años veinte y fuera de la enseñanza acadèmica, personas provenientes de Chicago le enseñaron la tècnica de la Brocha de Aire, ahora conocida como Aerografìa impartida actualmente en la Escuela Nacional de Artes Visuales, mismos conocimientos que hizo extensivos gratuitamente a un gran nùmero de compañeros y que formaron parte de sus Talleres-Estudio que tuvo a lo largo de su vida., habièndo sido èsta la principal fuente de sus ingresos.
        </Typography>
        <Typography variant="body1">
          <br />
          Al mejorar su ingreso, lo primero que hizo fuè
          comprar un fonògrafo.
        </Typography>
        <Typography variant="body1">
          <br />
          En 1928 y 1929 estudió Solfeo y Violín en la Escuela Libre de Música.
        </Typography>
        <Typography variant="body1">
          <br />
          En 1942 se casó con mujer Michoacana Herlinda Cortes Gonzàlez procreado 3 hijos.
          <br />
        </Typography>
        <Typography variant="h4">
          <br />
          Exposiciones individuales
        </Typography>
        <hr/>
        <Typography variant="body1">
          <ul>
            <li>1974 CASA DON EVERARDO RUIZ OCAMPO</li>
            <li>1975 CASA DON EVERARDO RUIZ OCAMPO</li>
            <li>1978 HOTEL FIESTA PALACE, Salon Terraza Jardin</li>
            <li>1978 HACIENDA DE LOS MORALES</li>
            <li>1978 TEATRO MARIANO AZUELA</li>
            <li>1978 CIRCULO SOCIAL GUANAJUATENSE, A.C.</li>
            <li>1978 RECINTO DE LA GRAN COMISION DE LA CAMARA DE DIPUTADOS  </li>
            <li>1979 MUSEO DE LA CIUDAD DE MÈXICO</li>
            <li>1979 PRESIDENCIA MUNICIPAL DE GUANAJUATO, Salòn de Cabildos</li>
            <li>1979 CLUB DE GOLF BELLAVISTA</li>
            <li>1981 TEATRO MANUEL DOBLADO, Leòn, Guanajuato</li>
            <li>1981 CLUB DE GOLF BELLAVISTA</li>
            <li>1981 CANACO, CÁMARA NACIONAL DE LA INDUSTRIA Y LA TRANSFORMACIÒN</li>
            <li>1982 FORO CULTURAL COYOACANENSE</li>
            <li>1982 CENTRO DE CONVENCIONES Y EXPOSICIONES DE ACAPULCO</li>
            <li>1983 CLUB DE GOLF BELLAVISTA</li>
            <li>1984 GALERÍA ART DIKE</li>
            <li>1986 CLUB DE GOLF BELLAVISTA</li>
            <li>1987 ASEGURADORA MEXICANA, S.A.</li>
            <li>1988 CLUB DE GOLF BELLAVISTA</li>
            <li>1991 INVERARTE</li>
            <li>1991 CAMARA NACIONAL DE COMERCIO DE LA CIUDAD DE MÉXICO</li>
            <li>1995 MUSEO UNIVERSITARIO CONTEMPORÁNEO DE ARTE</li>
            <li>2002 PRESIDENCIA MUNICIPAL DE GUANAJUATO, Salòn Presidentes</li>
            <li>2003 CASA DE CULTURA DE GUANAJUATO </li>
          </ul>
        </Typography>

        <Typography variant="h4">
          Exposiciones colectivas
        </Typography>
        <hr/>
        <Typography variant="body">
          <ul>
            <li>1926 PALACIO DE MINERÍA</li>
            <li>1953 BOSQUE DE CHAPULTEPEC</li>
            <li>1971 COMPAÑÍA VINÍCOLA DEL VERGEL</li>
            <li>1972 AGRUPACIÓN  DE LA ZONA ROSA, A. C.</li>
            <li>1977 y 1978 JARDÍN REFORMA, Ciudad de Guanajuato</li>
            <li>1978 PASAJE HUMBOLDT, Ciudad de Guanajuato</li>
            <li>1979 GALERÍA CARLOS PELLICER</li>
            <li>1979 MUSEO DE BELLAS ARTES DE TOLUCA</li>
            <li>1979 MUSEO DE SAN CARLOS</li>
            <li>1979 GALERÍA TIERRA ADENTRO</li>
            <li>1979 ALIANZA FRANCESA</li>
            <li>1979 GALERÍA TERE HASS</li>
            <li>1980 FERIA REGIONAL , Cholula, Puebla</li>
            <li>1980 GALERÍA DOS PUERTAS</li>
            <li>1980 RESTAURANTE LANCERS, Cuernavaca, Morelos</li>
            <li>1980 FOMENTO CULTURAL MÈXICO, A.C.</li>
            <li>1981 HACIENDA DE COCOYOC, Cuernavaca, Mor</li>
            <li>1981 HOTEL CAMINO REAL, Foyer Calder, Ciudad de Mèxico</li>
            <li>1982 MUSEO DE ANTROPOLOGÍA</li>
            <li>1982 GALERÍAS INTERNACIONALES, Ciudad de Mèxico</li>
            <li>1983 ARTE Y CULTURA, A. C.</li>
            <li>1983 ANTIGUA HACIENDA DE TLALPAN</li>
            <li>1983 DIF DE TEQUISQUIAPAN, Querétaro</li>
            <li>1983 CLUB DE GOLF TEQUISQUIAPAN, Querétaro</li>
            <li>1983 HOTEL ALAMEDA</li>
            <li>1983 LA CASA CANELA, San Miguel Allende</li>
            <li>1983 GALERÍA MAREN</li>
            <li>1983 POLIFORUM CULTURAL SIQUEIROS</li>
            <li>1984 GALERÍA TEQUISQUIAPAN, Queretaro</li>
            <li>1984 RESTAURANTE YI`L</li>
            <li>1985 HACIENDA DE LOS MORALES</li>
            <li>1985 PALACIO NACIONAL DE BELLAS ARTES</li>
            <li>1985 SUBSECRETARIA DE INGRESOS, SECRETARIA DE HACIENDA Y CRÉDITO PÚBLICO</li>
            <li>1985 DIRECCIÓN GENERAL DE COMUNICACIONES</li>
            <li>1986 LA GALERÍA, Puebla, Pue</li>
            <li>1986 GALERIA ALEXANDRA</li>
            <li>1986 GALERIA COYOACAN</li>
            <li>1986 CASA DE LA CULTURA JUAN RULFO</li>
            <li>1986 MUSEO CARRILLO GIL</li>
            <li>1986 MINI GALERIA DE SAN ANGEL</li>
            <li>1986 RESTAURANTE CASONA DE JACCO</li>
            <li>1986 GALERIA ARTE CONTEMPORANEO</li>
            <li>1986 GALERIA ARVIL</li>
            <li>1987 HOSTERIA REGATO</li>
            <li>1987 CLUB DE GOLF JURIQUILLA, Queretaro </li>
            <li>1987 GALERIA COYOACAN</li>
            <li>1987 CENTRO DE CONVENCIONES DE LOS ANGELES, California</li>
            <li>1987 HOTEL NIKKO</li>
            <li>1987 HOTEL SHERATON, Can Cun, Quintana Ro</li>
            <li>1987 GALERIA 10/10</li>
            <li>1987 GALERIA ALBERTO MISRACHI</li>
            <li>1987 TELEVISA</li>
            <li>1987 GALERIA TERE HASS</li>
            <li>1988 GALERIA DE LA SECRETARIA DE HACIENDA</li>
            <li>1989 GALERIA ARTE CONCEPTO</li>
            <li>1989 GALERIA KALESTEIA</li>
            <li>1989 POLIFORUM CULTURAL SIQUEIROS</li>
            <li>1989 GALERIA ALEXANDRA</li>
            <li>1989 JACOB K. JAVITS CONVENTION CENTER, Nueva York</li>
            <li>1989 LA FARGA, L´HOSPITALET, Barcelona</li>
            <li>1992 PALACIO MUNDIAL DE LAS FERIAS, Ciudad de Mèxico</li>
            <li>1994 GALERIA COYOACAN</li>
            <li>1995 MUSEO UNIVERSITARIO CONTEMPORANEO DE ARTE</li>
            <li>1995 MUSEO DE ARTE DE QUERETARO</li>
            <li>1995 MUSEO DE ARTE MODERNO DE TOLUCA</li>
            <li>1999 GALERIA LA GRANJA</li>
            <li>2002 MUSEO FRANZ MAYER</li>
          </ul>
        </Typography>

        <Typography variant="h4">
          Premios y reconocimientos
        </Typography>
        <hr/>
        <Typography variant="body">
          <ul>
            <li>En 1978 Reconocimiento de la Honorable Càmara de Diputados .</li>
            <li>En 1981 Menciòn Honorifica de la Hacienda de Cocoyoc</li>
            <li>En 1987 Reconocimiento de ASEMEX, S. A.</li>
            <li>En 1994 Agradecimiento de Participaciòn del Museo de Arte de Queretaro.</li>
            <li>En 1995 Agradecimiento del MUCA, Museo Universitario Contemporàneo de Arte.</li>
            <li>En 2002 Reconocimiento Pòstumo “Atayala” por la Presidencia Municipal de Guanajuato.</li>
            <li>En 2010 Titulo Oficilal Pòstumo de Embajador Honorario del Estado de Guanajuato otorgado por el Gobierno del Estado de Guanajuato.</li>
          </ul>
        </Typography>

        <Typography variant="h4">
          Publicaciones en libros y revistas
        </Typography>
        <hr/>
        <Typography variant="body">
          <ul>
            <li>1978 Noviembre, Revista Todo</li>
            <li>1978 Noviembre, Revista Mujeres</li>
            <li>1979 Noviembre, Revista Club de Golf Bellavista</li>
            <li>1981 Septiembre, Revista Jueves de Excelsior</li>
            <li>1981 Noviembre, Revista Club de Golf Bellavista</li>
            <li>1981 Libro Artistas Plasticos Mexico</li>
            <li>1984 Enero, Revista Club de Golf Bellavista</li>
            <li>1984 Libro Catàlogo Colección Pago en Especie de la Secretarìa de Hacienda 1975-1984</li>
            <li>1985 Abril-Mayo Revista El Mundo de las Compras</li>
            <li>1985 Libro Catàlogo Grupo de los Dieciseis, A. C.</li>
            <li>1986 Marzo-Abril, Revista Club de Golf Bellavista</li>
            <li>1986 Libro Directorio Artistico “Arte 1986”</li>
            <li>1986 Libro Arte en Mèxico-Directorio de Artistas Plàsticos</li>
            <li>1986 Revista Arte en Mèxico</li>
            <li>1987 Libro Catalogo Colección Pago en Especie de la Secretarìa de Hacienda 1984-1987</li>
            <li>1978 Noviembre, Revista Todo</li>
            <li>1978 Noviembre, Revista Mujeres</li>
            <li>1979 Noviembre, Revista Club de Golf Bellavista</li>
            <li>1981 Septiembre, Revista Jueves de Excelsior</li>
            <li>1981 Noviembre, Revista Club de Golf Bellavista</li>
            <li>1981 Libro Artistas Plasticos Mexico</li>
            <li>1984 Enero, Revista Club de Golf Bellavista</li>
            <li>1984 Libro Catàlogo Colección Pago en Especie de la Secretarìa de Hacienda 1975-1984</li>
            <li>1985 Abril-Mayo Revista El Mundo de las Compras</li>
            <li>1985 Libro Catàlogo Grupo de los Dieciseis, A. C.</li>
            <li>1986 Marzo-Abril, Revista Club de Golf Bellavista</li>
            <li>1986 Libro Directorio Artistico “Arte 1986”</li>
            <li>1986 Libro Arte en Mèxico-Directorio de Artistas Plàsticos</li>
            <li>1986 Revista Arte en Mèxico</li>
            <li>1987 Libro Catalogo Colección Pago en Especie de la Secretarìa de Hacienda 1984-1987</li>
            <li>1989 Libro Catàlogo Art Expo New York</li>
            <li>1989 Libro Catàlogo Barcelona International Art Forum</li>
            <li>1989 Revista Arte en Mèxico</li>
            <li>1991 Septiembre, Revista Comercio Boletin Informativo Càmara Nacional de Comercio.</li>
            <li>1991 Noviembre, Revista Comercio.</li>
            <li>1992 Febrero, Revista CBI </li>
            <li>1993 Revista Expo Arte Canaco</li>
            <li>1994 Noviembre, Revista Catàlogo Fundaciòn UNAM</li>
            <li>1994 Libro El Valle de Mèxico del Museo Universitario Contemporaneo de Arte.</li>
            <li>1996 Revista Arte y Artistas.</li>
            <li>2002 Agosto-Septiembre, Revista Hoy Presentamos</li>
            <li>2002 Septiembre, Libro Catàlogo 2a Subasta de Arte Fundaciòn Politècnico en el Museo Franz Mayer.</li>
          </ul>
        </Typography>
      </Container>
    </SinglePage>
  );
}

export default Biografia;
