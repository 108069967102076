import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme();

theme.typography.h3 = {
    fontSize: '2.4rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.2rem',
    },
};

theme.typography.h5 = {
    fontSize: '1.8rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
    },
};

export default theme;