import {Container, Typography} from "@material-ui/core";
import SinglePage from "../../components/SinglePage";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 32,
        paddingBottom: 32,
        textAlign: "justify",
    },
}));

function Index() {
    const classes = useStyles();
    return (
        <SinglePage  title="Contacto">
            <Container maxWidth="md" className={classes.container}>
                <Typography variant="h5">CONTACTO</Typography>
                <br />
                Si tiene preguntas o le gustaría estar en contacto
                con el museo, puede enviarnos un correo electrónico a
                la siguiente dirección <a href="mailto:info@museohilariogomez.com">info@museohilariogomez.com</a>.
                <br />
                <br />
            </Container>
        </SinglePage>
    );
}

export default Index;
